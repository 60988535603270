@import url(../assets/animate/animate.css);
@import url(../assets/social-buttons/css/rrssb.css);
@import url(../assets/easy-pie-chart/css/easypiechart.css);
@import url(http:

//fonts.googleapis.com/css?family=PT+Sans:400,
700,
400italic
)
;
@import url(http:
//fonts.googleapis.com/css?family=Roboto:400,
500,
700
)
;
.noUi-target, .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
}

.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-stacking .noUi-handle {
    z-index: 10;
}

.noUi-stacking + .noUi-origin {
    *z-index: -1;
}

.noUi-state-tap .noUi-origin {
    -webkit-transition: left .3s, top .3s;
    transition: left .3s, top .3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

.noUi-horizontal {
    height: 9px;
}

.noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    left: -17px;
    top: -6px;
}

.noUi-horizontal.noUi-extended {
    padding: 0 15px;
}

.noUi-horizontal.noUi-extended .noUi-origin {
    right: -15px;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

.noUi-vertical.noUi-extended {
    padding: 15px 0;
}

.noUi-vertical.noUi-extended .noUi-origin {
    bottom: -15px;
}

.noUi-background {
    border: 0;
    background: #8ec449;
}

.noUi-connect {
    background: #2c2c2c;
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

.noUi-origin {
    border-radius: 2px;
}

.noUi-target {
    border-radius: 4px;
    border: 0;
}

.noUi-target.noUi-connect {
    box-shadow: none;
}

.noUi-dragable {
    cursor: w-resize;
}

.noUi-vertical .noUi-dragable {
    cursor: n-resize;
}

.noUi-handle {
    border-radius: 3px;
    background: #74a535;
    cursor: default;
}

.noUi-active {
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: transparent;
    left: 14px;
    top: 6px;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}

[disabled].noUi-connect, [disabled] .noUi-connect {
    background: #b8b8b8;
}

[disabled] .noUi-handle {
    cursor: not-allowed;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
}

body {
    overflow-x: hidden;
    position: relative;
    font-family: "PT Sans", sans-serif !important;
    font-size: 13px;
    line-height: 22px;
    font-weight: 300;
    background-color: #fff;
    color: #616161;
}

::-moz-selection {
    background: #1c588f;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #1c588f;
    color: #fff;
    text-shadow: none;
}

input, textarea, input[type="submit"]:focus, div {
    outline: none;
}

button, input, select, textarea {
    vertical-align: top;
    margin: 0;
}

.list-group-item {
    background: transparent;
}

.body-bg-1 {
    background: #f1f1f1;
}

.body-bg-2 {
    background: url("/images/backgrounds/bg-dark_2.jpg") no-repeat fixed;
}

.body-bg-3 {
    background: url("../images/patterns/squairy-light.png") repeat;
}

.body-bg-4 {
    background: url("../images/patterns/dark-dotted.png") repeat;
}

.body-bg-5 {
    background: url("../images/patterns/skulls.png") repeat;
}

.body-bg-6 {
    background: url("../images/backgrounds/full-bg-10.jpg") no-repeat fixed;
}

.body-bg-7 {
    background: url("../images/backgrounds/full-bg-3.jpg") no-repeat fixed;
}

iframe {
    border: 0;
    margin-top: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: .7em;
    /*text-transform: capitalize;*/
}

h1 {
    font-size: 29px;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 23px;
}

h4 {
    font-size: 19px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 14px;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

small {
    font-size: 85%
}

p {
    line-height: 22px;
    margin-bottom: 10px;
    text-align: justify;
}

a {
    color: #666;
    cursor: pointer;
}

a:hover {
    color: #7eb44a;
    text-decoration: none;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .35s;
}

hr {
    margin: 15px 0;
    border-color: #e0eded;
}

hr.delimiter {
    background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    margin: 22px 0 22px 0;
}

hr.hr-sm {
    margin: 5px 0;
}

label {
    font-weight: 400;
    font-size: 13px;
}

.heading {
    margin: 0;
    margin-bottom: 6px;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.heading-xs {
    font-size: 14px;
}

.heading-sm {
    font-size: 16px;
}

.heading-md {
    font-size: 18px;
}

.heading-lg {
    font-size: 22px;
}

.font-normal {
    font-size: 13px;
}

.font-xs {
    font-size: 11px;
}

.font-sm {
    font-size: 12px;
}

.font-md {
    font-size: 16px;
}

.font-xl {
    font-size: 100px;
}

.light h1, .light h2, .light h3, .light h4, .light h5, .light h6 {
    color: #333 !important;
}

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
    color: #fff !important;
}

.light-gray h1, .light-gray h2, .light-gray h3, .light-gray h4, .light-gray h5, .light-gray h6 {
    color: #000;
}

.base h1, .base > h2, .base h3, .base h4, .base h5, .base h6 {
    color: #fff;
}

.base-alt h1, .base-alt h2, .base-alt h3, .base-alt h4, .base-alt h5, .base-alt h6 {
    color: #fff !important;
}

.c-base {
    color: #8ec449;
}

.c-alt {
    color: #2c2c2c;
}

.c-light {
    color: #ecf0f1;
}

.c-dark {
    color: #131313;
}

.c-white {
    color: #fff !important;
}

.c-gray {
    color: #333 !important;
}

.c-black {
    color: #000 !important;
}

.c-red {
    color: #f00;
}

.light {
    background: #ecf0f1;
    color: #333;
}

.light a, .light i {
    color: #333;
}

.light-gray {
    background: #fcfcfc;
    color: #000;
}

.light-gray a, .light-gray > i {
    color: #000;
}

.dark {
    background: #353535;
    color: #fff;
}

.dark a, .dark i {
    color: #fff;
}

.white {
    background: #eee;
    color: #333 !important;
}

.white a, .white i {
    color: #333;
}

.base {
    background: #8ec449;
    color: #fff !important;
}

.base a, .base i {
    color: #fff;
}

.base-alt {
    background: #2c2c2c;
    color: #fff;
}

.base-alt a, .base-alt i {
    color: #fff;
}

.alpha {
    background: rgba(255, 255, 255, 0.5);
}

.red {
    background: #e91b23;
    color: #fff;
}

.red a, .red i {
    color: #fff;
}

.yellow {
    background: #ffd800;
    color: #fff;
}

.yellow a, .yellow i {
    color: #fff;
}

.orange {
    background: #ff8a3c;
    color: #fff;
}

.orange a, .orange i {
    color: #fff;
}

.green {
    background: #2ecc71;
    color: #fff;
}

.blue {
    background: #2980b9;
    color: #fff;
}

.purple {
    background: #8e44ad;
    color: #fff;
}

.black {
    background: #000;
    color: #fff;
}

.gray {
    background: #ecf0f1;
    color: #eee;
}

.dark-cyan {
    background: #42bdc2;
    color: #fff;
}

.forest-green {
    background: #7fc242;
    color: #fff;
}

.bg-header {
    background: #fff;
}

.bg-white {
    background: #ddd !important;
}

.bg-base {
    background: #8ec449;
    color: #fff;
}

.bg-base {
    background: #8ec449;
    color: #fff;
}

.bg-base-alt {
    background: #2c2c2c;
    color: #616161;
}

.bg-white {
    background: #fff;
    color: #555;
}

.bg-white-alt {
    background: #ecf0f1;
    color: #666;
}

.bg-dark {
    background: #131313;
    color: #8ec449;
}

.bg-dark-alt {
    background: #1a4d68;
    color: #8ec449;
}

.bg-gray-alt {
    background: #dee0e1;
    color: #eee;
}

.bg-dark-green {
    background: #4B6532;
}

.bg-dark-green.section_title.left h1 {
    color: #fff;
}

.bg-paper {
    background: #333; /*url("/images/backgrounds/tree-repeata.jpg") repeat;*/
    color: #FFF;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: 0 !important;
}

.no-radius {
    border-radius: 0 !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-50 {
    padding: 50px 0 !important;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.mt-5 {
    margin-top: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mt-40 {
    margin-top: 40px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.ml-40 {
    margin-left: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.width-10 {
    width: 10%
}

.width-20 {
    width: 20%
}

.width-30 {
    width: 30%
}

.width-40 {
    width: 40%
}

.width-50 {
    width: 50%
}

.width-60 {
    width: 60%
}

.width-70 {
    width: 70%
}

.width-80 {
    width: 80%
}

.width-90 {
    width: 90%
}

.width-100 {
    width: 100%
}

.ba {
    border: 1px solid #e0eded;
}

.bt {
    border-top: 1px solid #e0eded;
}

.br {
    border-right: 1px solid #e0eded;
}

.bb {
    border-bottom: 1px solid #e0eded;
}

.bl {
    border-left: 1px solid #e0eded;
}

.rounded {
    border-radius: 3px;
}

.rounded-xs {
    border-radius: 1px;
}

.rounded-sm {
    border-radius: 2px;
}

.rounded-md {
    border-radius: 5px;
}

.rounded-lg {
    border-radius: 8px;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-caps {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-normal {
    text-transform: none !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.relative {
    position: relative;
}

.italic {
    font-style: italic !important;
}

.strong {
    font-weight: 500 !important;
}

.strong-400 {
    font-weight: 400 !important;
}

.strong-500 {
    font-weight: 500 !important;
}

.strong-600 {
    font-weight: 600 !important;
}

.strong-700 {
    font-weight: 700 !important;
}

.title {
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    color: #333;
}

.title-xs {
    color: #333;
    font-size: 11px;
    font-weight: 500;
}

.title-sm {
    color: #333;
    font-size: 12px;
    font-weight: 500;
}

.title-md {
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.title-lg {
    color: #333;
    font-size: 20px;
    font-weight: 500;
}

.full-size-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.bg-banner-1 {
    padding: 54px 0 !important;
    background: url(../images/prv/banner-img-4.jpg) fixed no-repeat !important;
    color: #fff;
}

.bg-banner-2 {
    padding: 54px 0 !important;
    background: url(../images/prv/banner-img-1.jpg) no-repeat fixed !important;
    color: #fff;
}

.prlx-bg {
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    height: 400px;
    position: relative;
}

.video-wrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;
}

.video-wrapper video {
    width: 100% !important;
    position: relative !important;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
}

.mask.mask-1 {
    background: url(../images/patterns/slash-it.png);
}

.mask.mask-2 {
    background: url(../images/patterns/dark-exa.png);
    opacity: .4;
}

.img-thumbnail {
    border-radius: 0;
}

.inset-shadow-1 {
    -webkit-box-shadow: inset 0 10px 8px -10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 10px 8px -10px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 10px 10px -8px rgba(0, 0, 0, 0.1);
}

.inset-shadow-2 {
    -webkit-box-shadow: inset 0 0 50px 0 #232228;
    -moz-box-shadow: inset 0 0 50px 0 #232228;
    box-shadow: inset 0 0 50px 0 #232228;
}

.inset-shadow-3 {
    -webkit-box-shadow: inset 33px 0 42px -40px rgba(0, 0, 0, 0.56);
    -moz-box-shadow: inset 33px 0 42px -40px rgba(0, 0, 0, 0.56);
    box-shadow: inset 33px 0 42px -40px rgba(0, 0, 0, 0.56);
}

.inset-shadow-4 {
    -webkit-box-shadow: inset 0 0 50px 0 #232228;
    -moz-box-shadow: inset 0 0 50px 0 #232228;
    box-shadow: inset 0 0 50px 0 #232228;
}

.primary-letter {
    float: left;
    padding: 6px 0;
    font-size: 44px;
    font-weight: 400;
    line-height: 30px;
    margin: 0 5px 0 0;
}

.primary-letter.style-2 {
    padding: 12px;
    margin-top: 6px;
    text-align: center;
}

.primary-letter.style-3 {
    padding: 12px;
    margin-top: 6px;
    text-align: center;
    border-radius: 2px;
}

.primary-letter.style-4 {
    padding: 12px;
    margin-top: 6px;
    min-width: 54px;
    border-radius: 50%;
    text-align: center;
}

header {
    padding: 0;
}

header.header-cover {
    background-size: cover !important;
}

header .navbar-wp .navbar-brand {
    height: auto;
    padding: 0 15px;
    margin-top: 14px;
    border: 1px solid transparent;
    border-radius: 3px;
}

header .top-navbar-header .navbar-brand {
    height: auto;
    padding: 20px 0;
}

header .navbar-wp .navbar-brand img {
    height: 46px;
}

header .navbar-wp.affix .navbar-brand {
    margin-top: 4px;
}

header .navbar-wp.affix .navbar-brand img {
    height: 46px;
}

header .navbar-default .navbar-brand.logo-img {
    height: 50px;
    padding: 0 20px;
}

header .navbar-fixed {
    width: 100%;
    left: 0;
}

header .navbar-fixed .navbar {
    width: 100%
}

.header-alpha .navbar-wp .navbar-inner {
    border-color: #eee !important;
}

.header-alpha .top-header {
    border-color: #eee !important;
}

.top-header {
    border-bottom: 1px solid #e0eded;
    background: #fff;
}

.top-header .aux-text {
    padding: 10px 0;
    color: #999;
    font-size: 11px;
}

.top-header .top-header-menu {
    float: right;
}

.top-header .top-header-menu > ul.top-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.top-header .top-header-menu > ul.top-menu > li {
    position: relative;
    float: left;
    display: inline-block;
    border-right: 1px solid #f2f2f2;
}

.top-header .top-header-menu > ul.top-menu > li:last-child {
    border: 0;
}

.top-header .top-header-menu > ul.top-menu > li > a {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
}

.top-header .top-header-menu > ul.top-menu > li.dropdown > a:after {
    content: "\f107";
    margin-left: 6px;
    font-family: "FontAwesome";
    position: relative;
    float: right;
}

.top-header .top-header-menu ul.top-menu > li > a > i {
    margin-right: 6px;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu {
    display: none;
    min-width: 160px;
    position: absolute;
    right: -1px;
    z-index: 1500;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border: 0;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.top-header .top-header-menu ul.top-menu > li:hover ul.sub-menu {
    opacity: 1;
    display: block;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li {
    border-bottom: 1px solid #e0eded;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li:last-child {
    border: 0;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li > a {
    display: block;
    padding: 6px 15px;
    color: #333;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li:last-child {
    border: 0;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li > a:hover {
    background: #8ec449;
    color: #fff;
}

.top-header .top-header-menu ul.top-menu > li ul.sub-menu > li > .language-active {
    display: block;
    padding: 6px 15px;
    background: #f2f2f2;
    color: #333;
    cursor: default;
}

.top-header .top-header-menu ul.top-menu > li.dropdown:hover .sub-menu {
    display: block;
}

.top-header.top-header-dark {
    border-bottom: 1px solid #090909;
    background: #131313;
}

.top-header.top-header-dark .aux-text {
    color: #fff;
}

.top-header.top-header-dark .top-header-menu > ul.top-menu > li {
    border-color: #090909;
}

.top-header.top-header-dark .top-header-menu > ul.top-menu > li > a {
    color: #fff;
}

.top-navbar-header {
    height: 90px;
    background: #fff;
    border-bottom: 1px solid #e0eded;
}

.top-navbar-header .top-nav-links {
    margin-top: 10px;
}

.top-navbar-header .top-nav-links a {
    color: #999;
}

.top-navbar-header .top-nav-links a:hover {
    color: #8ec449;
    text-decoration: underline;
}

.navbar-wp {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    z-index: 1000;
    background: #454545;
    border-bottom: 1px solid #333;
}

.navbar-wp .navbar-nav > li > a {
    border-top: 3px solid transparent;
    padding: 28px 16px;
    margin-right: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.navbar-wp .navbar-nav > li > a.dropdown-form-toggle {
    color: #333;
}

.navbar-wp .navbar-nav > li > a:hover, .navbar-wp .navbar-nav > li > a:focus {
    color: #fff;
    background-color: #8ec449;
    border-top: 3px solid #8ec449;
}

.navbar-wp .navbar-nav li.divider {
    margin: 0;
    padding: 4px 15px;
    background: #fff;
    color: #333;
    height: auto;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.navbar-base {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    z-index: 1000;
    background: #8ec449;
    border-bottom: 1px solid #8ec449;
}

.navbar-base .navbar-nav > li > a {
    border-top: 3px solid transparent;
    padding: 28px 16px;
    margin-right: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.navbar-shadow {
    -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
}

.header-standard-1 {
    background: #fff;
}

.header-standard-1 .navbar-wp {
    height: 52px;
    border-bottom: 1px solid #e0eded;
}

.header-standard-1 .navbar-wp .navbar-nav > li > a {
    padding: 14px 16px;
    margin-right: 0;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #333;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #fff;
}

.header-standard-1 .navbar-wp .navbar-right .social-link {
    margin-top: 5px;
}

.navbar-wp .navbar-nav > .active > a, .navbar-wp .navbar-nav > .active > a:hover, .navbar-wp .navbar-nav > .active > a:focus {
    color: #fff !important;
    background-color: #8ec449;
    border-radius: 0;
}

.navbar-wp .navbar-nav > .disabled > a, .navbar-wp .navbar-nav > .disabled > a:hover, .navbar-wp .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
}

.navbar-wp .navbar-nav > .open > a, .navbar-wp .navbar-nav > .open > a:hover, .navbar-wp .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #8ec449;
    border-color: #8ec449;
}

.navbar-base .navbar-nav > .open > a, .navbar-base .navbar-nav > .open > a:hover, .navbar-base .navbar-nav > .open > a:focus {
    background: transparent;
    color: #fff !important;
}

.navbar-wp .navbar-nav > .open > a .caret, .navbar-wp .navbar-nav > .open > a:hover .caret, .navbar-wp .navbar-nav > .open > a:focus .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

.navbar-wp .navbar-nav > .dropdown > a .caret {
    border-top-color: #4c4c4c;
    border-bottom-color: #4c4c4c;
}

.navbar-wp .navbar-nav > li > a.dropdown-form-toggle, .navbar-wp .navbar-nav > li > a.dropdown-form-toggle:hover, .navbar-wp .navbar-nav > li > a.dropdown-form-toggle:focus {
    padding: 28px 16px;
    font-size: 16px;
    font-weight: normal;
    background: transparent;
    color: #333;
}

.navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle, .navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle:hover, .navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle:focus {
    padding: 18px 16px;
    font-size: 14px;
}

.navbar-wp.navbar-base .navbar-nav > li > a.dropdown-form-toggle, .navbar-wp.navbar-base .navbar-nav > li > a.dropdown-form-toggle:hover, .navbar-wp.navbar-base .navbar-nav > li > a.dropdown-form-toggle:focus {
    color: #fff !important;
}

.navbar-wp .navbar-nav > .open > a.dropdown-form-toggle, .navbar-wp .navbar-nav > .open > a.dropdown-form-toggle:hover, .navbar-wp .navbar-nav > .open > a.dropdown-form-toggle:focus {
    color: #333 !important;
    background-color: transparent;
}

.navbar-toggle .icon-bar {
    background-color: #333;
}

.navbar-toggle .icon-custom {
    font-size: 16px;
}

.navbar-toggle:hover, .navbar-toggle:focus {
    background-color: #8ec449;
    border-color: #8ec449;
}

.navbar-toggle:hover .icon-bar, .navbar-toggle:focus .icon-bar {
    background-color: #fff;
}

.navbar-toggle:hover .icon-custom, .navbar-toggle:focus .icon-custom {
    color: #fff;
}

.navbar-toggle {
    border-color: #333;
    border-radius: 2px;
    margin-top: 20px;
    color: #333;
    padding: 12px 16px 7px 16px;
}

.navbar-toggle-aside-menu {
    padding: 12px 16px 7px 16px;
    margin-right: 10px;
}

.navbar-toggle-mobile-nav {
    padding: 12px 16px 7px 16px;
    margin-right: 10px;
}

.navbar-base .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-base .navbar-toggle .icon-custom {
    font-size: 16px;
}

.navbar-base .navbar-toggle:hover, .navbar-base .navbar-toggle:focus {
    background-color: #fff;
    border-color: #fff;
}

.navbar-base .navbar-toggle:hover .icon-bar, .navbar-base .navbar-toggle:focus .icon-bar {
    background-color: #fff;
}

.navbar-base .navbar-toggle:hover .icon-custom, .navbar-base .navbar-toggle:focus .icon-custom {
    color: #000;
}

.navbar-base .navbar-toggle {
    border-color: #fff;
    border-radius: 2px;
    margin-top: 20px;
    color: #fff;
    padding: 12px 16px 7px 16px;
}

.navbar-base .navbar-toggle-aside-menu {
    padding: 12px 16px 7px 16px;
    margin-right: 10px;
}

.navbar-base .navbar-toggle-mobile-nav {
    padding: 12px 16px 7px 16px;
    margin-right: 10px;
}

.navbar-wp .navbar-nav {
    margin-top: 0;
}

.navbar-wp .navbar-collapse.in {
    max-height: 340px;
}

.navbar-wp .navbar-collapse, .navbar-wp .navbar-form {
    border-color: #e7e7e7;
}

.navbar-wp .navbar-collapse.in .dropdown-aux {
    display: none;
}

.navbar-wp .navbar-nav > .dropdown > a:hover .caret, .navbar-wp .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

.navbar-wp .dropdown-menu {
    min-width: 220px;
    padding: 0;
    background: #fff;
    border: 0;
    border-top: 1px solid #e0eded;
    border-bottom: 3px solid #e0eded;
    border-radius: 2px;
}

.navbar-wp .dropdown-menu > li {
    border-bottom: 1px solid #e0eded;
}

.navbar-wp .dropdown-menu > li:last-child {
    border: 0;
}

.navbar-wp .dropdown-menu > li > a {
    color: #333;
    padding: 8px 15px;
}

.navbar-wp .dropdown-menu > li > a:hover {
    background: #8ec449;
    color: #fff;
}

.navbar-wp .dropdown-menu label.checkbox {
    color: #333;
}

.navbar-wp .navbar-nav > li.dropdown-aux > a, .navbar-wp .navbar-nav > li.dropdown-aux.open > a {
    border-color: transparent;
}

.navbar-wp .dropdown-menu-search {
    margin-top: 0;
}

.navbar-wp .navbar-right .social-link {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0 !important;
    text-align: center;
    padding: 10px;
    margin: 14px 0;
    border-radius: 100%;
    border-top: 0 !important;
}

.navbar-wp.affix .navbar-right .social-link {
    margin: 10px 0 8px 0;
}

.navbar-wp .navbar-right .social-link.facebook:hover {
    background: #43609c;
    color: #fff;
}

.navbar-wp .navbar-right .social-link.pinterest:hover {
    background: #cb2027;
    color: #fff;
}

.navbar-wp .navbar-right .social-link.twitter:hover {
    background: #62addb;
    color: #fff;
}

.mega-nav .nav, .mega-nav .collapse, .mega-nav .dropup, .mega-nav .dropdown.mega-dropdown-fluid {
    position: static;
}

.mega-nav .container {
    position: relative;
}

.mega-nav .dropdown-menu {
    left: auto;
}

.mega-nav .br {
    border-color: #e0eded;
}

.mega-nav .mega-nav-content {
    padding: 20px 30px;
}

.mega-nav .dropdown.mega-dropdown-fluid .dropdown-menu {
    left: 0;
    right: 0;
    margin: 0 15px;
}

.navbar-wp .dropdown-menu .mega-nav-section-title {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    margin: 0 0 10px 0;
    text-transform: capitalize;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
}

.navbar-wp .dropdown-menu .mega-nav-section {
    padding: 25px 0 10px 0;
}

.navbar-wp .dropdown-menu img {
    margin-bottom: 15px;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    list-style: none;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul li {
    position: relative;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul li a {
    display: block;
    padding: 4px 0;
    color: #333;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a {
    padding-left: 12px;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    color: #333;
}

.navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a:hover {
    color: #8ec449;
}

.navbar-wp.affix {
    position: fixed;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #e0eded;
    min-height: 50px;
    height: auto;
}

.navbar-wp.affix .navbar-inner {
    border-bottom: 0;
}

.navbar-base.affix {
    background: #8ec449;
    border-color: #8ec449;
}

.navbar-wp.affix .navbar-nav > li > a {
    padding: 18px 16px;
    margin-right: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
}

.navbar-wp .navbar-nav > li > a.dropdown-form-toggle {
    color: #fff;
}

.navbar-wp .navbar-nav > li > a:hover, .navbar-wp .navbar-nav > li > a:focus {
    color: #fff;
    background-color: #8ec449;
}

.navbar-base .navbar-nav > li > a.dropdown-form-toggle {
    color: #fff;
}

.navbar .social-item .facebook {
    background: #43609c;
    color: #fff !important;
}

.navbar .social-item .twitter {
    background: #62addb;
    color: #fff !important;
}

.navbar .social-item .google {
    background: #fff;
    color: #dd4b39 !important;
}

.navbar .social-item .youtube {
    background: #dd4b39;
    color: #fff !important;
}

.navbar .social-item .pinterest {
    background: #cb1f2b;
    color: #fff !important;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: -1px;
    left: 100%;
    margin-top: 0;
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
    padding: 0;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.nav > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav > ul > li {
    border-bottom: 1px solid #333;
}

.nav > ul > li > a {
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
}

.nav > ul > li > a:hover {
    text-decoration: none;
    color: #8ec449;
    background: #292929;
}

.nav > ul > li > a > i {
    margin-right: 5px;
}

.pg-opt {
    border-bottom: 1px solid #888;
    background: #999;
    color: #333;
}

.pg-opt h2 {
    margin: 0;
    padding: 16px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    /*text-transform: capitalize;*/
    color: #333;
}

.pg-opt .breadcrumb {
    float: right;
    margin: 0;
    padding: 16px 15px 16px 0;
    padding-right: -15px;
    background: transparent;
    border-radius: 0;
}

.pg-opt .breadcrumb a {
    color: #333;
}

.pg-opt .breadcrumb a:hover {
    color: #8ec449;
}

.pg-opt .breadcrumb li.active {
    color: #333;
}

@media only screen and (max-width: 767px) {
    .pg-opt .pg-nav {
        float: left;
        margin-bottom: 10px;
    }

    .pg-opt h2 {
        padding: 20px 0 0 0;
    }
}

.page-header {
    margin: 0;
    border: 0;
}

.page-header p {
    font-size: 16px;
}

.wp-block {
    margin: 0 0 15px 0;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    position: relative;
    cursor: default;
    border-radius: 2px;
}

.wp-block:before, .wp-block:after {
    display: table;
    content: ""
}

.wp-block:after {
    clear: both;
}

.wp-section:before, .w-section:after {
    display: table;
    content: " "
}

.wp-section:after {
    clear: both;
}

.wp-block .wp-block-body {
    padding: 15px;
}

.wp-block-entry {
    margin: 20px;
}

.wp-block-entry h2 {
    margin: 10px 0 10px 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
}

.wp-block .img-wrapper {
    position: relative;
}

.wp-block .img-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.wp-block:hover .img-overlay {
    display: block;
}

.wp-block .img-overlay .img-overlay-content {
    padding: 20px;
}

.wp-block .img-overlay .img-overlay-content dt {
    font-size: 16px;
}

.wp-block .img-overlay .img-overlay-content dd {
    margin-bottom: 20px;
}

.wp-block .wp-block-header .section-title {
    margin: 0;
}

.wp-block .wp-block-header .section-title:before {
    background: transparent;
}

.wp-block .caption-bottom {
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(142, 196, 73, 0.8);
}

.wp-block .caption-bottom .title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
}

.wp-block .caption-bottom .subtitle {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
}

.wp-block .wp-block-info {
    margin-top: 5px;
    padding: 10px 15px;
}

.wp-block .wp-block-info .info-title {
    margin: 0;
    padding: 0;
}

.wp-block.inverse .figure {
    position: relative;
}

.wp-block.inverse .figure img {
    width: 100%
}

.wp-block.inverse .thmb-img {
    text-align: center;
}

.wp-block.inverse .thmb-img i {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    font-size: 34px;
    line-height: 100px;
    text-align: center;
    background: #8ec449;
    color: #fff !important;
    border: 2px solid transparent;
}

.wp-block.inverse .thmb-img:hover i {
    background: transparent;
    border-color: #8ec449;
    color: #8ec449 !important;
}

.wp-block.inverse h2, .wp-block.inverse .title {
    margin: 0;
    padding: 12px 15px 0 0;
    font-weight: 500;
    font-size: 16px;
    color: #333;
    text-transform: none;
}

.wp-block.inverse small {
    display: block;
    font-size: 12px;
    margin-top: 3px;
}

.wp-block.inverse p {
    margin: 6px 0;
    padding-bottom: 8px;
}

.wp-block.default {
    border: 1px solid;
    border-color: #e0eded;
    background: #fff;
}

.wp-block.default .figure {
    position: relative;
}

.wp-block.default .figure img {
    width: 100%
}

.wp-block.default > .wp-block-header {
    margin: 0;
    padding: 15px 20px;
}

.wp-block.default > .wp-block-body {
    padding: 15px;
}

.wp-block.default > .wp-block-body .title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 10px 0;
    margin: 0;
    color: #333 !important;
}

.wp-block.default > .wp-block-body h2 {
    margin: 0 0 10px 0;
    font-size: 16px;
}

.wp-block.default > .wp-block-body dd {
    margin-bottom: 20px;
}

.wp-block.default > figure > img {
    width: 100%;
    max-width: 100%
}

.wp-block.default > .wp-block-footer {
    display: block;
    background: #fff;
    padding: 15px;
}

.wp-block.default:hover > .wp-block-footer {
    background: #fff;
}

.wp-block.default > .wp-block-footer h3 {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
}

.wp-block-date-over {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background: #fff;
    color: #333;
    border-radius: 2px;
    font-weight: 400;
    font-size: 12px;
}

.wp-block-date-over.alpha {
    background: rgba(255, 255, 255, 0.7);
}

.wp-block-info-over {
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    background: #fff;
}

.wp-block-info-over h3 {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
}

.wp-block-info-over h3 a {
    color: #fff;
}

.wp-block-info-over h3 small {
    color: #fff;
    font-size: 11px;
}

.wp-block-info-over {
    width: 100%;
    padding: 10px;
    background: #2c2c2c;
    border-top: 1px solid #e0eded;
}

.wp-block-info-over h3 {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
}

.wp-block-info-over h3 small {
    font-size: 11px;
}

.wp-block-info-over.left {
    background: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    bottom: 55px;
    left: 0;
}

.wp-block.default.thumbnail > .wp-block-info-over.left {
    left: 5px;
}

.wp-block-info-over.left h2 {
    font-size: 13px;
    font-weight: normal;
}

.wp-block-info-over.left h2 a {
    padding: 10px 15px 20px 15px;
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    background: rgba(51, 51, 51, 0.8);
    background: #2c3e50;
    color: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.wp-block-info-over.left h3 {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
}

.wp-block-info-over.left h2 .label {
    position: absolute;
    bottom: -30px;
    left: 15px;
    padding: .5em .6em .5em;
}

.wp-block-info-over.left h3 a {
    color: #fff;
}

.wp-block-info-over.left h3 small {
    color: #fff;
    font-size: 11px;
}

.wp-block.bordered .wp-block-body {
    background: transparent;
    padding: 15px;
    border: 1px solid #e0eded;
}

.wp-block.white {
    margin: 0 0 15px 0;
    position: relative;
    overflow: hidden;
    cursor: default;
    border: 1px solid #e0eded;
}

.wp-block.white .wp-block-body {
    padding: 15px;
}

.wp-block.white .title {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    color: #333;
    text-align: left;
}

.wp-block.white .subtitle {
    margin: 6px 0 0 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    text-align: left;
}

.wp-block.white p {
    margin: 6px 0;
    padding: 0 15px;
    padding-bottom: 8px;
}

.wp-block.white .thmb-img {
    text-align: center;
    padding: 15px 0;
}

.wp-block.white p {
    color: #616161;
}

.wp-block.white h2 {
    color: #333;
}

.wp-block.white .thmb-img i {
    color: #616161;
}

.wp-block.hero p {
    padding: 15px;
}

.wp-block.hero h2 {
    text-align: center;
    margin-bottom: 7px;
    font-size: 16px;
}

.wp-block.hero .thmb-img {
    text-align: center;
    padding: 15px 0;
}

.wp-block.hero .thmb-img i {
    font-size: 64px;
    color: #fff;
}

.wp-block.white p {
    color: #333;
}

.wp-block.white h2 {
    color: #333 !important;
}

.wp-block.white .thmb-img i {
    color: #333;
}

.wp-block.light p {
    color: #333;
}

.wp-block.light h2 {
    color: #333;
    text-align: center;
    margin-bottom: 7px;
    font-size: 16px;
}

.wp-block.light .thmb-img {
    text-align: center;
    padding: 15px 0;
}

.wp-block.light .thmb-img i {
    color: #333;
}

.wp-block.dark p {
    color: #f1f1f1;
}

.wp-block.dark h2 {
    color: #fff;
}

.wp-block.dark .thmb-img i {
    color: #fff;
}

.wp-block.base-alt p {
    color: #fff;
}

.wp-block.base-alt h2 {
    color: #fff;
}

.wp-block.base-alt .thmb-img i {
    color: #fff;
}

.wp-block.base p {
    color: #fff;
}

.wp-block.base h2 {
    color: #fff !important;
}

.wp-block.base .thmb-img i {
    color: #fff !important;
}

.wp-block.no-space.arrow-right:after {
    left: 100%;
    z-index: 300;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 20px;
    margin-top: -20px;
}

.wp-block.no-space.arrow-right.white:after {
    border-left-color: #fff;
}

.wp-block.no-space.arrow-right.dark:after {
    border-left-color: #131313;
}

.wp-block.no-space.arrow-right.light:after {
    border-left-color: #ecf0f1;
}

.wp-block.no-space.arrow-right.base:after {
    border-left-color: #8ec449;
}

.wp-block.no-space.arrow-right.base-alt:after {
    border-left-color: #2c2c2c;
}

.wp-block.no-space > .wp-block-header {
    margin: 0;
    padding: 15px 20px;
}

.wp-block.no-space > .wp-block-body {
    margin-top: 20px;
    padding: 20px 40px;
    min-height: 350px;
}

.wp-block.no-space > .wp-block-footer {
    margin: 15px 0 0 0;
    padding: 15px 20px;
}

.wp-block.no-space > .wp-block-footer .pagination {
    margin: 0;
}

.wp-block.no-space .img-icon {
    display: block;
    text-align: center;
    margin: 20px 0 10px 0;
}

.wp-block.no-space .img-icon img {
    width: 80px;
}

.wp-block.no-space .img-icon i {
    font-size: 80px;
}

.wp-block.no-space h1 {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 20px 0 20px 0;
    padding: 0;
    text-align: center;
}

.wp-block.icon-square {
    margin-top: 0;
}

.wp-block.icon-square .wp-block-body {
    padding-top: 0;
}

.wp-block.icon-square .img-icon-wrapper {
    width: 210px;
    padding: 20px;
    border-radius: 3px;
    background: #ffd600;
    margin: 0 auto;
    margin-bottom: 40px;
    position: relative;
    background: #8ec449;
}

.wp-block.icon-square.arrow-down .img-icon-wrapper:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #8ec449;
    border-width: 20px;
    margin-left: -20px;
}

.wp-block.icon-square.arrow-down .img-icon-wrapper .img-icon {
    display: block;
    text-align: center;
    margin: 20px 0;
}

.wp-block.icon-square.arrow-down .img-icon-wrapper .img-icon i {
    font-size: 64px;
}

.wp-block.icon-square h1 {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    font-style: normal;
    margin: 10px 0 10px 0;
    padding: 0;
    text-align: center;
    color: #333;
}

.wp-block.icon-square p {
    color: #616161;
}

.wp-block.thumbnail {
    border: 1px solid #e0eded;
    padding: 5px;
}

.wp-block.image-holder .wp-block-info {
    padding: 15px 0;
    background: #8ec449;
}

.wp-block.image-holder .wp-block-info.over {
    padding: 15px !important;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 0;
    background: #fff;
    background: rgba(255, 255, 255, 0.6);
}

.wp-block.image-holder .wp-block-info.over .info-title {
    font-size: 16px;
    color: #333;
}

.wp-block.product {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #e0eded;
}

.wp-block.product .figure {
    padding: 20px;
}

.wp-block.product .product-title {
    margin: 10px 0 0 0;
    padding: 0;
    border-bottom: 0;
}

.wp-block.product .product-title a {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.wp-block.product p {
    color: #616161;
}

.wp-block.product figure {
    padding-bottom: 15px;
    border-bottom: 1px solid #e0eded;
}

.wp-block.product .price {
    padding: 4px 0;
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

.wp-block.product .price.discount {
    padding: 7px 0 0 0;
    margin-right: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #f00;
    text-decoration: line-through;
}

.wp-block.product .wp-block-footer {
    border-top: 1px solid #e0eded;
    padding-top: 15px;
}

.wp-block.product .wp-block-footer:after {
    display: table;
    content: "";
    clear: both;
}

.wp-block.product.style-2 {
    padding: 0;
}

.wp-block.product.style-2 figure {
    padding: 0;
    border: 0;
}

.wp-block.product.style-2 .product-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.wp-block.product.style-2 .product-title a {
    font-size: 16px;
    font-weight: 400;
}

.wp-block.product.style-2 .wp-block-footer {
    display: table;
    width: 100%;
    border-top: 1px solid #e0eded;
    margin: 0;
    padding: 0;
}

.wp-block.product.style-2 .wp-block-footer .cell {
    border-right: 1px solid #e0eded;
    vertical-align: middle;
}

.wp-block.product.style-2 .wp-block-footer .cell:last-child {
    border-right: 0;
}

.wp-block.product.style-2 .wp-block-footer .cell a {
    display: block;
    text-align: center;
    padding: 10px 0;
}

.wp-block.product.style-2 .wp-block-footer .cell:hover {
    background: #8ec449;
}

.wp-block.product.style-2 .wp-block-footer .cell.price-wr:hover {
    background: transparent;
}

.wp-block.product.style-2 .wp-block-footer .cell:hover a, .wp-block.product.style-2 .wp-block-footer .cell:hover a i {
    color: #fff !important;
}

.wp-block.product.style-2 .wp-block-footer .cell a i {
    margin: 0;
}

.wp-block.product.style-2 .wp-block-footer .price {
    padding: 0;
    color: #333;
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.wp-block.product.style-2 .wp-block-footer .discount {
    color: #f00;
    font-size: 11px;
    font-weight: 400;
}

.wp-block.product.inverse {
    padding: 0;
}

.wp-block.product.inverse h2 a {
    font-weight: 400;
}

.wp-block.product.inverse .price {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.wp-block.product.inverse .wp-block-footer {
    border-top: 0;
    padding-top: 0;
}

.wp-block.product.inverse .btn i {
    margin: 0;
}

.wp-block.testimonial .wp-block-body {
    font-style: italic;
    line-height: 24px;
}

.panel-sidebar-1 .wp-block.testimonial {
    margin: 0;
}

.wp-block.testimonial .testimonial-author:before, .wp-block.testimonial .testimonial-author:after {
    content: "";
    display: table;
}

.wp-block.testimonial .wp-block-body i {
    font-size: 20px;
    margin-right: 8px;
}

.wp-block.testimonial .wp-block-image img {
    width: 100%
}

.wp-block.testimonial .testimonial-author:after {
    clear: both;
}

.wp-block.testimonial.style-1 .testimonial-author {
    width: 100%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.wp-block.testimonial.style-1 .testimonial-author .author-img {
    width: 75px;
    height: 75px;
    display: table-cell;
    margin: 0;
    padding: 0;
}

.wp-block.testimonial.style-1 .testimonial-author .author-img img {
    width: 100%;
    border-bottom-left-radius: 2px;
}

.wp-block.testimonial.style-1 .testimonial-author .author-info {
    height: 75px;
    display: table-cell;
    padding-left: 15px;
    vertical-align: middle;
    text-align: left;
    border-bottom-left-radius: 2px;
}

.wp-block.testimonial.style-1 .testimonial-author .author-info .author-name {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.wp-block.testimonial.style-1 .testimonial-author .author-info .author-pos {
    display: block;
    font-style: italic;
}

.wp-block.testimonial.style-2 .wp-block-body {
    position: relative;
}

.wp-block.testimonial.style-2 .wp-block-body:after {
    top: 100%;
    left: 37.5px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
    margin-left: -10px;
}

.wp-block.testimonial.style-2 .wp-block-body.base:after {
    border-top-color: #8ec449;
}

.wp-block.testimonial.style-2 .wp-block-body.base-alt:after {
    border-top-color: #2c2c2c;
}

.wp-block.testimonial.style-2 .wp-block-body.light:after {
    border-top-color: #ecf0f1;
}

.wp-block.testimonial.style-2 .wp-block-body.dark:after {
    border-top-color: #131313;
}

.wp-block.testimonial.style-2 .testimonial-author {
    width: 100%;
    margin-top: 15px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.wp-block.testimonial.style-2 .testimonial-author .author-img {
    width: 75px;
    height: 75px;
    display: table-cell;
    margin: 0;
    padding: 0;
}

.wp-block.testimonial.style-2 .testimonial-author .author-img img {
    width: 100%;
    border-bottom-left-radius: 2px;
}

.wp-block.testimonial.style-2 .testimonial-author .author-info {
    height: 75px;
    display: table-cell;
    padding-left: 15px;
    vertical-align: middle;
    text-align: left;
    border-bottom-left-radius: 2px;
}

.wp-block.testimonial.style-2 .testimonial-author .author-info .author-name {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.wp-block.testimonial.style-2 .testimonial-author .author-info .author-pos {
    display: block;
    font-style: italic;
}

.wp-block.property.list {
    border: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-title {
    padding: 15px 15px 0 15px;
}

.wp-block.property.list .wp-block-title h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

.wp-block.property.list .wp-block-img {
    display: table-cell;
    width: 250px;
}

.wp-block.property.list .wp-block-img img {
    width: 100%
}

.wp-block.property.list .wp-block-body .wp-block-content {
    display: table-cell;
    vertical-align: top;
    padding-left: 15px;
}

.wp-block.property.list .wp-block-content .content-title {
    font-size: 20px;
    color: #8ec449;
    margin-bottom: 5px;
}

.wp-block.property.list .wp-block-content .description {
    padding-bottom: 10px;
    border-bottom: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-content .price {
    font-size: 22px;
    color: #2c2c2c;
    font-weight: 600;
}

.wp-block.property.list .wp-block-content .period {
    margin-left: 5px;
    color: #999;
}

.wp-block.property.list .wp-block-content .capacity {
    font-size: 16px;
    color: #999;
}

.wp-block.property.list .wp-block-footer ul.aux-info {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer ul.aux-info li {
    display: table-cell;
    padding: 10px 15px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer ul.aux-info li:last-child {
    border: 0;
}

.wp-block.property.list .wp-block-footer ul.aux-info li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info {
    display: table;
    width: 100%;
    border: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li {
    padding: 10px 10px;
    font-size: 11px;
    text-align: center;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li i {
    display: block;
}

.wp-block.property.grid {
    border: 1px solid #e0eded;
}

.wp-block.property.grid .wp-block-title {
    padding: 15px 15px 0 15px;
}

.wp-block.property.grid .wp-block-title h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

.wp-block.property.grid .wp-block-body .wp-block-img img {
    width: 100%
}

.wp-block.property.grid .wp-block-body .wp-block-content {
    padding-top: 15px;
}

.wp-block.property.grid .wp-block-body .wp-block-content .content-title {
    font-size: 20px;
    color: #8ec449;
    margin-bottom: 5px;
}

.wp-block.property.grid .wp-block-body .wp-block-content .description {
    padding-bottom: 10px;
    border-bottom: 1px solid #e0eded;
}

.wp-block.property.grid .wp-block-body .wp-block-content .price {
    font-size: 22px;
    color: #2c2c2c;
    font-weight: 600;
}

.wp-block.property.grid .wp-block-body .wp-block-content .period {
    margin-left: 5px;
    color: #999;
}

.wp-block.property.grid .wp-block-body .wp-block-content .capacity {
    font-size: 16px;
    color: #999;
}

.wp-block.property.grid .wp-block-footer ul.aux-info {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
}

.wp-block.property.grid .wp-block-footer ul.aux-info li {
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

.wp-block.property.grid .wp-block-footer ul.aux-info li:last-child {
    border: 0;
}

.wp-block.property.grid .wp-block-footer ul.aux-info li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

.wp-block.article .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 6px;
    padding: 0;
    text-transform: none;
}

.wp-block.article .video-title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 6px;
    padding: 0;
    text-transform: none;
}

.wp-block.article img {
    width: 100%
}

.wp-block.article .meta-tags a {
    display: inline-block;
    color: #8ec449;
}

.wp-block.article .video-hover-play {
    font-size: 100%
}

.wp-block.article .video-hover-play a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 19, 19, 0);
}

.wp-block.article .video-hover-play a:hover {
    background: rgba(19, 19, 19, 0);
}

.wp-block.article .video-hover-play a:before {
    content: "\f144";
    font-family: FontAwesome;
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -23px;
    margin-left: -30px;
    width: 60px;
    height: 46px;
    line-height: 46px;
    background: #131313;
    border-radius: 2px;
    text-align: center;
}

.wp-block.article .video-hover-play a:hover:before {
    color: #fff;
    background: #8ec449;
}

.wp-block.article .video-hover-play.small a:before {
    font-size: 16px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -15px;
    width: 30px;
    height: 24px;
    line-height: 24px;
}

.wp-block.article.grid .article-image {
    position: relative;
    margin-bottom: 5px;
}

.wp-block.article.grid .image-title {
    position: absolute;
    left: 0;
    bottom: 10px;
    padding: 6px 8px;
    font-size: 13px;
    font-weight: 500;
}

.wp-block.article.grid .article-category {
    font-size: 11px;
    color: #8ec449;
    text-transform: uppercase;
}

.wp-block.article.list {
    padding-bottom: 10px;
}

.wp-block.article.list .article-image {
    display: table-cell;
    vertical-align: top;
    width: 40%;
    position: relative;
}

.wp-block.article.list .wp-block-body {
    display: table-cell;
    vertical-align: top;
    padding: 0 0 0 15px;
}

.wp-block.article.list .article-label {
    display: inline-block;
    padding: 6px 8px;
    font-size: 13px;
    font-weight: 500;
}

.wp-block.article.list .title {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 16px;
}

.wp-block.article.list .article-info {
    display: block;
    margin-bottom: 4px;
    font-size: 11px;
    text-transform: uppercase;
    color: #999;
}

.wp-block.article.list .wp-block-footer {
    display: table;
    width: 100%
}

.wp-block.article.list .wp-block-footer .meta-info span {
    float: left;
    margin-right: 8px;
    font-size: 11px;
}

.wp-block.article.list .wp-block-footer .meta-info span i {
    margin-right: 4px;
    font-size: 13px;
}

.wp-block.article.list .image-title {
    position: absolute;
    left: 0;
    bottom: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}

.wp-block.article.list .article-category {
    font-size: 11px;
    color: #8ec449;
    text-transform: uppercase;
}

.wp-block.article.post .article-title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 26px;
    text-transform: none;
}

.wp-block.article.post .article-image {
    margin-bottom: 20px;
}

.wp-block.article.post .meta-info {
    margin: 15px 0;
}

.wp-block.article.post .meta-info span {
    display: block;
}

.wp-block.article.post .meta-info span strong {
    color: #333;
}

.panel-sidebar-1 {
    border-radius: 2px !important;
}

.panel-sidebar-1 .panel-heading {
    padding: 15px;
}

.panel-sidebar-1 .panel-heading h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.panel-sidebar-1 h4 {
    padding: 15px;
    font-size: 13px;
    font-weight: normal;
    text-transform: capitalize;
    color: #8ec449;
}

.panel-sidebar-1 .panel-body h4 {
    padding: 0;
}

.panel-sidebar-1 .panel-body ul.list-group {
    border-bottom: 1px solid #e0eded;
}

.panel-sidebar-1 .panel-body ul.list-group:last-child {
    border: 0;
}

.panel-sidebar-1 .panel-body ul.list-group li {
    border: 0;
    padding: 5px 0 5px 10px;
}

.panel-sidebar-1 ul.categories {
    border-left: 0;
    border-right: 0;
}

.panel-base {
    border: 0;
    background: #8ec449;
}

.panel-base .panel-heading {
    background: #84ba3f;
    padding: 25px 15px;
    color: #fff;
    border-bottom: 1px solid #7ab035;
}

.panel-base .panel-heading.panel-heading-lg {
    padding: 25px;
    font-size: 20px;
    font-weight: 600;
}

.panel-base .form-base label {
    color: #fff;
    margin-bottom: 6px;
}

.icon-block {
    margin-bottom: 20px;
}

.icon-block.icon-block-1 .icon-block-item {
    width: 20%;
    display: table-cell;
    vertical-align: top;
    font-size: 64px;
    text-align: center;
    color: #333;
}

.icon-block.icon-block-1 .icon-block-body {
    display: table-cell;
    vertical-align: top;
    padding-left: 15px;
}

.icon-block.icon-block-1 .icon-block-body .title {
    margin-bottom: 5px;
    color: #333;
}

.icon-block.icon-block-1 .icon-block-body p {
    margin: 0;
}

.icon-block.icon-block-2 .icon-block-item {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 2px;
    display: block;
    float: left;
    font-size: 46px;
    text-align: center;
}

.icon-block.icon-block-2 .icon-block-body {
    padding-left: 115px;
}

.icon-block.icon-block-2 .icon-block-body .title {
    margin-bottom: 5px;
    color: #333;
}

.icon-block.icon-block-2 .icon-block-body p {
    margin: 0;
}

.icon-block.icon-block-3 .icon-block-item {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 100px;
    display: block;
    float: left;
    font-size: 46px;
    text-align: center;
    color: #333;
}

.icon-block.icon-block-3 .icon-block-body {
    padding-left: 115px;
}

.icon-block.icon-block-3 .icon-block-body .title {
    margin-bottom: 5px;
    color: #333;
}

.icon-block.icon-block-3 .icon-block-body p {
    margin: 0;
}

.hov {
    float: left;
    width: 100%;
    text-decoration: none;
}

.hov p {
    margin: 0;
}

.caption {
    padding: 20px;
}

.caption a:hover {
    text-decoration: none;
}

.caption .title {
    padding: 0 !important;
}

.caption p {
    font-size: 13px;
    line-height: 17px;
    margin: 10px 0 0 0 !important;
}

.caption.alpha {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
}

.caption.icon {
    padding-left: 88px;
    background-image: url(images/camera.png);
    background-repeat: no-repeat;
    background-position: 20px 20px;
}

.caption.copyright {
    padding: 5px 10px;
    background: transparent;
    text-align: right;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.caption.copyright span {
    padding: 2px 8px 4px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

.caption.widescreen {
    padding: 10px 20px;
}

.aside-feature {
    margin: 10px;
    cursor: default;
}

.aside-feature .icon-feature {
    font-size: 68px;
    margin-top: 10px;
    text-align: center;
    display: block;
}

.aside-feature:hover .icon-feature, .wp-section .aside-feature:hover h4 {
    color: #8ec449;
}

.aside-feature .img-feature {
    margin-top: 4px;
    display: block;
}

.aside-feature .img-feature img {
    width: 78px;
}

.aside-feature.square .icon-feature {
    width: 64px;
    height: 64px;
    margin-top: 15px;
    font-size: 40px;
    line-height: 64px;
    background: #8ec449;
    color: #fff;
    text-align: center;
    display: block;
    border-radius: 3px;
}

.aside-feature.square .icon-feature i {
    color: #fff;
}

.aside-feature.square:hover .icon-feature {
    background: #fff;
    border: 2px solid #8ec449;
    color: #8ec449;
}

.aside-feature.square .outer-title {
    margin: 0 15px;
    padding: 10px 0;
    border-bottom: 1px dashed #e0eded;
    font-size: 16px;
}

.aside-feature.square p {
    margin-top: 10px;
}

.short-feature {
    margin-bottom: 15px;
}

.short-feature .feature-icon {
    background: #8ec449;
    border-radius: 35px;
    color: #fff;
    display: inline-block;
    float: left;
    height: 35px;
    line-height: 35px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 35px;
}

.short-feature .feature-txt {
    padding-left: 50px;
}

.short-feature .feature-txt h3 {
    margin: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.short-feature .feature-txt p {
    font-size: 13px;
    font-weight: normal;
    color: #616161;
}

.milestone-counter {
    margin-top: 40px;
    margin-bottom: 20px;
}

.milestone-counter .milestone-count {
    font-size: 72px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.milestone-counter h4.milestone-info {
    margin-top: 35px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.dropdown-form {
    min-width: 350px;
    z-index: 500;
}

.dropdown-cart {
    min-width: 400px;
    background: #fff;
    color: #333;
}

.dropdown-cart .table > thead > tr > th {
    border-color: #e0eded;
}

.dropdown-cart .dropdown-cart-body {
    padding: 15px;
    background: #fff;
}

.dropdown-cart .dropdown-cart-footer {
    padding: 15px;
    background: #fcfcfc;
    border-top: 1px solid #efefef;
}

.dropdown-cart .cart-items {
    display: block;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    background: #fcfcfc;
    color: #000;
    border-bottom: 1px solid #efefef;
}

.dropdown-menu h4 {
    font-size: 14px;
    color: #4c4c4c;
}

.dropdown-profile {
    padding: 15px;
}

.dropdown-profile img {
    width: 60px;
}

.style-switcher-in .body-wrap {
    right: -280px;
}

.aside-menu-in .wrapper {
    left: -100%
}

.nav-menu-in .wrapper {
    right: -280px;
}

.body-wrap {
    position: relative;
    right: 0;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.body-wrap {
    position: relative;
    z-index: 0;
}

.body-wrap:before, .body-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 260px;
}

.body-wrap:after {
    top: auto;
    bottom: 0;
}

@media (max-width: 767px) {
    .body-wrap: before, .body-wrap:after {
        height: 20px;
    }
}

.ie9 body, .ie9 .body-wrap:before, .ie9 .body-wrap:after {
    filter: none;
}

.aside-menu.left {
    width: 280px;
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    left: 0;
    top: 0;
    background: #222;
    border-right: 1px solid #333;
    display: none;
    z-index: 1100;
}

.aside-menu.right {
    width: 280px;
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    background: #222;
    border-left: 1px solid #333;
    display: none;
    z-index: 1100;
}

.slidebar-toggle {
    position: fixed;
    top: 100px;
    right: 0;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.55);
    color: #fff;
    cursor: pointer;
    z-index: 1000;
    border: 0;
}

.aside-menu::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

.aside-menu .form-search {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #333;
}

.aside-menu .form-search .form-input {
    padding: 0;
}

.aside-menu .form-search .form-control {
    display: block;
    height: 34px;
    padding: 21px 15px;
    color: #fff;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
}

.aside-menu .form-search .btn-close {
    background: transparent;
    color: #fff;
}

.aside-menu .form-search .btn-close i {
    font-weight: 300 !important;
    font-size: 16px;
}

.aside-menu .social-media {
    padding: 15px;
    padding-bottom: 0;
}

.aside-menu .contact-info {
    padding: 15px;
    color: #fff;
}

.aside-menu .contact-info h5 {
    font-size: 13px;
}

.side-section-title {
    position: relative;
    overflow: hidden;
    margin: 0;
    margin-top: 15px;
    padding: 8px 15px;
    font-size: 11px;
    text-transform: uppercase;
    color: #616161;
}

.side-section-title:after {
    content: "";
    height: 1px;
    background: #333;
    width: 80px;
    position: absolute;
    top: 26px;
    left: 15px;
}

.style-switcher-slidebar {
    width: 335px;
    height: 100%;
    position: fixed;
    left: -285px;
    bottom: 0;
    z-index: 1200;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all 1s ease;
    transition: all .5s ease;
}

.style-switcher-slidebar.opened {
    left: 0;
}

.style-switcher-slidebar .switch-panel {
    float: left;
    width: 280px;
    height: 100%;
    overflow-y: scroll;
    background: #fff;
    color: #333;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.style-switcher-slidebar .switch-panel::-webkit-scrollbar {
    width: 5px;
}

.style-switcher-slidebar .switch-panel::-webkit-scrollbar-track {
    background-color: #bdc3c7;
}

.style-switcher-slidebar .switch-panel::-webkit-scrollbar-thumb {
    background-color: #aaa;
}

.style-switcher-slidebar .switch-panel::-webkit-scrollbar-thumb:hover {
    background-color: #7f8c8d;
}

a.open-panel {
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    color: #fff !important;
    background-color: #8ec449;
    display: block;
    height: 50px;
    width: 50px;
    float: right;
    margin: 0;
    z-index: 1000;
    position: relative;
    top: 160px;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
}

a.open-panel:hover {
    color: #fff;
}

.style-switcher-slidebar label {
    margin-bottom: 0;
}

.style-switcher-slidebar h3 {
    height: 50px;
    line-height: 50px;
    margin: 0 0 10px 0;
    padding: 0 10px;
    background: #8ec449;
    font-size: 16px;
    color: #fff;
}

.style-switcher-slidebar .panel-section {
    padding: 0 15px;
}

.style-switcher-slidebar .switch-panel .form-group {
    margin-bottom: 5px;
    margin-top: 5px;
}

.style-switcher-slidebar .switch-panel .bg-switch a {
    width: 36px;
    height: 36px;
    float: left;
    margin-right: 8px;
}

.color-switch a {
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 8px;
    text-indent: -9999px;
}

.color-switch a:hover, .color-switch a.active {
    border: 2px solid #8ec449;
    box-shadow: 2px 2px 0 #666;
}

.color-switch a:last-child {
    margin-right: 0;
}

.color-switch a.color-red {
    background: #e91b23;
}

.color-switch a.color-violet {
    background: #563d7c;
}

.color-switch a.color-blue {
    background: #3498db;
}

.color-switch a.color-green {
    background: #8ec449;
}

.color-switch a.color-yellow {
    background: #f1c40f;
}

.color-switch a.color-orange {
    background: #d35400;
}

.color-switch a.color-bw {
    background: url(../images/temp/bw.jpg) no-repeat;
}

.color-switch a.color-dark {
    background: url(../images/temp/dark.jpg) no-repeat;
}

.color-switch a.color-flat {
    background: url(../images/temp/flat.jpg) no-repeat;
}

.popover .popover-title {
    margin: 0;
}

.popover .popover-content {
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
}

.carousel-1 {
    overflow: hidden;
    margin-bottom: 20px;
}

.carousel-1.carousel-fixed-height .carousel-inner {
    height: 440px;
}

.carousel-1.carousel-fixed-height .item {
    height: 440px;
}

.carousel-1 .carousel-control {
    color: #f8f8f8;
}

.carousel-1 .carousel-control.left {
    left: -40px;
}

.carousel-1 .carousel-control.right {
    right: -40px;
}

.carousel-1 .carousel-control:hover {
    color: #fff;
}

.carousel-1 .carousel-control i {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    font-size: 36px;
    font-weight: 600;
}

.carousel-1 .item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
}

.carousel-1 .item-dark {
    color: #fff;
}

.carousel-1 .item-light {
    color: #fff;
}

.carousel-1 .item img {
    width: 100%
}

.carousel-1 p {
    font-size: 16px;
}

.carousel-1 .object {
    position: absolute;
    top: 38px;
    right: 50%;
    margin-left: 15px;
    width: 568px;
    height: 320px;
    overflow: hidden;
}

.carousel-1 .object.fluid {
    width: 100%;
    left: 0;
    margin: 0;
}

.carousel-1 .object iframe {
    width: 100% !important;
}

.carousel-1 .description {
    position: absolute;
    top: 55px;
    left: 50%;
    margin-left: 50px;
    width: 514px;
    height: 290px;
}

.carousel-1 .description .title {
    font-size: 32px;
    margin: 0 0 15px 0;
    padding: 8px 20px;
    line-height: 38px;
    background: #fff;
    color: #616161;
}

.carousel-1 .description .subtitle {
    font-size: 24px;
    margin: 20px 0;
    padding: 0;
    display: block;
}

.carousel-1 .description p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

.carousel-1 .description-left {
    position: absolute;
    width: 40%;
    top: 50px;
    left: 80px;
    margin-left: 20px;
    width: 514px;
    height: 290px;
}

.carousel-1 .description-left .title {
    font-size: 3vw;
    margin: 0 0 15px 0;
    padding: 0;
    line-height: 3.5vw;
    font-weight: 600;
}

.carousel-1 .description-left .subtitle {
    font-size: 1.8vw;
    margin: 20px 0;
    padding: 0;
    display: block;
    line-height: 2.3vw;
}

.carousel-1 .description-left p {
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: #fff;
    margin: 0;
}

.carousel-1 .description.fluid-center {
    width: 100%;
    top: 50px;
    left: 0;
    margin: 0;
}

.carousel-1 .description.fluid-center .title {
    margin-bottom: 5px;
    display: block;
    text-align: center;
    background: none;
    color: #fff;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(150, 150, 150, 0.5);
}

.carousel-1 .description.fluid-center .subtitle {
    font-size: 20px;
    margin: 0;
    display: block;
    text-align: center;
}

.carousel-1 .description.fluid-center .features {
    display: block;
    margin-top: 40px;
    text-align: center;
}

.carousel-1 .description.fluid-center .features i {
    width: 110px;
    height: 110px;
    background: #fff;
    text-align: center;
    line-height: 110px;
    font-size: 54px;
    color: #697e93;
    font-weight: 700;
    border-radius: 96px;
    margin-right: 20px;
}

.carousel-1 .caption-bottom {
    width: 100%;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(142, 196, 73, 0.8);
}

.carousel-1 .caption-bottom .title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
}

.carousel-1 .caption-bottom .subtitle {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
}

.carousel-1 .text-shadow {
    text-shadow: 2px 2px 2px #888;
}

.carousel-1 .subtitle-sm {
    font-size: 16px;
    margin: 5px 0 15px 0;
    padding: 0;
    display: block;
    line-height: 26px;
}

.carousel-4 .carousel-inner {
    overflow: hidden;
}

.carousel-4 .carousel-control i {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    font-size: 36px;
    font-weight: 600;
}

.carousel-4 .carousel-control.left i {
    left: 30px;
}

.carousel-4 .carousel-control.right i {
    right: 30px;
}

.owl-carousel .item {
    margin: 3px;
}

.owl-carousel .owl-item, .owl-carousel .owl-item .wp-block {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.owl-carousel .owl-item:target, .owl-carousel .owl-item .wp-block:target {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.simple-slider {
    height: 500px;
    background: #f3f3f3;
}

.cta-wr {
    padding: 16px 0;
    border-radius: 2px;
}

.cta-wr h1 {
    margin: 10px 0 !important;
    font-size: 20px;
    text-transform: none;
    line-height: 26px;
    font-weight: 400;
}

section.slice {
    padding: 30px 0;
}

section.slice.arrow-bottom {
    position: relative;
}

section.slice.arrow-bottom:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-width: 20px;
    margin-left: -20px;
    z-index: 700;
}

section.slice.arrow-bottom.base:after {
    border-top-color: #8ec449;
}

section.slice.arrow-bottom.light:after {
    border-top-color: #ecf0f1;
}

section.slice.arrow-bottom.light-gray:after {
    border-top-color: #fcfcfc;
}

section.slice.arrow-bottom.dark:after {
    border-top-color: #131313;
}

section.slice.arrow-bottom.base-alt:after {
    border-top-color: #2c2c2c;
}

section.slice.slice-lg {
    padding: 50px 0;
}

section.slice.relative {
    position: relative;
}

section.slice.bg-image {
    background-repeat: no-repeat;
    background-size: cover;
}

section.slice-over-elements {
    position: absolute;
    top: -50px;
    z-index: 2500;
}

.subsection {
    margin-top: 30px;
}

.container.bordered {
    border: 1px solid #ddd;
    padding-top: 15px;
}

.wp-section:before, .wp-section:after {
    display: table;
    content: " "
}

.wp-section:after {
    clear: both;
}

.wp-example {
    margin-bottom: 45px;
}

.pre-scrollable {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #ccc !important;
}

.pre-scrollable::-webkit-scrollbar {
    width: 5px;
}

.pre-scrollable::-webkit-scrollbar-track {
    background-color: #bdc3c7;
}

.pre-scrollable::-webkit-scrollbar-thumb {
    background-color: #aaa;
}

.pre-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #7f8c8d;
}

.nav-sidebar-fixed {
    position: fixed;
    width: 260px;
}

.shop .pagination {
    margin: 0;
}

.shop .product-short-info p {
    padding: 6px 0;
    margin: 0;
}

.shop .product-short-info p i {
    margin-right: 8px;
    color: #8ec449;
}

.shop .product-short-info p strong {
    font-size: 14px;
}

.shop .primary-image {
    border: 1px solid #e0eded;
    padding: 15px;
    border-radius: 2px;
}

.shop .thumbnail-images {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
}

.shop .thumbnail-images a {
    display: block;
    padding: 5px;
    border: 1px solid #e0eded;
}

.shop .thumbnail-images a:first-child {
    border-bottom-left-radius: 2px;
}

.shop .thumbnail-images a:last-child {
    margin: 0;
    border-bottom-right-radius: 2px;
}

.shop .thumbnail-images a img {
    width: 100%
}

.shop .thumbnail-images.style-2 {
    margin-top: 15px;
}

.shop .thumbnail-images.style-2 a:first-child {
    border-top-left-radius: 2px;
}

.shop .thumbnail-images.style-2 a:last-child {
    border-top-right-radius: 2px;
}

.product-info .product-title {
    margin-bottom: 10px;
    color: #333;
}

.shop .product-price {
    margin-bottom: 15px;
    color: #333;
}

.shop .product-price > .price {
    font-size: 26px;
    font-weight: 600;
    margin-left: 10px;
}

.shop .product-price > .price.discount {
    font-size: 16px;
    font-weight: 600;
    color: #f00;
    text-decoration: line-through;
}

.table-cart {
    border-right: 1px solid #e0eded;
    border-left: 1px solid #e0eded;
    border-bottom: 1px solid #e0eded;
}

.table-cart th {
    border-top: 0 !important;
    font-weight: 600;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-color: #e0eded;
}

.table-cart td:first-child {
    border-right: 1px solid #e0eded;
}

.table-cart img {
    width: 80px;
}

.table-cart .cart-remove {
    display: block;
    text-align: center;
    color: #e80e1d;
}

.table-dropdown-cart {
    margin: 0;
}

.table-dropdown-cart > thead > tr {
    background: transparent !important;
}

.table-dropdown-cart > thead > tr > th {
    border-top: 0 !important;
    border-color: #e0eded;
    background: transparent;
    font-weight: 600;
    padding-left: 15px;
}

.table-dropdown-cart > tbody > tr > td {
    border-color: #e0eded;
}

.table-dropdown-cart > tbody > tr > td:first-child {
    border-right: 1px solid #e0eded;
}

.table-dropdown-cart img {
    width: 50px;
}

.table-totals td:nth-child(even) {
    padding: 5px 15px;
}

.table-cart-subtotal {
    margin: 0;
}

.table-cart-subtotal th {
    font-weight: 400;
    width: 50%
}

.table-cart-subtotal > thead > tr > th, .table-cart-subtotal > tbody > tr > th, .table-cart-subtotal > tfoot > tr > th, .table-cart-subtotal > thead > tr > td, .table-cart-subtotal > tbody > tr > td, .table-cart-subtotal > tfoot > tr > td {
    padding: 6px 0;
    border-top: 0;
}

.table-orders th {
    background: #fcfcfc;
}

.estate .product-info h3 {
    font-weight: 500;
    font-size: 20px;
}

.estate .pagination {
    margin: 0;
}

.estate .product-short-info p {
    padding: 6px 0;
    margin: 0;
}

.estate .primary-image {
    border: 1px solid #e0eded;
    padding: 5px;
}

.estate .thumbnail-images {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
}

.estate .thumbnail-images a {
    display: table-cell;
    width: 100px;
    padding: 5px;
    border: 1px solid #e0eded;
    border-top: 0;
}

.estate .thumbnail-images a:last-child {
    margin: 0;
}

.estate .thumbnail-images a img {
    width: 100%
}

.product-info h3 {
    margin-bottom: 10px;
}

.estate .product-price {
    margin-bottom: 15px;
}

.estate .product-price > .price {
    font-size: 32.5px;
    font-weight: 600;
    margin-left: 10px;
}

.estate .product-price > .price.discount {
    font-size: 16px;
    font-weight: 600;
    color: #f00;
    text-decoration: line-through;
}

.animate-wr {
    animation-duration: .5s;
    -webkit-animation-duration: .5s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
}

.animate-hover-slide .figure {
    position: relative;
    overflow: hidden;
}

.animate-hover-slide .figure img {
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide .figure .figcaption {
    height: 100%;
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide .figure:hover .figcaption {
    opacity: .8;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transition: -webkit-transform .4s, opacity .1s;
    -moz-transition: -moz-transform .4s, opacity .1s;
    -o-transition: -o-transform .4s, opacity .1s;
    transition: transform .4s, opacity .1s;
}

.animate-hover-slide .figure .figcaption {
    text-align: center;
}

.animate-hover-slide .figure .figcaption-btn {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    opacity: 0;
    padding-left: 20px;
    text-align: center;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide .figure:hover .figcaption-btn {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transition: -webkit-transform .4s, opacity .1s;
    -moz-transition: -moz-transform .4s, opacity .1s;
    -o-transition: -o-transform .4s, opacity .1s;
    transition: transform .4s, opacity .1s;
}

.animate-hover-slide .figure .figcaption-txt {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    padding-left: 20px;
    text-align: center;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide .figure:hover .figcaption-txt {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transition: -webkit-transform .4s, opacity .1s;
    -moz-transition: -moz-transform .4s, opacity .1s;
    -o-transition: -o-transform .4s, opacity .1s;
    transition: transform .4s, opacity .1s;
}

.animate-hover-slide .figure .figcaption-txt .title {
    padding: 0;
    margin: 30px 0 0 0;
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
}

.animate-hover-slide .figure .figcaption-txt .subtitle {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 12px;
}

.animate-hover-slide .figure a {
    position: relative;
    top: 94%;
    margin-top: -11px;
}

.animate-hover-slide .figure .figcaption h3 {
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #f2f2f2;
}

.animate-hover-slide-2 .figure {
    position: relative;
    overflow: hidden;
}

.animate-hover-slide-2 .figure img {
    position: relative;
    z-index: 2;
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide-2 .figure:hover img {
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    transform: scale(.4);
}

.animate-hover-slide-2 .figure .figcaption {
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: auto;
    background: #e3e3e3;
    color: #000 !important;
    padding: 0 15px;
    width: 100%;
    opacity: 1;
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    transform: scale(.4);
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide-2 .figure .figcaption a {
    color: #000;
}

.animate-hover-slide-2 .figure:hover .figcaption {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.animate-hover-slide-2 .figure .figcaption h2 {
    text-align: center;
    margin-top: 15px;
}

.animate-hover-slide-2 .figure .figcaption .social-icons {
    width: 100%;
    position: absolute;
    bottom: 15px;
    text-align: center;
}

.animate-hover-slide-3 .figure {
    position: relative;
    overflow: hidden;
}

.animate-hover-slide-3 .figure img {
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide-3 .figure .figcaption {
    height: 32px;
    background: #e3e3e3;
    color: #000 !important;
    padding: 0 15px;
    width: 100%;
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transition: -webkit-transform .4s, opacity .1s .3s;
    -moz-transition: -moz-transform .4s, opacity .1s .3s;
    -o-transition: -o-transform .4s, opacity .1s .3s;
    transition: transform .4s, opacity .1s .3s;
}

.animate-hover-slide-3 .figure .figcaption a {
    color: #000 !important;
}

.animate-hover-slide-3 .figure:hover .figcaption {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transition: -webkit-transform .4s, opacity .1s;
    -moz-transition: -moz-transform .4s, opacity .1s;
    -o-transition: -o-transform .4s, opacity .1s;
    transition: transform .4s, opacity .1s;
}

.animate-hover-slide-4 {
    position: relative;
}

.animate-hover-slide-4 .figure {
    position: relative;
}

.animate-hover-slide-4 .figure .figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    opacity: .75;
}

.animate-hover-slide-4 .figure .figcaption {
    text-align: center;
}

.animate-hover-slide-4 .figure .figcaption-btn {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    opacity: 0;
    padding-left: 20px;
    text-align: center;
}

.animate-hover-slide-4 .figure:hover .figcaption-btn {
    opacity: 1;
}

.animate-hover-slide-4 .figure .figcaption-txt {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    padding-left: 20px;
    text-align: center;
}

.animate-hover-slide-4 .figure:hover .figcaption-txt {
    opacity: 1;
}

.animate-hover-slide-4 .figure .figcaption-txt .title {
    padding: 0;
    margin: 30px 0 0 0;
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
}

.animate-hover-slide-4 .figure .figcaption-txt .subtitle {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 12px;
}

.animate-hover-slide-4 .figure a {
    position: relative;
    top: 94%;
    margin-top: -11px;
}

.animate-hover-slide-4 .figure .figcaption h3 {
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #f2f2f2;
}

.pricing-plans {
    margin-bottom: 15px;
}

.pricing-plans:before, .pricing-plans:after, .pricing-table:before, .pricing-table:after {
    display: table;
    content: " "
}

.pricing-plans:before, .pricing-table:before {
    clear: both;
}

.pricing-plans .plan-header .popular-tag {
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
}

.pricing-plans .plan-header small {
    display: block;
    text-align: center;
    font-style: italic;
}

.pricing-plans .plan-title {
    text-align: center;
    margin: 0;
    padding: 15px 0 5px 0;
    font-size: 28px;
    font-weight: 500;
}

.pricing-plans .price-tag {
    margin: 0;
    height: 70px;
    line-height: 70px;
    font-size: 58px;
    font-weight: 500;
    text-align: center;
}

.pricing-plans .price-tag span {
    font-size: 28px;
    font-weight: 500;
}

.pricing-plans .price-tag span.price-type {
    font-size: 20px;
    font-weight: 500;
}

.pricing-plans .plan-info {
    margin-bottom: 10px;
}

.pricing-plans ul {
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;
}

.pricing-plans ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #e0eded;
    font-size: 13px;
}

.pricing-plans ul li:last-child {
    border-bottom: 0;
}

.pricing-plans ul li i {
    margin-right: 8px;
}

.pricing-plans .plan-info {
    margin: 0;
    padding: 15px;
    font-size: 13px;
    text-align: center;
    font-style: italic;
}

.pricing-plans .plan-select {
    padding-top: 10px;
    border-top: 1px solid #f1f1f1;
}

.pricing-plans .plan-select-block {
    padding: 0;
    margin: 0;
}

.pricing-plans .plan-circle {
    width: 100%;
    background: transparent !important;
}

.pricing-plans .plan-circle:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.pricing-plans .plan-circle.base:after {
    background: #8ec449;
}

.pricing-plans .plan-circle.light:after {
    background: #ecf0f1;
}

.pricing-plans .plan-circle.dark:after {
    background: #131313;
}

.pricing-plans .plan-circle .plan-content {
    float: left;
    width: 100%;
    padding-top: 20px;
    text-align: center;
}

.pricing-plans .plan-circle .plan-title {
    padding-top: 15px;
}

.pricing-plans .plan-circle .btn {
    margin-top: 20px;
}

.pricing-plans-2 .plan-title {
    text-align: center;
    margin: 0;
    padding: 16px 0;
    font-size: 28px;
    font-weight: 500;
}

.pricing-plans-2 .plan-price {
    border-bottom: 1px solid #e0eded;
}

.pricing-plans-2 .plan-price .price-tag {
    margin: 0;
    padding: 15px 0 0 0;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
}

.pricing-plans-2 .plan-price .price-interval {
    display: block;
    margin: 15px 0;
    text-align: center;
    color: #999;
}

.pricing-plans-2 .price-tag span {
    font-size: 28px;
    font-weight: 500;
}

.pricing-plans-2 .price-tag span.price-type {
    font-size: 20px;
    font-weight: 500;
}

.pricing-plans-2 .price-tag sup {
    font-size: 32px;
}

.pricing-plans-2 ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 0;
}

.pricing-plans-2 ul li {
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid #e0eded;
    font-size: 13px;
}

.pricing-plans-2 ul li:last-child {
    border-bottom: 1px solid #e0eded;
}

.pricing-plans-2 ul li i {
    margin-right: 8px;
}

.pricing-plans-2 .plan-select {
    padding-top: 10px;
    border-top: 0;
}

.pricing-plans-2 .plan-select-block {
    padding: 0;
    margin: 0;
}

.promos {
    margin: 0 auto;
}

.promo {
    margin: 15px 10px 25px;
    padding: 5px 0 30px;
    font-weight: 400;
    line-height: 1.625;
    text-align: center;
}

.promos.base {
    background: transparent;
}

.promos.base .promo {
    background: #8ec449;
}

.promos.light .promo {
    background: #ecf0f1;
}

.promos.dark .promo {
    background: #131313;
}

.promo h4 {
    margin: 5px 0 0;
    font-size: 170%;
    font-weight: normal;
}

.promos.base .promo h4 {
    color: #fff;
}

.promos.light .promo h4 {
    color: #2c3e50;
}

.promos.dark .promo h4 {
    color: #fff;
}

.promo li {
    padding: 5px 0;
}

.promo .brief {
    color: #fff;
}

.promos.light .promo .brief {
    color: #2c3e50;
}

.promo .price {
    margin: 10px 0;
    padding: 5px 0;
    font-size: 250%
}

.promo .features {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.promo .buy {
    margin: 15px 0 0;
}

.promos.base .promo .features {
    color: #fff;
}

.promos.light .promo .features {
    color: #2c3e50;
}

.promos.dark .promo .features {
    color: #fff;
}

.promos.base .promo .price {
    background: #70a62b;
    color: #fff;
}

.promos.light .promo .price {
    background: #ced2d3;
    color: #2c3e50;
}

.promos.dark .promo .price {
    background: #000;
    color: #fff;
}

.pricing-plans .wp-block {
    margin-top: 20px;
}

.pricing-plans .wp-block:hover, .pricing-table .wp-block:hover {
    -webkit-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
}

.pricing-plans .wp-block-inverse:hover, .pricing-table .wp-block-inverse:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.pricing-table .wp-block {
    z-index: 1;
    margin-top: 20px;
    margin-bottom: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.pricing-table .wp-block.popular, .pricing-plans .wp-block.popular {
    border: 1px solid #e0eded;
    margin-top: 0;
    -webkit-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.2);
}

.pricing-table .wp-box.popular:hover, .pricing-plans .wp-block.popular:hover {
    -webkit-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.3);
    box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.3);
}

.pricing-table .plan-select {
    border: 0;
    padding: 10px 0;
    border-bottom: 1px solid #e0eded;
}

.pricing-table .plan-info {
    text-align: center;
    margin-bottom: 15px;
}

.pricing-table .table-comparision {
    background: #fff;
    position: relative;
    top: -2px;
    z-index: 1000;
    border-color: #f1f1f1;
    color: #777;
}

.pricing-table .table-comparision th {
    border-color: #f1f1f1;
}

.pricing-table .table-comparision td {
    text-align: center;
    border-color: #f1f1f1;
}

.pricing-table .table-comparision tr td:first-child {
    text-align: left;
}

.attr-col {
    margin: 110px 0 0;
    float: left;
    width: 200px;
}

.attr-col ul {
    background: #fcfcfc;
    font-weight: bold;
    font-size: 13px;
    border: 1px solid #e0eded;
    border-width: 1px 0 1px 1px;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.attr-col ul li {
    text-align: right;
    padding: 0 10px;
    border-bottom: 1px solid #e0eded;
    line-height: 45px;
    display: block;
}

.attr-col ul li.last {
    border-bottom: none;
}

.pt-table {
    padding-left: 200px;
    display: block;
    position: relative;
}

.pt-body {
    padding: 10px 0 0;
}

.pt-rows li {
    display: block;
    overflow: hidden;
    background: #fff;
    border-left: 1px solid #e0eded;
    border-right: 1px solid #e0eded;
    border-bottom: 1px solid #e0eded;
}

.pt-rows li span {
    width: 24%;
    text-align: center;
    float: left;
    border-right: 1px solid #d9d9d9;
    display: block;
    line-height: 45px;
    height: 45px;
}

.pt-rows li.title {
    background: #131313;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    -webkit-border-top-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    border-top-left-radius: 2px;
}

.pt-rows li.title span {
    line-height: 50px;
    height: 50px;
    border: none;
    padding: 0 1px;
}

.pt-rows li.fees {
    border-bottom: 1px solid #e0eded;
}

.pt-rows li.fees span {
    line-height: 48px;
    height: 48px;
    background: #fcfcfc;
    font-size: 34px;
    font-weight: 700;
    font-family: Georgia, Arial, sans-serif;
    color: #4172a5;
    text-shadow: 2px 2px #fff;
}

.pt-rows li span.pt-yes {
    background: url(yes-no.gif) no-repeat center 12px;
}

.pt-rows li span.pt-no {
    background: url(yes-no.gif) no-repeat center -38px;
}

.pt-rows li.fin {
    border-bottom: 1px solid #e0eded;
    -webkit-border-bottom-right-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 85px;
}

.pt-rows li span.pt-3x {
    width: 72%;
    float: left;
    text-align: center;
    border: none;
}

.pt-special {
    width: 24%;
    position: absolute;
    right: 0;
    top: 0;
}

.pt-special .pt-rows {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    background: #fcfcfc;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
}

.pt-special .pt-rows li {
    border-width: 0 0 1px;
    background: transparent;
}

.pt-special .pt-rows li.title {
    height: 58px;
    margin: 1px;
    background: #d0ebfe;
    color: #2a719d;
    font-size: 30px;
    line-height: 65px;
    text-align: center;
    border-bottom: 1px solid #bfd4e6;
    border-width: 0 0 1px;
    text-shadow: 2px 2px #e6f5ff;
}

.pt-special .pt-rows li.fees {
    border-bottom: 1px solid #bcd8ec;
}

.pt-special .pt-rows li.fees span {
    background: #ecf6fe;
}

.pt-special .pt-rows li.fin {
    border: none;
    text-align: center;
}

.pt-special .pt-rows li span {
    border: none;
    width: 100%
}

.pt-special .pt-rows li.fin .big-button {
    background: #3a8bd0;
    top: 22px;
}

.pt-special .pt-rows li.fin .big-button:hover {
    background: #50a6ef;
}

.pagination-delimiter {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e0eded;
}

.pagination-delimiter .pagination {
    margin: 0;
}

.vertical-info h4 {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 14px;
    color: #555;
    font-weight: 400;
}

.vertical-info h4:first-child {
    margin-top: 0;
}

.vertical-info p.delimiter {
    margin: 5px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0eded;
    font-size: 17px;
    font-weight: 700;
    color: #333;
}

#ulSorList {
    margin-top: 20px;
}

#ulSorList:after {
    content: '';
    display: inline-block;
    width: 100%
}

#ulSorList .mix {
    display: none;
    opacity: 0;
}

#ulSorList .mix .item {
    background: #f2f2f2;
}

#ulSorList .gap {
    display: inline-block;
    width: 200px;
}

.work {
    width: 100%;
    overflow: hidden;
}

.work .btn-group {
    margin-bottom: 10px;
}

.work .btn {
    margin-right: 6px;
}

.work .btn-group .btn {
    margin-right: 0;
}

.work .mix {
    margin-top: 20px;
}

.work.work-no-space .mix {
    margin: 0;
    padding: 0;
}

.work.work-no-space .mix .wp-block {
    margin: 0 !important;
    padding: 0;
}

.work.work-no-space.g2 .mix {
    width: 50%;
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
}

.work.work-no-space.g3 .mix {
    width: 33.3%;
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
}

.work.work-no-space.g4 .mix {
    width: 25%;
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
}

.work.work-no-space.g5 .mix {
    width: 20%;
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
}

.work.work-no-space .mix .wp-box {
    padding: 0;
    margin: 0;
}

.map-canvas {
    height: 400px;
    margin: 0;
}

.map-canvas .info-window-content {
    min-width: 250px;
}

.map-canvas .info-window-content h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.map-canvas .info-window-content h3 {
    font-size: 14px;
    font-weight: 500;
}

.map-canvas .info-window-content p {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    color: #999;
    text-shadow: none;
}

.map-canvas-square {
    height: 200px;
}

.comments-wr {
    padding: 0 15px;
}

.comments-wr .comment:before, .comments-wr .comment:after {
    display: table;
    content: " "
}

.comments-wr .comment:after {
    clear: both;
}

.comments-wr .comment {
    border-bottom: 1px solid #eee;
    padding: 15px 0;
}

.comments-wr .comment:last-child {
    border-bottom: 0;
}

.comments-wr .comment p {
    padding: 0;
}

.comments-wr .comment .comment {
    margin: 12px 0 0 60px;
    padding-bottom: 0;
    border-bottom: 0;
    border-top: 1px solid #eee;
}

.comments-wr .comment img {
    width: 48px;
    float: left;
}

.comments-wr .comment p {
    margin-left: 60px;
    color: #777;
}

.comments-wr .comment .comment-author {
    display: block;
}

.comments-wr .comment .comment-author a {
    font-weight: 600;
}

.comment-form {
    padding: 15px 15px;
}

.comment-form h2 {
    margin-bottom: 15px;
}

.widget {
    margin-bottom: 30px;
}

.boxed {
    background: #fff;
    border: 1px solid #e0eded;
    position: relative;
}

.boxed-blue {
    background: #1c588f;
}

.boxed-dark {
    background: #142439;
}

.boxed-light {
    background: #e5eff6;
}

.boxed.red-line {
    border-top: 4px solid #c9376e;
}

.boxed.green-line {
    border-top: 4px solid #9cd70e;
}

.boxed.blue-line {
    border-top: 4px solid #4bb4e8;
}

.widget-container {
    margin-bottom: 30px;
    border: 1px solid #e0eded;
}

.widget-full-width {
    margin-bottom: 100px;
}

@media (max-width: 767px) {
    .content, .sidebar {
        padding: 20px 0;
    }
}

.widget-recent-stories {
    background: #e6edf2;
}

.widget-recent .widget-title {
    padding: 26px 24px 28px;
}

.widget-recent li {
    padding: 10px 0;
    border-bottom: 1px solid #e0eded;
}

.widget-recent li:first-child {
    padding-top: 0;
    border-top: 0;
}

.widget-recent.widget-recent-boxed li {
    padding: 10px 15px;
}

.widget-recent li:last-child {
    border: 0;
}

.widget-recent-stories li:nth-child(odd) {
    background: #c6d3dd;
}

.widget-recent-comments li:nth-child(odd) {
    background: #fcfcfc;
}

.widget-recent-comments li:nth-child(even) {
    background: #eee;
}

.widget-recent .post-thumb {
    float: left;
    width: 72px;
    height: 72px;
    margin-right: 16px;
}

.widget-recent-stories .post-thumb, .widget-recent-stories .post-thumb img {
    border-radius: 50%
}

.widget-recent .post-thumb img {
    display: block;
    width: 100%;
    height: 100% !important;
}

.widget-recent .post-title, .widget-recent .comment-author {
    display: block;
    margin-bottom: 0;
}

.widget-recent .comment-author {
    font-size: 12px;
    color: #8ec449;
}

.widget-recent .post-title, .widget-recent .comment-author a {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
}

.widget-recent .post-desc, .widget-recent .comment-entry {
    display: block;
    font-size: 12px;
    color: #616161;
    line-height: 17px;
}

.widget-recent .post-meta-bot {
    padding: 21px 0;
    text-align: center;
}

@media (max-width: 479px), (min-width: 768px) and (max-width: 991px) {
    .widget-recent li {
        padding: 8px;
    }

    .widget-recent .post-thumb {
        margin-right: 5px;
        margin-top: 3px;
    }

    .widget-recent-comments li:nth-child(odd) .post-thumb {
        margin-left: 5px;
    }
}

.container .jumbotron {
    padding: 0;
    border-radius: 0;
}

.jumbotron {
    overflow: hidden;
    line-height: normal;
    background: #f7fafc;
    padding: 0 !important;
    margin: 0;
}

.jumbotron-left, .jumbotron-right {
    float: left;
}

.jumbotron-left {
    width: 70%;
    z-index: 1;
    -webkit-box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
}

.jumbotron-right {
    width: 30%
}

@media (max-width: 767px) {
    .jumbotron-left, .jumbotron-right {
        float: none;
        width: 100%
    }

    .jumbotron-title {
        top: 10px;
        left: 10px;
    }

    .jumbotron-title h1 {
        font-size: 18px;
        padding-bottom: 6px;
        margin-bottom: 6px;
    }

    .jumbotron-title span {
        font-size: 12px;
    }

    .jumbotron-title i {
        display: none;
    }
}

@media (max-width: 479px) {
    .jumbotron .post-item .post-meta-top {
        float: none;
        width: 100%
    }

    .jumbotron .post-item .post-image {
        margin: 0 0 10px 0;
    }
}

.media-photos-list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    overflow: hidden;
}

.media-photos-list > li {
    float: left;
    margin-right: 6px;
    margin-bottom: 6px;
}

.media-photos-list > li img {
    width: 60px;
    height: 60px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.media-photos-list > li img:hover {
    opacity: .65;
    filter: alpha(opacity=65);
}

ul.ul-default {
    margin-left: 15px;
}

ul.ul-default > li {
    list-style-type: disc;
}

ul.ul-default > li > ul {
    margin-left: 30px;
}

ul.ul-default > li > ul > li {
    list-style-type: circle;
}

ul.ul-default-ustyled > li {
    list-style-type: none;
}

ul.ul-default-ustyled > li > ul {
    margin-left: 30px;
}

ul.ul-default-ustyled > li > ul > li {
    list-style-type: circle;
}

ol.ol-default {
    margin-left: 15px;
}

ol.ol-default > li {
    list-style-type: decimal;
}

ol.ol-default > li > ul {
    margin-left: 30px;
}

ol.ol-default > li > ul > li {
    list-style-type: lower-alpha;
}

ul.popular {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.popular li {
    clear: left;
    border-bottom: 1px dotted #f1f1f1;
    padding: 10px 0;
    display: block;
    width: 100%
}

ul.popular li:first-child {
    padding-top: 0;
}

ul.popular li img {
    width: 60px;
}

ul.popular li p {
    margin-left: 70px;
}

ul.popular li i {
    color: #a1a1a1;
    display: block;
    font-style: normal;
    font-size: 12px;
}

ul.popular li h3 {
    margin: 0 0 0 70px;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
}

ul.popular li h3 a {
    font-size: 13px;
    font-weight: 600;
}

ul.popular li a {
    font-weight: 400;
    line-height: 18px;
}

ul.popular li a:hover {
    text-decoration: none;
    color: #464646;
}

ul.popular li span {
    font-size: 12px;
}

ul.thumb-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.thumb-list li {
    clear: left;
    border-bottom: 1px dotted #e0eded;
    padding: 10px 0;
    display: block;
    width: 100%
}

ul.thumb-list li:first-child {
    padding-top: 0;
}

ul.thumb-list li img {
    width: 90px;
}

ul.thumb-list li p {
    margin-left: 100px;
}

ul.thumb-list li .thumb-item-text {
    margin-left: 100px;
}

ul.thumb-list li i {
    color: #a1a1a1;
    display: block;
    font-style: normal;
    font-size: 12px;
}

ul.thumb-list li h3 {
    margin: 0 0 0 100px;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
}

ul.thumb-list li h3 a {
    font-size: 13px;
    font-weight: 600;
}

ul.thumb-list li a {
    font-weight: 400;
    line-height: 18px;
}

ul.thumb-list li a:hover {
    text-decoration: none;
    color: #464646;
}

ul.thumb-list li span {
    font-size: 12px;
}

ul.featured {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.featured li {
    clear: left;
    border-bottom: 1px dotted #e0eded;
    padding: 10px 0 10px 0;
    display: block;
    width: 100%
}

ul.featured li:last-child {
    border: 0;
    padding-bottom: 0;
}

ul.featured li .featured-item {
    display: table;
    width: 100%
}

ul.featured li .featured-item .featured-item-img {
    display: table-cell;
    width: 35%;
    vertical-align: top;
}

ul.featured li .featured-item .feature-item-info {
    display: table-cell;
    padding-left: 8px;
    vertical-align: top;
}

ul.featured li img {
    width: 100%
}

ul.featured li .title {
    margin: 0;
    padding: 0;
    font-size: 13px;
}

ul.featured li .title a {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
}

ul.featured li p {
    font-size: 13px;
    line-height: 17px;
}

ul.featured li span {
    font-size: 12px;
}

ul.featured li .price {
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    color: #333;
}

ul.featured li .price.discount {
    text-decoration: line-through;
    color: #999;
    font-size: 12px;
    margin-right: 8px;
    font-weight: 400;
}

ul.featured li .featured-content {
    padding-left: 10px;
}

ul.featured-vertical li:last-child {
    border-bottom: 0;
}

ul.featured-vertical li .title {
    margin-top: 10px;
}

ul.featured-vertical li .title a {
    font-size: 14px;
}

ul.featured-vertical li .price-wr {
    display: table-cell;
}

ul.featured-vertical li .price {
    font-size: 16px;
    font-weight: 600;
    color: #2c2c2c;
}

ul.featured-vertical li .period {
    color: #999;
    margin-left: 3px;
}

ul.featured-vertical li .capacity-wr {
    display: table-cell;
}

ul.featured-vertical li .capacity {
    display: block;
    float: right;
}

ul.featured-vertical li .capacity i {
    margin-left: 3px;
    float: left;
    color: #999;
}

ul.recent {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.recent li {
    border-bottom: 1px dotted #e9e9e9;
}

ul.recent li:last-child {
    border: 0;
    padding-bottom: 0;
}

ul.recent li a {
    display: block;
    padding: 10px 0;
}

ul.recent li a:hover {
    border-color: #75b918;
}

ul.recent li h6 {
    margin: 0 0 10px 0;
}

ul.recent li h6 a {
    color: #353535;
    font-size: 14px;
    text-transform: none;
    text-decoration: none;
    font-weight: 600;
}

ul.meta-list {
    margin: 0;
    padding: 10px 15px 15px 15px;
    display: block;
    list-style: none;
}

ul.meta-list li:first-child {
    padding: 0;
    border-left: 0;
}

ul.meta-list li {
    display: inline-block;
    color: #a1a1a1;
}

ul.meta-list li a {
    color: #7a92ac;
}

ul.meta-list li a:hover {
    color: #e06d58;
    text-decoration: underline;
}

ul.bullet {
    list-style: none;
    margin: 0;
    padding: 0;
    background: transparent;
}

ul.bullet li {
    clear: left;
    padding: 10px 0;
    display: block;
    width: 100%
}

ul.bullet li > figure {
    margin: 5px 0 0 0;
    padding: 0;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 8px 0 0;
    text-align: center;
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    float: left;
}

ul.bullet li img {
    width: 60px;
}

ul.bullet li h3 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
    display: inline-block;
}

ul.bullet li p {
    margin: 0 0 0 50px;
    padding: 0;
}

ul.bullet li span {
    margin-left: 6px;
}

ul.bullet li a {
    font-weight: 500;
}

ul.bullet li a:hover {
    text-decoration: none;
    color: #464646;
}

ul.bullet li span {
    font-size: 12px;
}

ul.list-carousel {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.list-carousel li {
    padding: 6px 0;
    display: block;
    width: 100%;
    font-size: 16px;
}

ul.list-carousel li i {
    font-style: normal;
    margin-right: 4px;
}

ul.list-carousel li a {
    font-weight: 500;
}

ul.list-carousel li a:hover {
    text-decoration: none;
    color: #a1a1a1;
}

ul.list-carousel li span {
    font-size: 14px;
}

ul.social-icons {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
}

ul.social-icons li {
    display: inline-block;
}

ul.social-icons li a {
    display: block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
}

ul.social-icons li:hover a {
    color: #fff !important;
}

ul.social-icons li.text {
    height: 32px;
    padding-left: 10px;
    line-height: 32px;
}

ul.social-icons li.facebook:hover {
    background: #43609c;
    color: #fff !important;
}

ul.social-icons li.twitter:hover {
    background: #00aced;
    color: #fff !important;
}

ul.social-icons li.linkedin:hover {
    background: #517fa4;
    color: #fff !important;
}

dl.dl-group dt {
    font-size: 16px;
    color: #2c3e50;
}

dl.dl-group dd {
    margin-bottom: 10px;
}

.wp-masonry-wrapper:after {
    content: '';
    display: block;
    clear: both;
}

.wp-masonry-2-cols .wp-masonry-gutter {
    width: 4%
}

.wp-masonry-2-cols .wp-masonry-block {
    float: left;
    width: 48%;
    margin-bottom: 25px;
}

.wp-masonry-3-cols .wp-masonry-gutter {
    width: 2%
}

.wp-masonry-3-cols .wp-masonry-block {
    float: left;
    width: 32%;
    margin-bottom: 25px;
}

.wp-masonry-4-cols .wp-masonry-gutter {
    width: 2%
}

.wp-masonry-4-cols .wp-masonry-block {
    float: left;
    width: 23.500545%;
    margin-bottom: 25px;
}

.wp-masonry-block.col-2 {
    width: 49.1%
}

.wp-masonry-block.height2 {
    height: 200px;
}

.table {
    display: table;
}

.cell {
    display: table-cell;
    vertical-align: top;
}

.cell-15 {
    width: 15%
}

.cell-20 {
    width: 20%
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    vertical-align: middle;
    cursor: default;
}

.table.table-no-border > thead > tr > th, .table.table-no-border > tbody > tr > th, .table.table-no-border > tfoot > tr > th, .table.table-no-border > thead > tr > td, .table.table-no-border > tbody > tr > td, .table.table-no-border > tfoot > tr > td {
    border-top: 0;
    padding: 0;
}

ul.list-listings.blog-list li {
    border: 0;
}

ul.list-listings.blog-list .listing-header {
    clear: both;
    padding: 8px 15px;
    font-weight: 600;
    text-transform: uppercase;
}

ul.list-listings.blog-list .listing-image {
    width: 35%;
    float: left;
}

ul.list-listings.blog-list .listing-image img {
    width: 100%
}

ul.list-listings.blog-list .listing-body {
    width: 65%;
    height: auto;
    max-height: auto;
    padding: 0 15px;
    float: left;
}

ul.list-listings.blog-list .listing-body h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    text-transform: none;
}

ul.list-listings.blog-list .listing-body h4 {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-transform: none;
}

ul.list-listings.blog-list .listing-actions {
    width: 15%;
    height: 180px;
    position: relative;
    padding-top: 20px;
    float: left;
    text-align: center;
}

ul.list-listings.blog-list .listing-actions .btn {
    position: absolute;
    bottom: 20px;
    left: 25px;
}

ul.list-listings.blog-list .list-item-info {
    font-size: 11px;
    text-transform: uppercase;
    color: #999;
}

ul.list-listings.blog-list .list-item-info a {
    color: #8ec449;
}

.blog-masonry .wp-box, .blog-grid .wp-box, .blog-list .wp-box {
    margin-bottom: 25px;
}

.wp-box.blog-post {
    border: 0;
    padding: 0;
}

.blog-post h2 {
    font-size: 18px;
    line-height: 24px;
    color: #3b3e43;
    border: 0;
    padding: 25px 0 0 0 !important;
}

.blog-post p {
    padding: 8px 0 !important;
    font-size: 14px;
    color: #777;
}

.blog-post blockquote {
    margin: 8px 0;
}

.blog-post .meta-list {
    padding-left: 0 !important;
}

.blog-post img {
    width: 100%
}

.side-info {
    display: block;
}

.side-info .date {
    display: block;
    text-align: center;
    margin-top: 5px;
}

.side-info .date strong {
    display: block;
    margin-bottom: 5px;
    font-size: 33px;
    font-weight: normal;
}

.post-item {
    margin-bottom: 20px;
    position: relative;
}

.post-title {
    margin-top: 20px;
}

.post-image {
    position: relative;
}

.post-image img {
    display: block;
    width: 100%
}

.post-tags {
    font-size: 13px;
    font-weight: 400;
    color: #999;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.post-tags a {
    color: #8ec449;
}

.post-tags a:hover {
    text-decoration: underline;
}

.post-meta-bot .post-author, .post-meta-bot .post-comments {
    float: left;
    font-size: 12px;
    color: #616161;
    text-transform: uppercase;
    line-height: 14px;
}

.post-meta-bot .post-author a, .post-meta-bot .post-comments a {
    color: #8ec449;
}

.post-meta-bot .post-more {
    float: right;
    font-size: 14px;
    font-weight: 700;
    color: #3999d4;
    text-transform: uppercase;
}

.post-meta-bot .post-more a {
    color: #3999d4;
}

.post-meta-bot .btn {
    float: right;
}

@media (max-width: 479px) {
    .post-title {
        font-size: 21px;
    }
}

.post-item.style1 {
    border: 1px solid #e0eded;
}

.post-item.style1 .post-video {
    margin-bottom: 60px;
}

.post-item.style1 .post-meta-top {
    position: relative;
}

.post-item.style1 .post-title {
    margin: 15px 0 8px 0;
    padding: 0 15px;
    line-height: 22px;
}

.post-item.style1 .post-title a {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-transform: none;
}

.post-item.style1 .post-content {
    padding: 0 15px;
}

.post-item.style1 .post-content .post-desc {
    font-size: 13px;
    text-align: justify;
}

.post-item.boxed.style1 .post-content {
    padding: 24px 40px 12px 28px;
}

.post-item.style1 .post-tags {
    margin-bottom: 10px;
    font-style: normal;
}

.post-item.style1 .post-comments {
    float: right;
    margin-left: 30px;
    font-size: 12px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
}

.post-item.style1 .post-comments strong {
    display: block;
    margin-bottom: 6px;
    font-size: 38px;
    font-weight: 700;
    color: #2c2c2c;
}

.post-item.style1 .post-meta-bot {
    border-top: 1px solid #e0eded;
}

.post-item.style1 .post-meta-bot .like-button {
    position: relative;
    padding: 10px 15px;
    border-left: 1px solid #e0eded;
    background: #fcfcfc;
    border-bottom-right-radius: 2px;
}

.post-item.style1 .post-meta-bot .like-button .button i {
    color: #999;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .35s;
}

.post-item.style1 .post-meta-bot .like-button .count {
    display: none;
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #999;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .35s;
}

.post-item.style1 .post-meta-bot .like-button:hover .button i {
    color: #8ec449;
}

.post-item.style1 .post-meta-bot .like-button.checked .button i {
    color: #8ec449;
}

.post-item.style1 .post-meta-bot .like-button:hover .count {
    display: block;
}

.post-item.style1 .post-meta-bot .post-author {
    float: left;
    line-height: 55px;
    padding-left: 15px;
}

.post-item.style2 {
    padding-left: 95px;
}

.post-item.style2 .post-meta {
    width: 74px;
    position: absolute;
    left: 0;
    top: 20px;
    display: block;
    text-transform: uppercase;
}

.post-item.style2 .post-meta .date {
    color: #888;
    font-family: "Roboto", sans-serif;
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-align: center;
    border-bottom: 1px solid #e0eded;
}

.post-item.style2 .post-meta .date .icon {
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 8px;
}

.post-item.style2 .post-meta .date .month {
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin-top: 6px;
}

.post-item.style2 .post-meta .date .day {
    display: block;
    font-size: 36px;
    font-weight: 600;
}

.post-item.style2 .post-meta .date .year {
    display: block;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 6px;
}

.post-item.style2 .post-content-wr {
    position: relative;
    background-color: #F5F5F5;
    padding: 10px;
}

.post-item.style2 .post-video {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px;
}

.post-item.style2 .post-meta-top {
    position: relative;
}

.post-item.style2 .post-title {
    margin: 15px 0 8px 0;
    line-height: 26px;
}

.post-item.style2 .post-title a {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    text-transform: none;
    color: #000000;
}

.post-item.style2 .post-title a:hover {
    color: #9aca5c;
}

.post-item.style2 .post-content {
    padding-bottom: 0;
}

.post-item.style2 .post-content .post-desc {
    text-align: justify;
}

.post-item.boxed.style2 .post-content {
    padding: 24px 40px 12px 28px;
}

.post-item.style2 .post-tags {
    margin-bottom: 10px;
    font-style: normal;
}

.post-item.style2 .post-comments {
    padding: 10px;
    float: right;
    margin-left: 15px;
    font-size: 12px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    background: #fcfcfc;
    border-radius: 2px;
}

.post-item.style2 .post-comments strong {
    display: block;
    margin-bottom: 6px;
    font-size: 38px;
    font-weight: 700;
    color: #2c2c2c;
}

.post-item.style2 .post-meta-bot {
    border-top: 1px solid #e0eded;
    padding: 20px 0;
}

.comment-list {
    position: relative;
    margin-bottom: 30px;
}

.comment-list .comment-count {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #333;
}

.link-add-comment {
    position: absolute;
    top: 8px;
    right: 2px;
    color: #8ec449;
}

.link-add-comment:hover {
    color: #8ec449;
    text-decoration: underline;
}

.comment-list li.comment {
    padding-left: 0px;
    position: relative;
}

.comment-list .children li.comment:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    left: -28px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.comment-body {
    padding: 23px 20px;
    position: relative;
    margin-bottom: 18px;
    background: #fcfcfc;
    border: 1px solid #e0eded;
}

.comment-body:before, .comment-body:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 20px;
    left: -16px;
    border: solid transparent;
}

.comment-body:after {
    border-color: transparent;
    border-right-color: #fcfcfc;
    border-width: 8px;
}

.comment-body:before {
    border-color: transparent;
    border-right-color: #e0eded;
    border-width: 9px;
    margin-top: -1px;
    left: -18px;
}

.comment-avatar {
    position: absolute;
    top: 0;
    left: -80px;
    width: 65px;
    height: 65px;
    border: 3px solid #fff;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.comment-avatar img {
    display: block;
    width: 100%;
    height: 100%
}

.comment-author {
    margin-bottom: 5px;
}

.comment-author .comment-name {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    color: #666;
}

.comment-author .link-author {
    float: left;
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.comment-author .comment-meta {
    float: right;
    font-size: 12px;
    color: #999;
    text-align: right;
}

.comment-author .comment-meta .link-reply {
    color: #8ec449;
}

.comment-entry {
    line-height: 19px;
}

.comment-date {
    font-size: 11px;
    color: #666;
}

@media (max-width: 479px) {
    .comment-list .children li.comment {
        margin-left: -80px;
    }

    .comment-list .children li.comment:before {
        display: none;
    }

    .link-add-comment {
        top: 30px;
        left: 0;
        right: auto;
    }

    .comment-author .link-author, .comment-author .comment-meta {
        float: none;
        text-align: left;
        display: block;
    }

    .comment-body {
        padding: 10px;
    }
}

.widget-tags {
    background: #e6edf2;
    padding: 22px 20px 26px;
}

.widget-tags .inner {
    background: #fff;
    padding: 10px 16px 28px;
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.widget-tags .widget-title {
    font-size: 32px;
    color: #21649c;
    padding: 13px 9px 19px;
    margin: 0;
}

.widget-tags .widget-title i {
    width: 24px;
    height: 24px;
    border: 2px solid #3999d4;
    border-radius: 50%;
    cursor: pointer;
    background: url(images/arrows-thin.png) -27px -28px no-repeat;
    cursor: default;
    margin: 0 0 0 12px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
    .widget-tags .widget-title i {
        background-image: url(images/arrows-thin-x2.png);
        background-size: 50px auto;
    }
}

.tagcloud a {
    float: left;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    padding: 6px 10px;
    margin: 0 4px 4px 0;
    background: #8ec449;
    border-radius: 2px;
    -webkit-transition: background .2s linear;
    -moz-transition: background .2s linear;
    -ms-transition: background .2s linear;
    transition: background .2s linear;
}

.tagcloud a:hover {
    background: #a6d070;
}

.tagcloud.bordered a {
    background: transparent;
    border: 1px solid #8ec449;
    color: #8ec449;
}

.tagcloud.bordered.base a {
    background: transparent;
    border: 1px solid #8ec449;
    color: #8ec449;
}

.tagcloud.bordered.base a:hover {
    background: #8ec449;
    color: #fff;
}

.tagcloud.bordered.base-alt a {
    background: transparent;
    border: 1px solid #2c2c2c;
    color: #2c2c2c;
}

.tagcloud.bordered.base-alt a:hover {
    background: #2c2c2c;
    color: #fff;
}

.tagcloud.bordered.light a {
    background: transparent;
    border: 1px solid #ecf0f1;
    color: #131313;
}

.tagcloud.bordered.light a:hover {
    background: #ecf0f1;
    color: #131313;
}

.tagcloud.bordered.dark a {
    background: transparent;
    border: 1px solid #131313;
    color: #131313;
}

.tagcloud.bordered.dark a:hover {
    background: #131313;
    color: #fff;
}

.tagcloud.base, .tagcloud.base-alt, .tagcloud.light, .tagcloud.dark {
    background: transparent;
}

.tagcloud.base a {
    background: #8ec449;
}

.tagcloud.base a:hover {
    background: #a6d070;
}

.tagcloud.base-alt a {
    background: #2c2c2c;
}

.tagcloud.base-alt a:hover {
    background: #464646;
}

.tagcloud.light a {
    background: #ecf0f1;
}

.tagcloud.light a:hover {
    background: #fff;
}

.tagcloud.dark a {
    background: #131313;
}

.tagcloud.dark a:hover {
    background: #2d2d2d;
}

.widget-tags.styled {
    background: #fff;
    padding: 0;
}

.widget-tags.styled .inner {
    background: none;
    padding: 5px 26px 26px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 479px), (min-width: 768px) and (max-width: 991px) {
    .widget-tags.styled .inner {
        padding: 0 15px 20px;
    }
}

.ribbon {
    position: absolute;
    top: 20px;
    right: -5px;
    padding: 15px;
}

.ribbon.base {
    background: #8ec449;
    color: #fff;
    border-right: 5px solid #bedd96;
}

.ribbon.light {
    background: #ecf0f1;
    color: #2c3e50;
    border-right: 5px solid #dde4e6;
}

.ribbon.dark {
    background: #131313;
    color: #fff;
    border-right: 5px solid #464646;
}

.ribbon.base-alt {
    background: #2c2c2c;
    color: #fff;
    border-right: 5px solid #5f5f5f;
}

.ribbon.red {
    background: #e91b23;
    color: #fff;
    border-right: 5px solid #f2787d;
}

.ribbon.orange {
    background: #ff8a3c;
    color: #fff;
    border-right: 5px solid #ffc7a2;
}

.ribbon.yellow {
    background: #ffd800;
    color: #fff;
    border-right: 5px solid #ffe866;
}

.ribbon:before, .ribbon:after {
    content: '';
    position: absolute;
    left: -9px;
    border-left: 10px solid transparent;
}

.ribbon:before {
    top: 0;
}

.ribbon:after {
    bottom: 0;
}

.ribbon.base:before {
    border-top: 27px solid #8ec449;
}

.ribbon.base:after {
    border-bottom: 27px solid #8ec449;
}

.ribbon.light:before {
    border-top: 27px solid #ecf0f1;
}

.ribbon.light:after {
    border-bottom: 27px solid #ecf0f1;
}

.ribbon.dark:before {
    border-top: 27px solid #131313;
}

.ribbon.dark:after {
    border-bottom: 27px solid #131313;
}

.ribbon.base-alt:before {
    border-top: 27px solid #2c2c2c;
}

.ribbon.base-alt:after {
    border-bottom: 27px solid #2c2c2c;
}

.ribbon.red:before {
    border-top: 27px solid #e91b23;
}

.ribbon.red:after {
    border-bottom: 27px solid #e91b23;
}

.ribbon.orange:before {
    border-top: 27px solid #ff8a3c;
}

.ribbon.orange:after {
    border-bottom: 27px solid #ff8a3c;
}

.ribbon.yellow:before {
    border-top: 27px solid #ffd800;
}

.ribbon.yellow:after {
    border-bottom: 27px solid #ffd800;
}

.ribbon span {
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.badge-corner:empty {
    display: inline-block;
}

.badge-corner {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 66px solid #888;
    border-top-color: rgba(0, 0, 0, 0.3);
    border-left: 66px solid transparent;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
}

.badge-corner span {
    position: absolute;
    top: -52px;
    left: -28px;
    font-size: 16px;
    color: #fff;
}

.badge-corner-base {
    border-top-color: #8ec449;
}

.badge-corner-alt {
    border-top-color: #2c2c2c;
}

.badge-corner-light {
    border-top-color: #ecf0f1;
}

.badge-corner-light span {
    color: #2c3e50;
}

.badge-corner-dark {
    border-top-color: #131313;
}

.badge-corner-orange {
    border-top-color: #ff8a3c;
}

.star-rating {
    display: block;
}

.star-rating i {
    display: inline-block !important;
    color: #f7e90c !important;
}

.review-rating {
    font-size: 12px;
}

.rating {
    display: inline-block;
}

.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    float: left;
    padding: 0 1px;
    cursor: pointer;
}

.rating span.star:before {
    content: "\f005";
    display: block;
    font-size: 14px;
    color: #AFADAD;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    transition: all .2s linear;
}

.rating-large span.star:before {
    font-size: 30px;
}

.rating span.voted:before {
    color: #f7e90c;
}

.rating:hover span.star:before {
    color: #AFADAD !important;
}

.rating:hover span.star.over:before {
    color: #f7e90c !important;
}

.skills {
    clear: both;
    width: 100%
}

.skills ul, .skills li {
    display: inline-block;
    list-style: none;
    margin: 0 6px 0 0;
    padding: 0;
}

.skills li {
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 1px;
    font-size: 18px;
}

.skills .jq {
    background: #97be0d;
}

.skills .css {
    background: #d84f5f;
}

.skills .html {
    background: #88b8e6;
}

.skills .php {
    background: #bedbe9;
}

.skills .sql {
    background: #edebee;
}

.form-control {
    border-radius: 2px !important;
    padding: 8px 12px;
}

.form-base .form-control {
    margin-bottom: 10px;
    background: #74a535;
    border: 1px solid #66912f;
    color: #fff;
    font-size: 13px !important;
}

.form-base label {
    color: #fff;
}

.form-base label.checkbox {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
}

.form-base .form-control:focus {
    background: #74a535;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #66912f;
}

.form-base .form-control:-moz-placeholder {
    color: #fff;
}

.form-base .form-control::-moz-placeholder, .form-base .form-control[placeholder] {
    color: #fff;
}

.form-base .form-control:-ms-input-placeholder {
    color: #fff;
}

.form-base .form-control::-webkit-input-placeholder {
    color: #fff;
}

.form-light .form-control {
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background: #f9f9f9;
    /*border: 1px solid #ccc;*/
    color: #70808b;
    font-size: 13px !important;
}

div.form-light input.has-error {
    border: 1px solid #ff0000;
}

.form-light .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: #8ec449;
}

.form-light label.checkbox {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
}

.form-light .form-control:-moz-placeholder {
    color: #999;
}

.form-light .form-control::-moz-placeholder, .form-light .form-control[placeholder] {
    color: #999;
}

.form-light .form-control:-ms-input-placeholder {
    color: #999;
}

.form-light .form-control::-webkit-input-placeholder {
    color: #999;
}

.form-light .input-group .form-control.left {
    border-right: 0 !important;
}

.form-dark .form-control {
    margin-bottom: 10px;
    background: #131313;
    border: 1px solid #464646;
    font-size: 13px !important;
}

.form-dark label.checkbox {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
}

.form-dark label {
    color: #fff;
}

.form-dark .form-control:focus {
    background: #131313;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #fff;
}

.form-dark .form-control:-moz-placeholder {
    color: #999;
}

.form-dark .form-control::-moz-placeholder, .form-dark .form-control[placeholder] {
    color: #999;
}

.form-dark .form-control:-ms-input-placeholder {
    color: #999;
}

.form-dark .form-control::-webkit-input-placeholder {
    color: #999;
}

.form-alpha .form-control {
    height: 50px;
    margin-bottom: 0;
    background: transparent;
    border: 0;
    border-bottom: 0;
    padding: 10px 10px 10px 0;
}

.form-alpha label.checkbox {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
}

.form-alpha .form-control:focus {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
    color: #fff;
}

.form-alpha .form-control:-moz-placeholder {
    color: #999;
}

.form-alpha .form-control::-moz-placeholder, .form-light .form-control[placeholder] {
    color: #999;
}

.form-alpha .form-control:-ms-input-placeholder {
    color: #999;
}

.form-alpha .form-control::-webkit-input-placeholder {
    color: #999;
}

.user-form .form-icon {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin: 25px auto;
    text-align: center;
    line-height: 80px;
    font-size: 40px;
}

.user-form .form-header {
    padding: 15px 20px;
    background: #fcfcfc;
    border-bottom: 1px solid #e0eded;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.user-form .form-header h2 {
    margin: 0;
    padding: 0 !important;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    color: #333;
}

.user-form .form-body {
    padding: 15px 20px;
}

.user-form .form-section-title {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
}

.user-form .form-body p {
    padding-left: 0;
    margin-bottom: 10px;
}

.user-form .form-footer {
    padding: 15px;
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
}

.user-form .form-footer p {
    margin: 0;
}

.user-form .form-header.base {
    background: #8ec449;
    border-color: transparent;
}

.user-form .form-header.base-alt {
    background: #2c2c2c;
    border-color: transparent;
}

.user-form .form-header.light {
    background: #ecf0f1;
    border-color: transparent;
}

.user-form .form-header.dark {
    background: #131313;
    border-color: transparent;
}

.user-form .form-footer.base {
    background: #8ec449;
    border-color: transparent;
}

.user-form .form-footer.base-alt {
    background: #2c2c2c;
    border-color: transparent;
}

.user-form .form-footer.light {
    background: #ecf0f1;
    border-color: transparent;
}

.user-form .form-footer.dark {
    background: #131313;
    border-color: transparent;
}

.user-form-alpha {
    background: rgba(255, 255, 255, 0.75) !important;
}

.user-form-alpha .sky-form fieldset {
    background: transparent !important;
}

.user-form-alpha .form-header, .user-form-alpha .form-footer {
    background: rgba(252, 252, 252, 0.75) !important;
}

.inline-form-filters {
    position: relative;
    padding: 20px;
    border-radius: 2px;
}

.inline-form-filters .optional-form-filters-trigger:after {
    content: "\f078";
    font-family: FontAwesome;
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: -20px;
    background: #8ec449;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
}

.inline-form-filters .optional-form-filters-trigger.opened:after {
    content: "\f077"
}

.inline-form-filters.over-top-element {
    margin-top: -60px;
}

.inline-form-filters .form-group {
    width: 100%;
    margin: 0;
}

.inline-form-filters .form-group .form-control {
    width: 100%;
    margin: 0;
}

.hidden-form-filters {
    padding-top: 10px;
}

.sky-form a {
    color: #8ec449;
}

.sky-form .input input, .sky-form .select select, .sky-form .textarea textarea {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    height: 39px;
    padding: 8px 10px;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    background: #f9f9f9;
    appearance: normal;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.sky-form .input input, .sky-form .select select, .sky-form .textarea textarea, .sky-form .radio i, .sky-form .checkbox i, .sky-form .toggle i, .sky-form .icon-append, .sky-form .icon-prepend {
    border-color: #ccc;
    -ms-transition: border-color .3s;
    -moz-transition: border-color .3s;
    -webkit-transition: border-color .3s;
}

.sky-form .toggle i:before {
    background-color: #8ec449;
}

.sky-form .rating input + label:hover, .sky-form .rating input + label:hover ~ label {
    color: #8ec449;
}

.sky-form .radio, .sky-form .checkbox {
    margin-bottom: 4px;
    padding-left: 27px;
    font-size: 15px;
    line-height: 27px;
    color: #333;
    cursor: pointer;
}

.sky-form .input input:focus, .sky-form .select select:focus, .sky-form .textarea textarea:focus, .sky-form .radio input:focus + i, .sky-form .checkbox input:focus + i, .sky-form .toggle input:focus + i {
    border-color: #8ec449;
}

.sky-form .radio input + i:after {
    background-color: #8ec449;
}

.sky-form .checkbox input + i:after {
    color: #8ec449;
}

.sky-form .radio input:checked + i, .sky-form .checkbox input:checked + i, .sky-form .toggle input:checked + i {
    border-color: #8ec449;
}

.sky-form .rating input:checked ~ label {
    color: #8ec449;
}

.ui-datepicker-inline {
    border: 2px solid #ccc;
    background: #fff;
    box-shadow: none;
}

.sky-form .ui-slider {
    position: relative;
    height: 3px;
    border: 2px solid #ccc;
    background: #fff;
    margin: 12px 6px 26px;
}

.sky-form .ui-slider-handle {
    position: absolute;
    width: 15px;
    height: 15px;
    margin: -8px 0 0 -8px;
    border: 2px solid #ccc;
    outline: none;
    background: #fff;
    -ms-transition: border-color .3s;
    -moz-transition: border-color .3s;
    -webkit-transition: border-color .3s;
}

ol.progtrckr {
    margin-bottom: 40px;
    padding: 0;
    list-style-type: none;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 3em;
}

ol.progtrckr[data-progtrckr-steps="2"] li {
    width: 49%
}

ol.progtrckr[data-progtrckr-steps="3"] li {
    width: 33%
}

ol.progtrckr[data-progtrckr-steps="4"] li {
    width: 24%
}

ol.progtrckr[data-progtrckr-steps="5"] li {
    width: 19%
}

ol.progtrckr[data-progtrckr-steps="6"] li {
    width: 16%
}

ol.progtrckr[data-progtrckr-steps="7"] li {
    width: 14%
}

ol.progtrckr[data-progtrckr-steps="8"] li {
    width: 12%
}

ol.progtrckr[data-progtrckr-steps="9"] li {
    width: 11%
}

ol.progtrckr li.progtrckr-done {
    color: #333;
    border-bottom: 4px solid #2c2c2c;
}

ol.progtrckr li.progtrckr-todo {
    color: #c0c0c0;
    border-bottom: 4px solid #c0c0c0;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
    font-family: FontAwesome;
}

ol.progtrckr li:before {
    position: relative;
    bottom: -2.5em;
    float: left;
    left: 50%;
    line-height: 1em;
}

ol.progtrckr li.progtrckr-done:before {
    content: "\f058";
    font-family: FontAwesome;
    color: #fff;
    background-color: #2c2c2c;
    height: 1.2em;
    width: 1.2em;
    line-height: 1.2em;
    border: none;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-todo:before {
    content: "\f111";
    font-family: FontAwesome;
    color: #c0c0c0;
    background-color: transparent;
    font-size: 1.5em;
    bottom: -1.6em;
}

.social-media {
    position: relative;
}

.social-media i {
    z-index: 2200;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center !important;
    font-size: 18px;
    background: #ddd;
    color: #333;
    border-radius: 2px;
}

.social-media.social-media-sm i {
    width: 26px;
    height: 26px;
    line-height: 26px;
    margin: 10px 5px 0 0;
    font-size: 13px;
}

.social-media.social-media-lg i {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 22px;
}

.social-media .facebook {
    background: #43609c;
    color: #fff;
}

.social-media .twitter {
    background: #62addb;
    color: #fff;
}

.social-media .google {
    background: #dd4b39;
    color: #fff;
}

.social-media i:hover {
    background: transparent;
    color: #a1a1a1;
}

.form-errors {
    width: 100%;
    margin-bottom: 20px;
}

.form-errors .error {
    display: block;
    color: #ce1a33;
    font-weight: 500;
}

.help-inline {
    font-size: 11px;
    color: #b8321f;
    position: relative;
    top: -8px;
}

#info-box {
    display: none;
    text-align: center;
    margin-top: 30px;
    color: #59b540;
}

#info-box h2 {
    font-size: 16px;
    font-weight: 600;
}

.testimonial-text {
    width: 70%;
    margin: 20px auto;
    font-size: 14px;
    line-height: 24px;
}

.testimonial-author {
    display: block;
    text-align: center;
    color: #a1a1a1;
    font-style: italic;
}

.carousel-testimonials .testimonial-author-info {
    padding-top: 28px;
}

.carousel-testimonials .testimonial-author-info a {
    padding-left: 20px;
}

.contact-info {
    margin-bottom: 20px;
}

.contact-info h5 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 4px;
}

.contact-info h5 i {
    margin-right: 4px;
    color: #8ec449;
}

.contact-info-inline p {
    margin: 0;
    padding: 0;
}

.client {
    border: 1px solid #e0eded;
    padding: 0 15px;
    background: transparent;
}

.client img {
    width: 100%;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
}

.client img:hover {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    filter: grayscale(0);
}

footer {
    padding-top: 15px;
    padding-bottom: 20px;
    min-height: 30px;
    background: #232323;
}

footer:before, footer:after {
    display: table;
    content: " "
}

footer:after {
    clear: both;
}

footer .col.reset {
    margin: 0;
}

footer h4 {
    margin-top: 20px;
    color: #ccc;
    margin-bottom: 20px;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 500;
}

footer .col p {
    color: #ccc;
    font-size: 13px;
    margin-bottom: 10px;
}

footer a {
    color: #ccc;
    text-decoration: none;
}

footer a:hover {
    text-decoration: none;
}

footer .col ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .col ul li {
    color: #8f8f8f;
}

footer .col ul li span {
    color: #fff;
}

footer .col address {
    color: #ddd;
    padding: 8px 0;
}

footer .company-info {
    font-size: 10px;
    text-align: justify;
}

footer .company-info h2 {
    font-size: 14px;
    font-weight: 600;
}

footer .col.col-social-icons i {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    color: #333;
    border-radius: 2px;
}

footer form {
    margin-top: 20px;
}

footer hr {
    border-top: 1px solid #444;
}

footer .copyright {
    color: #fff;
}

.fontawesome-icon-list {
    margin-top: 22px;
}

.fontawesome-icon-list .fa-hover a {
    display: block;
    color: #222;
    line-height: 32px;
    height: 32px;
    padding-left: 10px;
    border-radius: 0;
}

.fontawesome-icon-list .fa-hover a .fa {
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
}

.fontawesome-icon-list .fa-hover a:hover {
    background-color: #1d9d74;
    color: #fff;
    text-decoration: none;
}

.fontawesome-icon-list .fa-hover a:hover .fa {
    font-size: 28px;
    vertical-align: -6px;
}

.fontawesome-icon-list .fa-hover a:hover .text-muted {
    color: #bbe2d5;
}

blockquote footer {
    background: transparent;
}

.blockquote-1:hover {
    border-color: #8ec449;
}

.blockquote-1 p {
    font-size: 13px;
}

.section-title-wr {
    display: table;
    width: 100%;
    margin-bottom: 15px;
}

.section-title-wr.base, .section-title-wr.dark, .section-title-wr.light, .section-title-wr.base-alt {
    background: transparent;
}

.section-title-wr .aux-nav {
    display: table-cell;
    width: 30%;
    padding: 8px 0;
    text-align: right;
}

.section-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    /*text-transform: capitalize;*/
    position: relative;
}

.section-title-small {
    font-size: 24px;
}

.section-title.left {
    display: table-cell;
    color: #333;
    font-size: 32px;
}

.section-title.left small {
    display: block;
    margin-top: 4px;
    color: #999;
}

.section-title-wr .section-title.center {
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-size: 26px;
    color: #333;
}

.section-title-wr .section-title.center small {
    display: block;
    margin-top: 8px;
    font-size: 13px;
    color: #999;
}

.section-title-wr.style-2 {
    margin-bottom: 15px;
    border-bottom: 2px solid #8ec449;
}

.section-title-wr.style-2 .aux-nav a {
    color: #616161;
}

.section-title-wr.style-2 .section-title.left {
    padding: 0;
}

.section-title-wr.style-2 .section-title.left span {
    display: inline-block;
    margin-bottom: 2px;
    padding: 10px 15px;
}

.section-title-wr.style-2 .section-title.left small {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
}

.section-title-wr.style-2.base {
    border-color: #8ec449;
}

.section-title-wr.style-2.light {
    border-color: #ecf0f1;
}

.section-title-wr.style-2.dark {
    border-color: #131313;
}

.section-title-wr.style-2.base-alt {
    border-color: #2c2c2c;
}

.section-title-wr.style-2.base .section-title.left span {
    background: #8ec449;
    color: #fff;
}

.section-title-wr.style-2.light .section-title.left span {
    background: #ecf0f1;
    color: #333;
}

.section-title-wr.style-2.dark .section-title.left span {
    background: #131313;
    color: #fff;
}

.section-title-wr.style-2.base-alt .section-title.left span {
    background: #2c2c2c;
    color: #fff;
}

.section-title-wr.style-3 {
    margin-bottom: 20px;
}

.section-title-wr.style-3 span {
    padding-bottom: 4px;
    border-bottom: 1px solid #333;
}

.section-title-wr.style-3 small {
    margin-top: 12px !important;
    color: #999;
}

.base .section-title-wr.style-3 span {
    border-bottom: 1px solid #fff;
}

.base-alt .section-title-wr.style-3 span {
    border-bottom: 1px solid #fff;
}

.light .section-title-wr.style-3 span {
    border-bottom: 1px solid #333;
}

.dark .section-title-wr.style-3 span {
    border-bottom: 1px solid #fff;
}

.section-title-wr.style-4 {
    margin-bottom: 15px;
}

.section-title-wr.style-4 .section-title {
    border-bottom: 1px solid #e0eded;
    padding-bottom: 5px;
}

.section-title-wr.style-4 span {
    padding-bottom: 4px;
    border-bottom: 3px solid #8ec449;
    background: #fff;
}

.section-title-wr.style-4 {
    margin-bottom: 15px;
}

.section-title-wr.style-4 span {
    padding-bottom: 4px;
}

.section-title-wr.style-4 span:after {
    border-bottom: 1px solid #333;
}

.section-title-wr.style-4 small {
    margin-top: 14px;
    color: #999;
}

.layer-slider-wrapper {
    font-size: 20px;
    overflow: hidden;
    border: 0;
    padding: 0;
}

.layer-slider-static {
    height: 540px;
}

.layer-slider-dynamic {
    max-height: auto !important;
}

.layer-slider-wrapper .title {
    font-size: 40px;
    line-height: 44px;
    font-weight: 400;
}

.layer-slider-wrapper .title-xs {
    font-size: 24px;
    line-height: 28px;
}

.layer-slider-wrapper .title-sm {
    font-size: 36px;
    line-height: 46px;
}

.layer-slider-wrapper .title-md {
    font-size: 64px;
    line-height: 68px;
}

.layer-slider-wrapper .title-lg {
    font-size: 80px;
    line-height: 84px;
}

.layer-slider-wrapper .text-shadow {
    text-shadow: 0 2px 2px #222;
}

.layer-slider-wrapper .subtitle {
    font-size: 22px;
    line-height: 30px;
    color: #8ec449;
    text-transform: capitalize;
}

.layer-slider-wrapper .list-item {
    font-size: 18px;
    line-height: 30px;
    padding-left: 30px;
    color: #8ec449;
    text-transform: capitalize;
}

.layer-slider-wrapper .list-item-2 span {
    background: #8ec449;
    font-size: 18px;
    padding: 10px 15px;
    color: #fff;
}

.layer-slider-wrapper .list-item-2 i {
    padding: 12px 12px 11px 12px;
    font-size: 20px;
    background: #333;
    color: #fff;
}

.layer-slider-wrapper .text-standard {
    font-size: 16px;
    line-height: 22px;
}

.layer-slider-wrapper .text-bordered {
    padding: 30px;
    border: 2px solid #fff;
}

.layer-slider-wrapper .text-wrapped {
    padding: 30px;
}

.static-page-image {
    position: relative;
    max-height: 500px;
    overflow: hidden;
}

.static-page-image img {
    width: 100%
}

.static-page-image .description-left {
    position: absolute;
    width: 40%;
    top: 50px;
    left: 120px;
    width: 514px;
    height: 290px;
}

.static-page-image .description-left .title {
    font-size: 3vw;
    margin: 0 0 15px 0;
    padding: 10px 0;
    line-height: 3.5vw;
    font-weight: 600;
}

.static-page-image .description-left .subtitle {
    font-size: 1.5vw;
    margin: 20px 0;
    display: block;
    line-height: 1.8vw;
    text-transform: none;
}

.static-page-image .description-left p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

.static-page-image .title {
    font-size: 3vw !important;
    line-height: 3.5vw;
    font-weight: 400;
}

.static-page-image .title-xs {
    font-size: 2vw;
    line-height: 2.5vw;
}

.static-page-image .title-sm {
    font-size: 2.5vw !important;
    line-height: 3vw;
}

.static-page-image .title-md {
    font-size: 3vw !important;
    line-height: 3.5vw;
}

.static-page-image .title-lg {
    font-size: 5.5vw !important;
    line-height: 6vw;
}

.static-page-image .text-shadow {
    text-shadow: 0 2px 2px #222;
}

.static-page-image .subtitle {
    font-size: 22px;
    line-height: 30px;
    color: #8ec449;
    text-transform: capitalize;
}

.static-page-image .list-item {
    font-size: 18px;
    line-height: 30px;
    padding-left: 30px;
    color: #8ec449;
    text-transform: capitalize;
}

.static-page-image .list-item-2 span {
    background: #8ec449;
    font-size: 18px;
    padding: 10px 15px;
    color: #fff;
}

.static-page-image .list-item-2 i {
    padding: 12px 12px 11px 12px;
    font-size: 20px;
    background: #333;
    color: #fff;
}

.static-page-image .text-standard {
    font-size: 16px;
    line-height: 22px;
}

.static-page-image .text-bordered {
    padding: 30px;
    border: 2px solid #fff;
}

.static-page-image .text-wrapped {
    padding: 30px;
}

.box-element {
    padding: 20px;
}

.box-element:nth-child(n+1) {
    margin-top: 20px;
}

.box-element h1 {
    margin: 10px 0 !important;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
}

.box-element.box-element-bordered {
    background: transparent !important;
    border: 1px solid;
}

.box-element.box-element-bordered.base {
    border-color: #8ec449;
    color: #8ec449 !important;
}

.box-element.box-element-bordered.base h1 {
    color: #8ec449;
    color: #8ec449 !important;
}

.box-element.box-element-bordered.base-alt {
    border-color: #2c2c2c;
    color: #2c2c2c;
}

.box-element.box-element-bordered.base-alt h1 {
    color: #2c2c2c;
    color: #2c2c2c !important;
}

.box-element.box-element-bordered.light {
    border-color: #ecf0f1;
    color: #ecf0f1 !important;
}

.box-element.box-element-bordered.light h1 {
    border-color: #ecf0f1;
    color: #ecf0f1 !important;
}

.box-element.box-element-bordered.dark {
    border-color: #131313;
    color: #131313 !important;
}

.box-element.box-element-bordered.dark h1 {
    color: #131313;
    color: #131313 !important;
}

.box-element.box-element-outer {
    padding-left: 0;
    padding-right: 0;
}

.carousel-2 {
    position: relative;
}

.carousel-2 .item {
    padding: 36px 0 !important;
}

.carousel-2 .title {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #333;
}

.carousel-2 .carousel-indicators {
    bottom: 0;
}

.carousel-2 .carousel-indicators li {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.carousel-2 .carousel-indicators .active {
    background-color: #8ec449;
}

.carousel-2 .img-thumbnail {
    margin-top: 26px;
}

.carousel-2 h2 {
    font-size: 22px;
}

.carousel-2 .carousel-nav a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    margin-top: 0;
    font-size: 18px;
    text-align: center;
    border: 1px solid transparent;
    background: #f5f5f5;
    color: #8ec449;
    opacity: 1;
}

.carousel-2 .carousel-nav a:hover {
    background: #8ec449 !important;
    color: #fff;
}

.carousel-2 .carousel-nav a.left {
    right: 36px;
}

.carousel-2 .carousel-nav a.right {
    right: 0;
}

.carousel-2 .carousel-control i {
    position: absolute;
    top: 50%;
    font-size: 22px;
    margin-top: -11px;
}

.carousel-2 .carousel-control.left i {
    left: 18px;
}

.carousel-2 .carousel-control.right i {
    right: 18px;
}

.carousel-3 {
    position: relative;
}

.carousel-3 .carousel-nav a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: -40px;
    right: 0;
    margin-top: 0;
    font-size: 18px;
    text-align: center;
    border: 1px solid transparent;
    background: #f5f5f5;
    color: #8ec449;
    opacity: 1;
}

.carousel-3 .carousel-nav a:hover {
    background: #8ec449 !important;
    color: #fff;
}

.carousel-3 .carousel-nav a.left {
    right: 36px;
}

.carousel-3 .carousel-nav a.right {
    right: 0;
}

.carousel-3 .carousel-nav a:hover {
    background: #fff;
}

.carousel-testimonials {
    padding: 20px 0;
    position: relative;
}

.carousel-testimonials h4 {
    font-weight: 400;
}

.carousel-testimonials .carousel-indicators {
    bottom: -15px !important;
}

.carousel-testimonials .carousel-indicators li {
    background-color: #8ec449;
}

.like-button .button {
    display: block;
    text-align: right;
    padding-top: 10px;
    color: #ddd;
}

.like-button .button i {
    font-size: 20px;
    color: #ddd;
}

.like-button .button.liked i {
    color: #8ec449;
}

.like-button .count {
    display: block;
    text-align: right;
    position: relative;
    top: -7px;
}

.like-button.inline .button {
    display: inline-block;
    padding: 0;
}

.like-button.inline .count {
    display: inline-block;
    top: -2px;
}

.like-button.inline .count small {
    font-size: 13px;
}

.side-like-box {
    text-align: center;
    padding: 5px 5px 0 5px;
    margin-top: 10px;
}

.side-like-box .button {
    text-align: center;
    padding: 0;
}

.side-like-box .count {
    text-align: center;
}

.side-like-box i {
    font-size: 24px;
}

ul.list-listings {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
}

ul.list-listings > li {
    margin-bottom: 20px;
    border: 1px solid #e0eded;
    border-radius: 2px;
}

ul.list-listings > li.featured {
    border-color: #8ec449;
}

ul.list-listings > li:before, ul.list-listings li:after {
    content: "";
    display: table;
}

ul.list-listings > li:after {
    clear: both;
}

ul.list-listings .listing-header {
    display: block;
    clear: both;
    padding: 8px 15px;
    font-weight: 600;
    text-transform: uppercase;
}

ul.list-listings .listing-image {
    width: 30%;
    display: table-cell;
}

ul.list-listings .listing-image img {
    border-bottom-left-radius: 2px;
}

ul.list-listings .listing-body {
    background-color: white;
    padding: 10px 15px;
    display: table-cell;
    vertical-align: top;
}

ul.list-listings .listing-body h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}

ul.list-listings .listing-body h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}

ul.list-listings .listing-body p {
    margin: 5px 0;
}

ul.list-listings .listing-body .meta-info {
    border-top: 1px solid #e0eded;
    padding-top: 7px;
}

ul.list-listings .listing-body .meta-info ul {
    width: 100%;
    display: table;
    table-layout: auto;
}

ul.list-listings .listing-body .meta-info ul li {
    display: table-cell;
    border-right: 1px solid #e0eded;
    text-align: center;
}

ul.list-listings .listing-body .meta-info ul li.stretch {
    width: 40%
}

ul.list-listings .listing-body .meta-info ul li i {
    color: #333;
    margin-right: 5px;
}

ul.list-listings .listing-body .meta-info ul li:first-child {
    padding-left: 0;
}

ul.list-listings .listing-body .meta-info ul li:last-child {
    padding-right: 0;
    border: 0;
}

ul.list-listings .listing-actions {
    width: 20%;
    display: table-cell;
    text-align: center;
    vertical-align: top;
    position: relative;
    border-left: 1px solid #e0eded;
}

ul.list-listings .listing-actions .btn {
    margin-top: 6px;
}

ul.list-listings-2 {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
}

ul.list-listings-2 > li {
    margin-bottom: 30px;
    border: 1px solid #e0eded;
    border-radius: 2px;
}

ul.list-listings-2 > li:before, ul.list-listings-2 li:after {
    content: "";
    display: table;
}

ul.list-listings-2 > li:after {
    clear: both;
}

ul.list-listings-2 > li.featured {
    border-color: #8ec449;
}

ul.list-listings-2 .listing-header {
    clear: both;
    padding: 8px 15px;
    font-weight: 600;
    text-transform: uppercase;
}

ul.list-listings-2 .listing-image {
    width: 30%;
    position: relative;
    display: table-cell;
    vertical-align: top;
    border-right: 1px solid #e0eded;
}

ul.list-listings-2 .listing-image img {
    border-bottom-left-radius: 2px;
}

ul.list-listings-2 .listing-image .btn {
    position: absolute;
    bottom: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

ul.list-listings-2 .listing-body {
    padding: 14px;
    background: transparent;
}

ul.list-listings-2 .listing-body h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}

ul.list-listings-2 .listing-body h4 {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}

ul.list-listings-2 .listing-body p {
    margin-bottom: 5px;
}

ul.list-listings-2 .listing-footer ul.aux-info {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    background: #fff;
    border-top: 1px solid #e0eded;
}

ul.list-listings-2 .listing-footer ul.aux-info li {
    display: table-cell;
    padding: 11px 15px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

ul.list-listings-2 .listing-footer ul.aux-info li:last-child {
    border: 0;
}

ul.list-listings-2 .listing-footer ul.aux-info li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

ul.list-check {
    list-style: none;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
}

ul.list-check li {
    padding: 4px 0;
    margin: 0;
    display: block;
    width: 100%
}

ul.list-check li i {
    color: #8ec449;
    font-style: normal;
    margin-right: 4px;
}

ul.list-check li span {
    font-size: 14px;
}

ul.categories {
    list-style: none;
    margin: 0;
    padding: 0 !important;
    border: 1px solid #e0eded;
    overflow: hidden;
}

ul.categories > li {
    border-bottom: 1px solid #e0eded;
    position: relative;
}

ul.categories > li:last-child {
    border: 0;
}

ul.categories > li > a {
    background: #fff;
    display: block;
    padding: 10px 15px;
}

ul.categories > li > a:after {
    font-family: 'FontAwesome';
    content: "\f105";
    position: relative;
    top: 0;
    float: right;
}

ul.categories > li > a:hover {
    background: #8ec449;
    color: #fff;
    text-decoration: none;
}

ul.categories > li > a i {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
    font-style: normal;
    color: #999;
    font-size: 11px;
}

ul.categories > li > a:hover i {
    color: #fff;
}

ul.categories > li > ul {
    margin: 0;
    padding: 0;
    background: #fcfcfc;
}

ul.categories > li > ul > li {
    border-top: 1px solid #e0eded;
}

ul.categories > li > ul > li > a {
    display: block;
    padding: 5px 25px;
}

ul.aux-info-cells {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    background: #fff;
}

ul.aux-info-cells li {
    color: #333;
    display: table-cell;
    padding: 11px 15px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

ul.aux-info-cells li:last-child {
    border: 0;
}

ul.aux-info-cells li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

.timeline .year {
    width: 100%;
    background: #131313;
    padding: 8px 10px;
    margin: 20px auto 40px !important;
    font-size: 20px;
}

.timeline .year {
    border-radius: 3px;
}

.timeline .event {
    padding: 0 !important;
    border: 1px solid #e0eded;
    border-radius: 0;
}

.timeline .event:nth-child(2n):before {
    content: "";
    display: inline-block;
    position: absolute;
    right: -6.8% !important;
    top: 20px;
    width: 10px;
    height: 10px;
    background: #8ec449;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.timeline .event:nth-child(2n-1):after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -12px !important;
    top: 12px;
    width: 0;
    height: 0;
    border-right: 12px solid #fcfcfc;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

.timeline .event:nth-child(2n-1):before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -6.5% !important;
    top: 20px;
    width: 10px;
    height: 10px;
    background: #8ec449;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.timeline .event-date {
    margin: 0;
    background: #fff;
    border-bottom: 1px solid #e0eded;
    text-align: left;
    padding: 10px 10px;
    font-weight: 500;
    font-size: 14px;
}

.timeline .event:nth-child(2n) .event-date:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: -12px !important;
    top: 12px;
    width: 0;
    height: 0;
    border-left: 12px solid #fcfcfc;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    z-index: 20;
}

.timeline .event:nth-child(2n) .event-date:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 11px;
    right: -13px;
    width: 0;
    height: 0;
    border-left: 13px solid #e0eded;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    z-index: 0;
}

.timeline .event:nth-child(2n-1) .event-date:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -12px !important;
    top: 12px;
    width: 0;
    height: 0;
    border-right: 12px solid #fcfcfc;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    z-index: 20;
}

.timeline .event:nth-child(2n-1) .event-date:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 11px;
    left: -13px;
    width: 0;
    height: 0;
    border-right: 13px solid #e0eded;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    z-index: 0;
}

.timeline .event-date {
    padding: 15px 10px;
    background: #fcfcfc;
}

.timeline .event-date h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    color: #333;
}

.timeline .event-date small {
    display: block;
    font-size: 12px;
    color: #a1a1a1;
    font-weight: normal;
}

.timeline .event-date i {
    margin-right: 7px;
}

.timeline .event-body {
    background: #fff;
    padding: 10px;
}

.timeline .event-footer {
    margin: 0;
    text-align: left;
    background: transparent;
    border-top: 1px solid #e0eded;
}

.timeline .event-footer:after, .timeline .event-footer:before {
    display: table;
    content: " "
}

.timeline .event-footer:after {
    clear: both;
}

.timeline .event img {
    margin: 0 0 15px 0;
}

.timeline p {
    text-align: justify;
}

.timeline .embed-responsive {
    margin-bottom: 15px;
}

#toTop {
    display: none;
    text-decoration: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border: 0;
    text-indent: 100%;
    background: #555;
    border-radius: 3px;
}

#toTopHover {
    background: #8ec449;
    width: 40px;
    height: 40px;
    display: block;
    overflow: hidden;
    float: left;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
}

#toTop:active, #toTop:focus {
    outline: none;
}

#toTop:before {
    font-family: 'FontAwesome';
    content: "\f106";
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    text-indent: 0;
}

.widget.tags-wr {
    padding-bottom: 15px;
}

.tags-list:before, .tags-list:after {
    display: table;
    content: ""
}

.tags-list:after {
    clear: both;
}

.tags-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.tags-list li {
    border: 1px solid #8ec449;
    background: #fff;
    padding: 5px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #8ec449;
    font-size: 12px;
}

.tags-list li a {
    color: #8ec449;
    margin-left: 4px;
}

.tags-list li:hover {
    background: #8ec449;
    color: #fff;
}

.tags-list li:hover a {
    color: #fff;
    text-decoration: none;
}

.range-slider-wrapper {
    position: relative;
}

.range-slider-wrapper .range-slider-val-low {
    position: absolute;
    left: 0;
}

.range-slider-wrapper .range-slider-val-up {
    position: absolute;
    right: 0;
}

.range-slider-wrapper .tooltip {
    display: block;
    position: absolute;
    top: -34px;
    left: -16px;
    border-radius: 2px;
    background: #111;
    color: #fff;
    padding: 5px;
    text-align: center;
    width: 50px;
    opacity: .7;
}

.range-slider-wrapper .tooltip:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(17, 17, 17, 0);
    border-top-color: #111;
    border-width: 6px;
    margin-left: -6px;
}

.range-slider-wrapper .tooltip strong {
    display: block;
    padding: 2px;
}

.user-profile-img {
    border-top: 3px solid #8ec449;
}

.user-profile-img img {
    width: 100%
}

.progress {
    height: 20px;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: #f5f5f5 !important;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.progress-lg {
    height: 26px;
}

.progress.progress-md {
    height: 16px;
}

.progress.progress-sm {
    height: 10px;
}

.progress.progress-xs {
    height: 4px;
}

.progress .sr-only {
    width: auto;
    height: 20px;
    margin: 0;
    margin-left: 30px;
    left: 0;
    clip: auto;
    line-height: 20px;
    font-size: 13px;
}

.progress.progress-lg .sr-only {
    height: 26px;
    line-height: 26px;
}

.progress.progress-sm .sr-only {
    height: 14px;
    line-height: 14px;
}

.progress.progress-xs .sr-only {
    height: 8px;
    line-height: 8px;
}

.progress.base {
    background-color: #8ec449;
}

.progress.base-alt {
    background-color: #2c2c2c;
}

.progress.light {
    background-color: #ecf0f1;
}

.progress.dark {
    background-color: #131313;
}

.progress {
    height: 6px;
    margin-bottom: 10px;
    background-color: #fcfcfc;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-bar {
    background-color: #8ec449;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.progress-text {
    margin-bottom: 5px;
}

.progress-text .mark {
    float: left;
    width: 50%;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background: transparent;
}

.progress-text .mark.right {
    text-align: right;
}

.progress.style-3 .progress-bar {
    background-image: -webkit-gradient(linear, 100% 0, 0 100%, color-stop(.25, rgba(255, 255, 255, 0.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, 0.15)), color-stop(.75, rgba(255, 255, 255, 0.15)), color-stop(.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-color: #35a7e6;
    background-size: 20px 20px;
}

.progress.style-3 .progress-bar.base-alt {
    background-color: #2c2c2c;
}

.progress.style-3 .progress-bar.light {
    background-color: #ecf0f1;
}

.progress.style-3 .progress-bar.dark {
    background-color: #131313;
}

.progress.download {
    position: relative;
}

.progress.download .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
}

.progress.download .download-bar {
    background-color: #74a535;
}

.progress.download .download-bar.base-alt {
    background-color: #121212;
}

.progress.download .download-bar.light {
    background-color: #cfd9db;
}

.progress.download .download-bar.dark {
    background-color: #000;
}

.tab-body {
    padding: 15px;
}

div.wp-tabs {
    margin-bottom: 0;
}

div.wp-tabs:before, div.wp-tabs:after {
    display: table;
    content: " "
}

div.wp-tabs:after {
    clear: both;
}

div.wp-tabs div.tab-content {
    -moz-border-radius: none;
    -moz-box-shadow: none;
    -webkit-border-radius: 0;
    -webkit-box-shadow: 0;
    background-color: #fff;
    border: 1px solid #e0eded;
    border-radius: 0;
    border-top: 0;
    box-shadow: none;
    padding: 15px;
}

div.wp-tabs div.tab-content.tab-content-inverse {
    border: 1px solid #eee;
    background: transparent;
}

div.wp-tabs ul.nav-tabs {
    margin: 0;
}

div.wp-tabs ul.nav-tabs li.active a {
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
    color: #333;
}

div.wp-tabs ul.nav-tabs a {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #fcfcfc;
    border: 0;
    border-bottom: 0;
    margin-right: 0;
    color: #333;
}

div.wp-tabs ul.nav-tabs a:hover {
    border-top: 1px solid #e0eded;
    color: #7a92ac;
}

div.wp-tabs ul.nav-tabs a:active, div.wp-tabs ul.nav-tabs a:focus {
    border-bottom: 0;
}

div.wp-tabs-left ul.nav-tabs a:active, div.wp-tabs-left ul.nav-tabs a:focus {
    border-right: 0;
}

div.wp-tabs ul.nav-tabs a, div.wp-tabs ul.nav-tabs a:hover {
    border: 1px solid #eee;
    border-right: 0;
    border-top: 1px solid #e0eded;
    font-size: .9em;
}

div.wp-tabs ul.nav-tabs li:last-child a, div.wp-tabs ul.nav-tabs li:last-child a:hover {
    border-right: 1px solid #e0eded;
}

div.wp-tabs-left ul.nav-tabs a, div.wp-tabs-left ul.nav-tabs a:hover {
    border: 1px solid #eee;
    border-right: 0;
    border-left: 2px solid #ddd;
    color: #ccc;
    font-size: .9em;
}

div.wp-tabs-right ul.nav-tabs a, div.wp-tabs-right ul.nav-tabs a:hover {
    border: 1px solid #eee;
    border-left: 0;
    border-right: 2px solid #ddd;
    color: #ccc;
    font-size: .9em;
}

.tabbable.wp-tabs-left {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 30px;
}

div.tabbable.wp-tabs-left div.tab-content {
    -moz-border-radius: 0 0 2px 2px;
    -moz-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 0 0 2px 2px;
    border-left: 0;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    padding: 15px;
}

div.tabbable.wp-tabs-left ul.nav-tabs a {
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    background: #f7f7f7;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 2px 2px 0 0;
    color: #666;
    margin-bottom: 3px;
}

div.tabbable.wp-tabs-left ul.nav-tabs li.active a {
    background: #fff;
    color: #ccc;
}

.tabbable.wp-tabs-right {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 30px;
}

div.tabbable.wp-tabs-right div.tab-content {
    -moz-border-radius: 0 0 2px 2px;
    -moz-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 0 0 2px 2px;
    border-left: 0;
    margin: 0;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.04);
    padding: 15px;
}

div.tabbable.wp-tabs-right ul.nav-tabs a {
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    background: #f7f7f7;
    border: 1px solid #eee;
    border-left: 0;
    border-radius: 2px 2px 0 0;
    color: #666;
    margin-bottom: 3px;
}

div.tabbable.wp-tabs-right ul.nav-tabs li.active a {
    background: #fff;
    color: #ccc;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #e0eded;
    border-right: 0;
}

.wp-tabs-centered {
    width: 100%;
    display: table;
    margin: 0 auto;
}

.wp-tabs-centered ul li {
    width: 100px;
    display: inline-block;
    float: none;
}

.tabs-framed {
    border: 1px solid #e0eded;
}

.tabs.bookmarks2 li {
    max-width: 48%
}

.tabs.bookmarks3 li {
    max-width: 32%
}

.tabs.bookmarks4 li {
    max-width: 24%
}

.tabs.bookmarks5 li {
    max-width: 18%
}

.tabs.bookmarks6 li {
    max-width: 16%
}

.tabs {
    border-bottom: 1px solid #e0eded;
}

.tabs li {
    float: left;
    border-right: 1px solid #e0eded;
    margin-bottom: -1px;
}

.tabs li:first-child {
    padding-left: 1px;
}

.tabs li.active {
    background: #fcfcfc;
}

.tabs li a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding: 12px 25px;
    margin: 0 -1px;
    border-bottom: 3px solid transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tabs li.active a {
    color: #333;
    border-bottom: 3px solid #8ec449;
}

.tab-image {
    margin: 0 0 12px 0;
    -webkit-box-shadow: 2px 2px 13px rgba(60, 155, 213, 0.16);
    box-shadow: 2px 2px 13px rgba(60, 155, 213, 0.16);
}

.tab-image img {
    display: block;
    width: 100%
}

.tab-image.pull-left, .tab-image.pull-right {
    width: 45%
}

.col-sm-12 .tab-image.pull-left, .col-sm-12 .tab-image.pull-right {
    width: 33%
}

.tab-image.pull-left {
    margin: 5px 20px 5px 0;
}

.tab-image.pull-right {
    margin: 5px 0 5px 20px;
}

@media (max-width: 991px) {
    .tabs li a {
        font-size: 18px;
        padding: 15px;
    }
}

@media (max-width: 479px) {
    .tabs li a {
        font-size: 14px;
        padding: 12px;
    }

    .tab-image.pull-left, .tab-image.pull-right {
        width: 100%;
        margin: 0;
        margin-bottom: 16px;
        float: none !important;
    }
}

.nav-pills {
    margin-bottom: 15px;
    border: 1px solid #eee;
}

.nav-pills > li > a {
    border-radius: 0;
    border-right: 1px solid #e0eded;
}

.nav-pills > li:last-child > a {
    border-radius: 0;
    border-right: 0;
}

.nav-pills > li > a:hover, .nav-pills > li > a:focus {
    background: #f5f5f5;
}

.nav-pills > li + li {
    margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #8ec449;
    background: none;
}

.panel {
    border-radius: 0;
    background-color: #fff;
}

.panel.panel-base {
    background-color: #8ec449;
}

.panel .panel-heading {
    border-radius: 0;
    padding: 15px;
}

.panel-default {
    border-color: #e0eded;
}

.panel-default > .panel-heading {
    border-color: #e0eded;
    background: #fcfcfc;
}

.panel-default > .panel-heading h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #333;
}

.panel-dark {
    background: #131313;
    border-color: transparent;
    color: #fff;
}

.panel-dark > .panel-heading {
    border-color: #0b0b0b;
    background: #131313;
    color: #fff;
}

.panel-dark .bb {
    border-color: #0b0b0b;
}

.panel-light {
    background: #ecf0f1;
    border-color: transparent;
    color: #2c3e50;
}

.panel-light > .panel-heading {
    border-color: #e0eded;
    background: #ecf0f1;
    color: #2c3e50;
}

.panel-light .bb {
    border-color: #e3e9eb;
}

.panel {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.panel-group {
    margin-bottom: 30px;
}

.panel-group .panel {
    border-radius: 0;
    border-color: #dadada;
}

.panel-group .panel + .panel {
    margin-top: 0;
    border-top: 0;
}

.panel-group .panel-heading {
    padding: 14px 15px;
    position: relative;
    background: #f3f3f3;
}

.panel-group .panel-heading:after {
    content: "+";
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -8px;
    color: #333;
}

.panel-group .panel-heading:after {
    content: "+";
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -8px;
    color: #333;
}

.panel-group .panel-heading a {
    font-weight: normal;
    color: #333;
}

.panel-group .panel-heading a i {
    margin-right: 5px;
    color: #333;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-color: #dadada;
    color: #616161;
}

.modal-footer {
    margin-top: 0;
}

.alert {
    border-radius: 0;
}

.table > thead > tr > th {
    border-bottom: 1px solid #ddd;
}

.table > thead > tr {
    background: #f3f3f3;
}

.label {
    border-radius: 0;
}

.pagination-style-2 > li > a {
    margin-right: 4px;
    border-color: #e0eded;
    border-radius: 2px;
}

.pagination > li:first-child > a {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.pagination > li:last-child > a {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.pagination > li > a, .pagination > li > span {
    background-color: #fff;
    border-color: #e0eded;
    color: #8ec449;
}

.pagination > li > a:hover, .pagination > li > span:hover {
    background-color: #8ec449;
    border-color: #e0eded;
    color: #fff;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #8ec449;
    border-color: #8ec449;
    color: #fff;
}

.well {
    border-radius: 2px;
    background: #fcfcfc;
    border-color: #e0eded;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.well.base {
    background: #8ec449;
    border-color: transparent;
}

.well.base-alt {
    background: #2c2c2c;
    border-color: transparent;
}

.well.light {
    background: #ecf0f1;
    border-color: transparent;
}

.well.dark {
    background: #131313;
    border-color: transparent;
}

.well h1, .well h2, .well h3, .well h4, .well h5, .well h6 {
    margin: 0;
    padding: 0;
    text-transform: none;
    font-weight: 500;
}

.well p {
    margin: 0;
}

.dl-horizontal.style-2 dt {
    text-align: left;
    font-size: 13px;
}

.btn {
    font-weight: normal;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.btn:hover, .btn:focus {
    -webkit-transition: background-position 0s linear;
    -moz-transition: background-position 0s linear;
    -o-transition: background-position 0s linear;
    transition: background-position 0s linear;
}

.btn i {
    margin-right: 4px;
}

.btn-lg {
    font-size: 18px;
    line-height: 1.33;
    border-radius: 2px;
}

.btn-lg i {
    font-size: 24px;
    position: relative;
    top: 3px;
}

.btn-xs {
    border-radius: 1px;
}

.btn-xl {
    padding: 16px 20px;
    font-size: 20px;
}

.btn-square {
    border-radius: 0 !important;
}

.btn-a-one {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: #fff;
    border-radius: 0;
}

.btn-a-one:hover, .btn-a-one:focus, .btn-a-one:active, .btn-a-one.active, .open .dropdown-toggle.btn-a-one {
    color: #8ec449;
    background-color: #fff;
    border: 0;
}

.btn-a-one:active, .btn-a-one.active, .open .dropdown-toggle.btn-a-one {
    background-image: none;
}

.input-group-btn .btn-a-one {
    border-left: 1px solid #fff;
}

.btn-b-white {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff !important;
}

.btn-b-white:hover, .btn-b-white:focus, .btn-b-white:active, .btn-b-white.active, .open .dropdown-toggle.btn-b-white {
    color: #8ec449 !important;
    background-color: #fff;
    border-color: #fff;
}

.btn-b-white:active, .btn-b-white.active, .open .dropdown-toggle.btn-b-white {
    background-image: none;
}

.btn-b-white:hover:before {
    color: #8ec449 !important;
}

.btn-b-white.btn-icon:before {
    border-right: 2px solid #fff;
}

.btn-b-white.btn-icon-right:before {
    border-right: 0;
    border-left: 2px solid #fff;
}

.btn-b-base {
    background-color: transparent;
    border: 2px solid #8ec449;
    color: #333; /*#8ec449;*/
}

.btn-b-base:hover, .btn-b-base:focus, .btn-b-base:active, .btn-b-base.active, .open .dropdown-toggle.btn-b-base {
    color: #fff;
    background-color: #8ec449;
    border-color: #8ec449;
}

.btn-b-base:active, .btn-b-base.active, .open .dropdown-toggle.btn-b-base {
    background-image: none;
}

.btn-b-base.btn-icon:before {
    border-right: 2px solid #8ec449;
}

.btn-b-base.btn-icon-right:before {
    border-right: 0;
    border-left: 2px solid #8ec449;
}

.btn-b-alt {
    background-color: transparent;
    border: 2px solid #2c2c2c;
    color: #2c2c2c;
}

.btn-b-alt:hover, .btn-b-alt:focus, .btn-b-alt:active, .btn-b-alt.active, .open .dropdown-toggle.btn-b-alt {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c;
}

.btn-b-alt:active, .btn-b-alt.active, .open .dropdown-toggle.btn-b-alt {
    background-image: none;
}

.btn-b-alt.btn-icon:before {
    border-right: 2px solid #2c2c2c;
}

.btn-b-alt.btn-icon-right:before {
    border-right: 0;
    border-left: 2px solid #2c2c2c;
}

.btn-b-light {
    background-color: transparent;
    border: 2px solid #ecf0f1;
    color: #ecf0f1;
}

.btn-b-light:hover, .btn-b-light:focus, .btn-b-light:active, .btn-b-light.active, .open .dropdown-toggle.btn-b-light {
    color: #333;
    background-color: #ecf0f1;
    border-color: #ecf0f1;
}

.btn-b-light:active, .btn-b-light.active, .open .dropdown-toggle.btn-b-light {
    background-image: none;
}

.btn-b-light.btn-icon:before {
    border-right: 2px solid #ecf0f1;
}

.btn-b-light.btn-icon-right:before {
    border-right: 0;
    border-left: 2px solid #ecf0f1;
}

.btn-b-dark {
    background-color: transparent;
    border: 2px solid #131313;
    color: #131313;
}

.btn-b-dark:hover, .btn-b-dark:focus, .btn-b-dark:active, .btn-b-dark.active, .open .dropdown-toggle.btn-b-dark {
    color: #fff;
    background-color: #131313;
    border-color: #131313;
}

.btn-b-dark:active, .btn-b-dark.active, .open .dropdown-toggle.btn-b-dark {
    background-image: none;
}

.btn-b-dark.btn-icon:before {
    border-right: 2px solid #131313;
}

.btn-b-dark.btn-icon-right:before {
    border-right: 0;
    border-left: 2px solid #131313;
}

.btn-base {
    color: #fff !important;
    background-color: #8ec449;
    border: 1px solid;
    border-color: #81b83c;
}

.btn-base:hover, .btn-base:focus, .btn-base:active, .btn-base.active, .open .dropdown-toggle.btn-base {
    color: #fff;
    background-color: #9aca5c;
    border-color: #9aca5c;
}

.btn-base:active, .btn-base.active, .open .dropdown-toggle.btn-base {
    background-image: none;
}

.btn-alt {
    color: #fff;
    background-color: #2c2c2c;
    border: 1px solid;
    border-color: #1f1f1f;
}

.btn-alt:hover, .btn-alt:focus, .btn-alt:active, .btn-alt.active, .open .dropdown-toggle.btn-alt {
    color: #fff;
    background-color: #464646;
    border-color: #464646;
}

.btn-alt:active, .btn-alt.active, .open .dropdown-toggle.btn-alt {
    background-image: none;
}

.btn-dark {
    color: #fff;
    background-color: #131313;
    border: 1px solid;
    border-color: #060606;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .open .dropdown-toggle.btn-dark {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d;
}

.btn-dark:active, .btn-dark.active, .open .dropdown-toggle.btn-dark {
    background-image: none;
}

.btn-light {
    color: #333;
    background-color: #ecf0f1;
    border: 1px solid;
    border-color: #dde4e6;
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .open .dropdown-toggle.btn-light {
    color: #333;
    background-color: #fff;
    border-color: #fff;
}

.btn-light:active, .btn-light.active, .open .dropdown-toggle.btn-light {
    background-image: none;
}

.btn-white {
    color: #333;
    background-color: #fff;
    border: 1px solid;
    border-color: #f2f2f2;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
    color: #333;
    background-color: #fff;
    border-color: #f2f2f2;
}

.btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
    background-image: none;
}

.btn-icon {
    position: relative;
}

.btn-icon span, .btn-icon input {
    padding-left: 40px;
}

.btn-icon-right span, .btn-icon-right input {
    padding-left: 15px;
    padding-right: 50px;
}

.btn-icon.btn-xl span, .btn-icon.btn-xl input {
    padding-left: 60px;
}

.btn-icon-right.btn-xl span, .btn-icon-right.btn-xl input {
    padding-left: 30px;
    padding-right: 70px;
}

.btn-icon.btn-lg span, .btn-icon.btn-lg input {
    padding-left: 50px;
}

.btn-icon-right.btn-lg span, .btn-icon-right.btn-lg input {
    padding-left: 15px;
    padding-right: 60px;
}

.btn-icon.btn-sm span, .btn-icon.btn-sm input {
    padding-left: 35px;
}

.btn-icon-right.btn-sm span, .btn-icon-right.btn-sm input {
    padding-left: 15px;
    padding-right: 45px;
}

.btn-icon.btn-xs span, .btn-icon.btn-xs input {
    padding-left: 30px;
}

.btn-icon-right.btn-xs span, .btn-icon-right.btn-xs input {
    padding-left: 15px;
    padding-right: 40px;
}

.btn-icon:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 36px;
    color: #fff;
    border-right: 0;
    -webkit-transition: background .1s linear;
    -moz-transition: background .1s linear;
    -ms-transition: background .1s linear;
    transition: background .1s linear;
}

.btn-icon.btn-icon-right:before {
    left: auto;
    right: 0;
}

.btn-icon:before {
    font-family: "FontAwesome";
    line-height: 32px;
}

.btn-icon.btn-xl:before {
    width: 66px;
    line-height: 62px;
}

.btn-icon.btn-lg:before {
    width: 50px;
    line-height: 46px;
}

.btn-icon.btn-sm:before {
    width: 32px;
    line-height: 28px;
}

.btn-icon.btn-xs:before {
    width: 28px;
    line-height: 22px;
}

.btn-icon.btn:before {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.btn-icon.btn-xl:before {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.btn-icon.btn-lg:before {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.btn-icon.btn-xs:before {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
}

.btn-base:before {
    background-color: #74a535;
}

.btn-alt:before {
    background-color: #121212;
}

.btn-dark:before {
    background-color: #000;
}

.btn-light:before {
    background-color: #cfd9db;
    color: #333;
}

.btn-white:before {
    background-color: #e6e6e6;
    color: #333;
}

.btn-icon-go:before {
    content: "\f054"
}

.btn-envelope:before {
    content: "\f003"
}

.btn-cart:before {
    content: "\f07a"
}

.btn-unlock:before {
    content: "\f09c"
}

.btn-check:before {
    content: "\f00c"
}

.btn-search:before {
    content: "\f002"
}

.btn-comment:before {
    content: "\f075"
}

.btn-book:before {
    content: "\f02d"
}

.btn-fly:before {
    content: "\f1d8"
}

.btn-heart:before {
    content: "\f004"
}

.btn-send:before {
    content: "\f1d8"
}

.btn-sign-in:before {
    content: "\f090"
}

.btn-refresh:before {
    content: "\f021"
}

.btn-block-bm {
    display: block;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.btn-full-width {
    width: 100%;
}

.dl-menuwrapper {
    width: 100%;
    max-width: 300px;
    float: left;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%
}

.dl-menuwrapper:first-child {
    margin-right: 100px;
}

.dl-menuwrapper ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.dl-menuwrapper li {
    position: relative;
}

.dl-menuwrapper li a {
    display: block;
    position: relative;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    outline: none;
    border-bottom: 1px solid #333;
}

.no-touch .dl-menuwrapper li a:hover {
    background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
    padding-left: 30px;
    background: rgba(0, 0, 0, 0.1);
}

.dl-menuwrapper li.dl-back:after, .dl-menuwrapper li > a:not(:only-child):after {
    position: absolute;
    top: 0;
    line-height: 43px;
    font-family: 'FontAwesome';
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "\f105";
    color: #fff;
}

.dl-menuwrapper li.dl-back:after {
    left: 10px;
    color: rgba(212, 204, 198, 0.3);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
    right: 10px;
    color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
    margin: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
    transition: all .3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.dl-menuwrapper li .dl-submenu {
    display: none;
}

.dl-menu.dl-subview li, .dl-menu.dl-subview li.dl-subviewopen > a, .dl-menu.dl-subview li.dl-subview > a {
    display: none;
}

.dl-menu.dl-subview li.dl-subview, .dl-menu.dl-subview li.dl-subview .dl-submenu, .dl-menu.dl-subview li.dl-subviewopen, .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu, .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
}

.dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
}

.dl-menu.dl-animate-out-1 {
    -webkit-animation: MenuAnimOut1 .4s;
    animation: MenuAnimOut1 .4s;
}

.dl-menu.dl-animate-out-2 {
    -webkit-animation: MenuAnimOut2 .3s ease-in-out;
    animation: MenuAnimOut2 .3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
    -webkit-animation: MenuAnimOut3 .4s ease;
    animation: MenuAnimOut3 .4s ease;
}

.dl-menu.dl-animate-out-4 {
    -webkit-animation: MenuAnimOut4 .4s ease;
    animation: MenuAnimOut4 .4s ease;
}

.dl-menu.dl-animate-out-5 {
    -webkit-animation: MenuAnimOut5 .4s ease;
    animation: MenuAnimOut5 .4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
    50% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -webkit-transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut2 {
    100% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut3 {
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut4 {
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut5 {
    100% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut1 {
    50% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -webkit-transform: translateZ(-372.5px) rotateY(15deg);
        transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@keyframes MenuAnimOut2 {
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut3 {
    100% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut4 {
    100% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut5 {
    100% {
        -webkit-transform: translateY(40%);
        transform: translateY(40%);
        opacity: 0;
    }
}

.dl-menu.dl-animate-in-1 {
    -webkit-animation: MenuAnimIn1 .3s;
    animation: MenuAnimIn1 .3s;
}

.dl-menu.dl-animate-in-2 {
    -webkit-animation: MenuAnimIn2 .3s ease-in-out;
    animation: MenuAnimIn2 .3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
    -webkit-animation: MenuAnimIn3 .4s ease;
    animation: MenuAnimIn3 .4s ease;
}

.dl-menu.dl-animate-in-4 {
    -webkit-animation: MenuAnimIn4 .4s ease;
    animation: MenuAnimIn4 .4s ease;
}

.dl-menu.dl-animate-in-5 {
    -webkit-animation: MenuAnimIn5 .4s ease;
    animation: MenuAnimIn5 .4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
    0% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn5 {
    0% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn1 {
    0% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        transform: translateZ(-250px) rotateY(30deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0deg);
        transform: translateZ(0) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn5 {
    0% {
        -webkit-transform: translateY(40%);
        transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
    -webkit-animation: SubMenuAnimIn1 .4s ease;
    animation: SubMenuAnimIn1 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
    -webkit-animation: SubMenuAnimIn2 .3s ease-in-out;
    animation: SubMenuAnimIn2 .3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
    -webkit-animation: SubMenuAnimIn3 .4s ease;
    animation: SubMenuAnimIn3 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
    -webkit-animation: SubMenuAnimIn4 .4s ease;
    animation: SubMenuAnimIn4 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
    -webkit-animation: SubMenuAnimIn5 .4s ease;
    animation: SubMenuAnimIn5 .4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
    0% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn5 {
    0% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn1 {
    0% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn5 {
    0% {
        -webkit-transform: translateZ(-200px);
        transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
    -webkit-animation: SubMenuAnimOut1 .4s ease;
    animation: SubMenuAnimOut1 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
    -webkit-animation: SubMenuAnimOut2 .3s ease-in-out;
    animation: SubMenuAnimOut2 .3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
    -webkit-animation: SubMenuAnimOut3 .4s ease;
    animation: SubMenuAnimOut3 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
    -webkit-animation: SubMenuAnimOut4 .4s ease;
    animation: SubMenuAnimOut4 .4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
    -webkit-animation: SubMenuAnimOut5 .4s ease;
    animation: SubMenuAnimOut5 .4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
    0% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut3 {
    0% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut4 {
    0% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut5 {
    0% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut1 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut3 {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut4 {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut5 {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-200px);
        transform: translateZ(-200px);
        opacity: 0;
    }
}

.no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
    display: block;
}

.no-js .dl-menuwrapper li.dl-back {
    display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
    content: ''
}

.cusel, #cuselBox .cusel-scroll-wrap, #cuselBox .jScrollPaneContainer, #cuselBox .cusel-scroll-pane {
    width: 100% !important;
}

.cusel {
    height: 44px;
    display: inline-block;
    position: relative;
    z-index: 2;
    padding: 0 30px 0 12px;
}

.cuselOpen {
    z-index: 9999;
}

.cuselText label::selection {
    background-color: transparent;
}

.cuselFrameRight {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 32px;
    cursor: pointer;
}

.cuselFrameRight:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #d0d1d3;
    position: absolute;
    top: 19px;
    right: 14px;
}

.cusel:hover .cuselFrameRight:before, .cuselOpen .cuselFrameRight:before, .cuselFocus .cuselFrameRight:before {
    border-top-color: #a0a0a0;
}

.cuselText {
    cursor: pointer;
}

.cuselText label {
    width: 100%;
    height: 100%;
    line-height: 48px;
    font-size: 14px;
    color: #70808b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#cuselBox {
    position: absolute;
    z-index: 9999;
}

#cuselBox .jScrollPaneContainer {
    position: relative;
    overflow: hidden;
    border: 1px solid #d0d1d3;
    border-top: none;
    background: #ebf1f8;
    border-radius: 2px;
}

#cuselBox .cusel-scroll-pane {
    padding-right: 0 !important;
}

#cuselBox span {
    display: block;
    cursor: pointer;
}

#cuselBox span label {
    display: block;
    padding: 10px 12px;
    position: relative;
    border-top: 1px solid #d0d1d3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#cuselBox span:first-child label {
    border-top: none;
}

#cuselBox span:hover, #cuselBox span.cuselOptHover, #cuselBox span.cuselActive {
    color: #c9376e;
    background: #dfe3e9;
}

#cuselBox .jScrollPaneTrack {
    height: 100%;
    width: 4px !important;
    background: #a0a0a0;
    background: rgba(160, 160, 160, 0.36);
    position: absolute;
    top: 0;
    right: 4px;
}

#cuselBox .jScrollPaneDrag {
    position: absolute;
    cursor: pointer;
    width: 6px !important;
    height: 26px !important;
    right: -1px;
    background: #a0a0a0;
}

.jScrollArrowUp, .jScrollArrowDown {
    width: 6px !important;
    height: 6px;
    position: absolute;
    top: 0;
    right: 3px;
    cursor: pointer;
}

.jScrollArrowDown {
    top: auto;
    bottom: 0;
}

.jScrollArrowUp:before, .jScrollArrowDown:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 6px solid #a0a0a0;
}

.jScrollArrowDown:before {
    border-top: 6px solid #a0a0a0;
    border-bottom: none;
}

.form-base .cusel {
    height: 46px;
    padding: 0 40px 0 12px;
}

.form-base .cuselText {
    padding-left: 15px;
}

.form-base .cuselText label {
    line-height: 46px;
    font-weight: 500;
    color: #fff;
}

.form-base .cuselFrameRight {
    width: 42px;
    background: #74a535;
    border-left: 1px solid #66912f;
    border-radius: 0 2px 2px 0;
}

.form-base .cuselFrameRight:before {
    border-top: 9px solid #fff !important;
    top: 16px;
    right: 14px;
}

.form-base .base.form-control {
    padding: 0;
}

#cuselBox.base .jScrollPaneContainer {
    border: 1px solid #66912f;
    border-top: none;
    background: #74a535 !important;
}

#cuselBox.base span label {
    padding: 10px 15px;
    border-top: 1px solid #66912f;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}

#cuselBox.base span:first-child label {
    border-top: none;
}

#cuselBox.base span:hover, #cuselBox.base span.cuselOptHover, #cuselBox.base span.cuselActive {
    color: #fff;
    background: #1b538c;
}

#cuselBox.base .jScrollPaneTrack {
    background: #083080;
    background: rgba(8, 48, 128, 0.36);
}

#cuselBox.base .jScrollPaneDrag {
    background: #083080;
}

#cuselBox.base .jScrollArrowUp:before {
    border-bottom-color: #083080;
}

#cuselBox.base .jScrollArrowDown:before {
    border-top-color: #083080;
}

.vjs-default-skin {
    color: #ccc;
}

@font-face {
    ont-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
}

.vjs-time-divider {
    float: left;
    line-height: 3em;
}

.vjs-big-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: 76px;
    height: 76px;
    margin-top: -38px;
    margin-left: -38px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.vjs-big-play-button:hover {
    background: #fff;
}

.vjs-big-play-button:before {
    content: "\f04b";
    font-family: "FontAwesome";
    font-size: 40px;
    color: #8ec449;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -11px;
}

.vjs-styled-skin.base .vjs-big-play-button:before {
    color: #8ec449;
}

.vjs-styled-skin.base-alt .vjs-big-play-button:before {
    color: #2c2c2c;
}

.vjs-styled-skin.light .vjs-big-play-button:before {
    color: #131313;
}

.vjs-styled-skin.dark .vjs-big-play-button:before {
    color: #131313;
}

.vjs-controls-disabled .vjs-big-play-button {
    display: none;
}

.vjs-has-started .vjs-big-play-button {
    display: none;
}

.vjs-using-native-controls .vjs-big-play-button {
    display: none;
}

.vjs-loading-spinner {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 4em;
    line-height: 1;
    width: 1em;
    height: 1em;
    margin-left: -0.5em;
    margin-top: -0.5em;
    opacity: .75;
    -webkit-animation: spin 1.5s infinite linear;
    -moz-animation: spin 1.5s infinite linear;
    -o-animation: spin 1.5s infinite linear;
    animation: spin 1.5s infinite linear;
}

.vjs-default-skin .vjs-loading-spinner:before {
    content: "\e01e";
    font-family: VideoJS;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    text-shadow: 0 0 .1em #000;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(359deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.video-js {
    background-color: #000;
    position: relative;
    padding: 0;
    font-size: 10px;
    vertical-align: middle;
    font-weight: normal;
    font-style: normal;
    font-family: Arial, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.video-js .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.video-js:-moz-full-screen {
    position: absolute;
}

body.vjs-full-window {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-y: auto;
}

.video-js.vjs-fullscreen {
    position: fixed;
    overflow: hidden;
    z-index: 1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    _position: absolute;
}

.video-js:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
}

.video-js.vjs-fullscreen.vjs-user-inactive {
    cursor: none;
}

.vjs-poster {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%
}

.vjs-poster img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    padding: 0;
    width: 100%
}

.video-js.vjs-using-native-controls .vjs-poster {
    display: none;
}

.video-js .vjs-text-track-display {
    text-align: center;
    position: absolute;
    bottom: 4em;
    left: 1em;
    right: 1em;
}

.video-js .vjs-text-track {
    display: none;
    font-size: 1.4em;
    text-align: center;
    margin-bottom: .1em;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
}

.video-js .vjs-subtitles {
    color: #fff;
}

.video-js .vjs-captions {
    color: #fc6;
}

.vjs-tt-cue {
    display: block;
}

.vjs-default-skin .vjs-hidden {
    display: none;
}

.vjs-lock-showing {
    display: block !important;
    opacity: 1;
    visibility: visible;
}

.vjs-styled-skin {
    color: #f9ffe8;
}

.vjs-styled-skin .vjs-control-bar {
    display: block;
    visibility: visible;
    opacity: 1;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    height: 50px;
    background: #8ec449;
}

.vjs-styled-skin.base .vjs-control-bar {
    background: #8ec449;
}

.vjs-styled-skin.base-alt .vjs-control-bar {
    background: #2c2c2c;
}

.vjs-styled-skin.light .vjs-control-bar {
    background: #ecf0f1;
}

.vjs-styled-skin.dark .vjs-control-bar {
    background: #131313;
}

.vjs-styled-skin.shadowed .vjs-control-bar {
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.vjs-styled-skin.vjs-fullscreen .vjs-control-bar {
    bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.vjs-styled-skin.vjs-has-started .vjs-control-bar, .vjs-styled-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    display: block;
    visibility: visible;
    opacity: 1;
}

.vjs-styled-skin .vjs-control {
    outline: none;
    position: relative;
    float: left;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 5em;
}

.vjs-styled-skin .vjs-control:before {
    font-family: "FontAwesome";
    font-size: 2em;
    line-height: 2.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.vjs-styled-skin .vjs-control:focus:before, .vjs-styled-skin .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff;
}

.vjs-styled-skin .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.vjs-styled-skin .vjs-play-control {
    cursor: pointer;
    background: #a6d070;
}

.vjs-styled-skin.base .vjs-play-control {
    background: #a6d070;
}

.vjs-styled-skin.base-alt .vjs-play-control {
    background: #464646;
}

.vjs-styled-skin.light .vjs-play-control {
    background: #cfd9db;
}

.vjs-styled-skin.dark .vjs-play-control {
    background: #2d2d2d;
}

.vjs-styled-skin .vjs-play-control:before {
    content: "\f04b"
}

.vjs-styled-skin.vjs-playing .vjs-play-control:before {
    content: "\f04c"
}

.vjs-styled-skin .vjs-mute-control, .vjs-styled-skin .vjs-volume-menu-button {
    cursor: pointer;
    float: right;
    background: #c00;
}

.vjs-styled-skin.base .vjs-mute-control, .vjs-styled-skin.base .vjs-volume-menu-button {
    background: #74a535;
}

.vjs-styled-skin.base-alt .vjs-mute-control, .vjs-styled-skin.base-alt .vjs-volume-menu-button {
    background: #121212;
}

.vjs-styled-skin.light .vjs-mute-control, .vjs-styled-skin.light .vjs-volume-menu-button {
    background: #cfd9db;
}

.vjs-styled-skin.dark .vjs-mute-control, .vjs-styled-skin.dark .vjs-volume-menu-button {
    background: #000;
}

.vjs-styled-skin .vjs-mute-control:before, .vjs-styled-skin .vjs-volume-menu-button:before {
    content: "\f028"
}

.vjs-styled-skin .vjs-mute-control.vjs-vol-0:before, .vjs-styled-skin .vjs-volume-menu-button.vjs-vol-0:before {
    content: "\f026"
}

.vjs-styled-skin .vjs-mute-control.vjs-vol-1:before, .vjs-styled-skin .vjs-volume-menu-button.vjs-vol-1:before {
    content: "\f027"
}

.vjs-styled-skin .vjs-mute-control.vjs-vol-2:before, .vjs-styled-skin .vjs-volume-menu-button.vjs-vol-2:before {
    content: "\f027"
}

.vjs-styled-skin .vjs-volume-control {
    float: right;
    background: #74a535;
    width: 8em;
}

.vjs-styled-skin.base .vjs-volume-control {
    background: #74a535;
}

.vjs-styled-skin.base-alt .vjs-volume-control {
    background: #121212;
}

.vjs-styled-skin.light .vjs-volume-control {
    background: #cfd9db;
}

.vjs-styled-skin.dark .vjs-volume-control {
    background: #000;
}

.vjs-styled-skin .vjs-volume-bar {
    background: #0b3068;
    height: 4px !important;
    margin-top: 22px;
    border-radius: 2px;
}

.no-volume-bar .vjs-volume-control {
    display: none !important;
}

.vjs-styled-skin .vjs-volume-level {
    background: #40aae4;
    height: 100%;
    border-radius: 2px;
}

.vjs-styled-skin .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    height: 3px;
    top: -3px;
    padding: 0;
    background: #fff;
}

.vjs-styled-skin .vjs-slider {
    outline: 0;
    position: relative;
    cursor: pointer;
    padding: 0;
    height: 100%
}

.vjs-styled-skin .vjs-slider:focus {
    -webkit-box-shadow: 0 0 2em #fff;
    box-shadow: 0 0 2em #fff;
}

.vjs-styled-skin .vjs-slider-handle {
    display: none;
}

.vjs-styled-skin .vjs-progress-holder .vjs-play-progress, .vjs-styled-skin .vjs-progress-holder .vjs-load-progress {
    position: absolute;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
}

.vjs-styled-skin .vjs-play-progress {
    background: #c9376e;
}

.vjs-styled-skin .vjs-load-progress {
    background: #ccc;
    background: rgba(201, 55, 110, 0.4);
}

.vjs-styled-skin .vjs-time-controls, .vjs-styled-skin .vjs-time-divider {
    font-size: 15px;
    font-weight: 700;
    color: #b5e059;
    line-height: 48px;
    width: auto;
    padding-left: 3px;
}

.vjs-styled-skin.base .vjs-time-controls, .vjs-styled-skin.base .vjs-time-divider {
    color: #e2f0d0;
}

.vjs-styled-skin.base-alt .vjs-time-controls, .vjs-styled-skin.base-alt .vjs-time-divider {
    color: #858585;
}

.vjs-styled-skin.light .vjs-time-controls, .vjs-styled-skin.light .vjs-time-divider {
    color: #859fa5;
}

.vjs-styled-skin.dark .vjs-time-controls, .vjs-styled-skin.dark .vjs-time-divider {
    color: #6c6c6c;
}

.vjs-styled-skin .vjs-current-time {
    color: #fff;
    padding-left: 16px;
}

.vjs-styled-skin .vjs-remaining-time {
    display: none;
}

.vjs-styled-skin .vjs-fullscreen-control {
    cursor: pointer;
    float: right;
    background: #74a535;
}

.no-fullscreen .vjs-fullscreen-control {
    display: none !important;
}

.vjs-styled-skin.base .vjs-fullscreen-control {
    background: #74a535;
}

.vjs-styled-skin.base-alt .vjs-fullscreen-control {
    background: #121212;
}

.vjs-styled-skin.light .vjs-fullscreen-control {
    background: #cfd9db;
}

.vjs-styled-skin.dark .vjs-fullscreen-control {
    background: #000;
}

.vjs-styled-skin .vjs-fullscreen-control:before {
    content: "\f065"
}

.vjs-styled-skin.vjs-fullscreen .vjs-fullscreen-control:before {
    content: "\f066"
}

.vjs-styled-skin .vjs-menu-button {
    float: right;
    cursor: pointer;
}

.vjs-styled-skin .vjs-menu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    margin-bottom: 3em;
    border-left: 2em solid transparent;
    border-right: 2em solid transparent;
    border-top: 1.55em solid #000;
    border-top-color: rgba(7, 40, 50, 0.5);
}

.vjs-styled-skin .vjs-menu-button .vjs-menu .vjs-menu-content {
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 10em;
    bottom: 1.5em;
    max-height: 15em;
    overflow: auto;
    left: -5em;
    background-color: #07141e;
    background-color: rgba(7, 20, 30, 0.7);
    -webkit-box-shadow: -0.2em -0.2em .3em rgba(255, 255, 255, 0.2);
    -moz-box-shadow: -0.2em -0.2em .3em rgba(255, 255, 255, 0.2);
    box-shadow: -0.2em -0.2em .3em rgba(255, 255, 255, 0.2);
}

.vjs-styled-skin .vjs-menu-button:hover .vjs-menu {
    display: block;
}

.vjs-styled-skin .vjs-menu-button ul li {
    list-style: none;
    margin: 0;
    padding: .3em 0 .3em 0;
    line-height: 1.4em;
    font-size: 1.2em;
    text-align: center;
    text-transform: lowercase;
}

.vjs-styled-skin .vjs-menu-button ul li.vjs-selected {
    background-color: #000;
}

.vjs-styled-skin .vjs-menu-button ul li:focus, .vjs-styled-skin .vjs-menu-button ul li:hover, .vjs-styled-skin .vjs-menu-button ul li.vjs-selected:focus, .vjs-styled-skin .vjs-menu-button ul li.vjs-selected:hover {
    outline: 0;
    color: #111;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0 0 1em #fff;
    -moz-box-shadow: 0 0 1em #fff;
    box-shadow: 0 0 1em #fff;
}

.vjs-styled-skin .vjs-menu-button ul li.vjs-menu-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 2em;
    padding: 0;
    margin: 0 0 .3em 0;
    font-weight: bold;
    cursor: default;
}

.vjs-styled-skin .vjs-subtitles-button:before {
    content: "\e00c"
}

.vjs-styled-skin .vjs-captions-button:before {
    content: "\e008"
}

.vjs-styled-skin .vjs-captions-button:focus .vjs-control-content:before, .vjs-styled-skin .vjs-captions-button:hover .vjs-control-content:before {
    -webkit-box-shadow: 0 0 1em #fff;
    -moz-box-shadow: 0 0 1em #fff;
    box-shadow: 0 0 1em #fff;
}

.video-js.vjs-styled-skin {
    background-color: #000;
    position: relative;
    padding: 0;
    font-size: 10px;
    vertical-align: middle;
    font-weight: normal;
    font-style: normal;
    font-family: Arial, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 50px;
}

.video-player .video-js.vjs-styled-skin {
    -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.video-js.vjs-styled-skin.vjs-fullscreen {
    margin-bottom: 0;
}

.video-js.vjs-styled-skin .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.vjs-styled-skin .vjs-poster {
    background-size: cover;
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%
}

@media (max-width: 479px) {
    .vjs-styled-skin .vjs-control-bar {
        bottom: -40px;
        height: 40px;
    }

    .vjs-styled-skin .vjs-control {
        width: 3em;
    }

    .vjs-styled-skin .vjs-control:before {
        font-size: 1.6em;
    }

    .vjs-styled-skin .vjs-time-controls, .vjs-styled-skin .vjs-time-divider {
        font-size: 13px;
        line-height: 40px;
    }

    .vjs-styled-skin .vjs-volume-control {
        width: 4em;
    }

    .vjs-styled-skin .vjs-volume-bar {
        margin-top: 17px;
    }

    .vjs-styled-skin .vjs-progress-control {
        width: auto;
    }
}

@media (min-width: 992px) {
    .header-cover .navbar-wp {
        width: 100%;
        position: absolute;
        top: 0px;
    }

    .header-cover .top-header {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1200;
    }

    #mobileLogo {
        display: none;
    }

    .header-alpha .top-header {
        background: rgba(255, 255, 255, 0.5);
    }

    .header-alpha .top-header .aux-text {
        color: #fff;
    }

    .header-alpha .top-header .top-header-menu > ul.top-menu > li > a {
        color: #fff;
    }

    .header-alpha .top-header .top-header-top-menu > ul.top-menu > li > a {
        color: #fff;
    }

    .header-alpha .top-header .top-header-top-menu > ul.top-menu > li > a:hover {
        color: #f00;
    }

    .header-alpha .top-header .top-header-menu ul.top-menu > li ul.sub-menu {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
    }

    .header-alpha .top-header .top-header-menu ul.top-menu > li ul.sub-menu > li > a {
        color: #fff;
    }

    .header-alpha .top-header .top-header-menu ul.top-menu > li ul.sub-menu > li > a:hover {
        color: #f00;
    }

    .header-alpha .navbar-wp {
        background: rgba(0, 0, 0, 0.3);
        border-bottom: 0;
    }

    .header-alpha .navbar-wp .navbar-inner {
        border-bottom: 0px solid #e0eded;
    }

    .header-alpha .navbar-wp .navbar-nav > li > a {
        padding: 28px 16px;
        margin-right: 0;
        font-size: 14px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        color: #fff;
        text-transform: uppercase;
    }

    .header-alpha .navbar-wp .navbar-nav > .open > a, .header-alpha .navbar-wp .navbar-nav > .open > a:hover, .header-alpha .navbar-wp .navbar-nav > .open > a:focus {
        color: #333;
    }

    .header-alpha .navbar-wp.affix .navbar-nav > li > a {
        color: #333;
    }

    .header-alpha .navbar-wp.affix .navbar-nav > li > a:hover, .header-alpha .navbar-wp.affix .navbar-nav > li > a:focus, .header-alpha .navbar-wp.affix .navbar-nav > .open > a:hover, .header-alpha .navbar-wp.affix .navbar-nav > .open > a:focus {
        color: #333;
    }

    .header-alpha .navbar-wp .navbar-nav > li > a.dropdown-form-toggle, .header-alpha .navbar-wp .navbar-nav > li > a.dropdown-form-toggle:hover, .header-alpha .navbar-wp .navbar-nav > li > a.dropdown-form-toggle:focus {
        color: #fff;
    }

    .header-alpha .navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle, .header-alpha .navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle:hover, .header-alpha .navbar-wp.affix .navbar-nav > li > a.dropdown-form-toggle:focus {
        color: #fff;
        margin-top: 0;
    }

    .header-alpha .navbar-wp .dropdown-menu {
        background: rgba(0, 0, 0, 0.3);
        border-top: 1px solid transparent;
        border-bottom: 0;
    }

    .header-alpha .navbar-wp .dropdown-menu > li {
        border-bottom: 1px solid rgba(247, 247, 247, 0);
    }

    .header-alpha .navbar-wp .dropdown-menu > li:last-child {
        border: 0;
    }

    .header-alpha .navbar-wp .dropdown-menu > li > a {
        color: #fff;
        padding: 8px 15px;
    }

    .header-alpha .navbar-wp .dropdown-menu > li > a:hover {
        background: #333;
        color: #fff;
    }

    .header-alpha .navbar-wp .dropdown-menu-search {
        background: rgba(0, 0, 0, 0.85);
        border: 1px solid #fff;
    }

    .header-alpha .navbar-wp .dropdown-menu ul.mega-nav-ul li a {
        color: #fff;
    }

    .header-alpha .navbar-wp .dropdown-menu .mega-nav-section-title {
        color: #fff;
    }

    .header-alpha .navbar-wp .dropdown-menu ul.mega-nav-ul li a:before {
        color: #fff;
    }

    .dropdown-submenu > a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 3px 0 3px 3px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -6px;
    }

    .product-list-filters {
        margin-bottom: 25px;
        background: #fcfcfc !important;
        border: 1px solid #e0eded !important;
    }

    .product-list-filters .view-mode {
        float: left;
        margin: 7px 0 0 15px;
    }

    .product-list-filters .view-mode a {
        font-size: 20px;
        margin-right: 4px;
        font-weight: normal;
    }

    .product-list-filters .filter {
        display: inline-block;
        float: left;
        margin-left: 30px;
        margin-top: 2px;
    }

    .product-list-filters .sort-filter label {
        margin: 0;
        margin-right: 10px;
        font-weight: 400;
    }

    .product-list-filters .sort-filter select {
        margin-right: 10px;
    }

    .product-list-filters .pagination {
        margin: 0;
        padding: 0;
        float: right;
        border: 0;
        border-radius: 0;
    }

    .product-list-filters .pagination li a {
        border-top: 0;
        border-bottom: 0;
        border-radius: 0;
        padding: 10px 16px;
    }

    .product-list-filters .pagination.pull-right li:last-child a {
        border-right: 0;
    }

    .product-list-filters .pagination.pull-left li:first-child a {
        border-left: 0;
    }

    .product-list-filters .pagination li.active a {
        background: #8ec449;
        color: #fff;
    }
}

@media (max-width: 991px) {
    .product-list-filters {
        margin-bottom: 25px;
        background: #fcfcfc !important;
        border: 1px solid #e0eded !important;
    }

    .product-list-filters .view-mode {
        float: left;
        margin: 7px 0 0 15px;
    }

    .product-list-filters .view-mode a {
        font-size: 20px;
        margin-right: 4px;
        font-weight: normal;
    }

    .product-list-filters .filter {
        display: block;
        padding: 0 10px;
        margin-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #e0eded;
    }

    .product-list-filters .sort-filter label {
        margin: 0;
        margin-right: 10px;
        font-weight: 400;
    }

    .product-list-filters .sort-filter select {
        margin-right: 10px;
    }

    .product-list-filters .pagination {
        margin: 0;
        padding: 0;
        float: left;
        border: 0;
        border-radius: 0;
    }

    .product-list-filters .pagination li a {
        border-top: 0;
        border-bottom: 0;
        border-radius: 0;
        padding: 10px 16px;
    }

    .product-list-filters .pagination li:first-child a {
        border-left: 0;
    }

    .product-list-filters .pagination li.active a {
        background: #8ec449;
        color: #fff;
    }

    .inline-form-filters .form-group {
        margin-bottom: 10px;
    }

    .static-page-image {
        position: relative;
        max-height: 500px;
        overflow: hidden;
    }

    .static-page-image img {
        width: 100%
    }

    .static-page-image .description-left {
        top: 50px;
        left: 20px;
        width: 70%;
        height: 290px;
    }

    .carousel-1 .description-left {
        position: absolute;
        width: 40%;
        top: 50px;
        left: 20px;
        margin-left: 20px;
        width: 514px;
        height: 290px;
    }
}

@media (min-width: 1200px) {
    .top-header .aux-text {
        display: inline-block !important;
        float: left;
    }

    .aside-feature {
        text-align: left;
    }

    .aside-feature .icon-feature {
        text-align: left;
    }

    .slider {
        width: 100%
    }

    .navbar-default .dropdown-menu, .navbar-wp .dropdown-menu {
        margin-top: 0 !important;
    }

    .navbar-wp.navbar-arrow .navbar-nav > li.open > a:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1100;
    }

    .navbar-wp.navbar-arrow .navbar-nav > li.open > a:before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: #e0eded;
        border-width: 11px;
        margin-left: -11px;
        z-index: 1100;
    }

    .navbar-wp.navbar-arrow .navbar-nav > li.dropdown-aux.open > a:after, .navbar-wp.navbar-arrow .navbar-nav > li.dropdown-aux.open > a:before {
        border-bottom-color: transparent;
        border-width: 0;
    }

    .navbar-wp.navbar-arrow .dropdown-aux .dropdown-menu-search:after, .navbar-wp.navbar-arrow .dropdown-aux .dropdown-menu-search:before {
        border-bottom-color: transparent;
        border-width: 0;
    }

    .body-wrap.body-boxed {
        width: 1230px;
        margin: 20px auto;
        /*border: 1px solid #333;*/
        box-shadow: 0px 0px 40px #000;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .top-header .aux-text {
        display: inline-block !important;
    }

    .navbar-default .dropdown-menu, .navbar-wp .dropdown-menu {
        margin-top: 0 !important;
    }

    .navbar-default .dropdown-menu, .navbar-wp .dropdown-menu.dropdown-menu-user {
        margin-top: 13px !important;
    }

    .navbar-wp.navbar-arrow .navbar-nav > li.open > a:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1100;
    }

    .navbar-wp.navbar-arrow .navbar-nav > li.dropdown-search.open > a:after {
        border-bottom-color: transparent;
        border-width: 0;
    }

    .navbar-wp.navbar-arrow .dropdown-search .dropdown-menu-search:after {
        content: "";
        position: absolute;
        top: -20px;
        right: 15px;
        border: solid transparent;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: #fff;
        border-width: 10px;
        z-index: 1100;
    }

    .body-wrap.body-boxed {
        width: 1000px;
        margin: auto;
    }

    .wp-block, .carousel-work .figure {
        margin-bottom: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .top-header .aux-text {
        display: inline-block !important;
    }

    #desktopLogo {
        display: none;
    }

    .wp-block, .carousel-work .figure {
        margin-bottom: 15px !important;
    }

    .animate-hover-slide img {
        width: 100%
    }

    .carousel-1 .object {
        width: 400px !important;
    }

    .slider {
        width: 100%
    }

    .work.work-no-space.g2 .mix {
        width: 50%
    }

    .work.work-no-space.g3 .mix {
        width: 50%
    }

    .work.work-no-space.g4 .mix {
        width: 50%
    }

    .work.work-no-space.g4-all .mix {
        width: 25%
    }

    .work.work-no-space.g5 .mix {
        width: 50%
    }

    .inline-form-filters.over-top-element {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .text-sm-center {
        text-align: center;
    }

    .wp-block, .carousel-work .figure {
        margin-bottom: 15px !important;
    }

    .wp-block.no-space {
        margin-bottom: 0 !important;
    }

    .animate-hover-slide .figure img {
        width: 100%
    }

    .wp-block.property.list .wp-block-body .wp-block-img {
        display: block;
        width: auto;
    }

    .wp-block.property.list .wp-block-body .wp-block-content {
        display: block;
        padding-top: 15px;
        padding-left: 0;
    }

    .wp-block.property.list .wp-block-footer ul.aux-info {
        width: 100%;
        display: table;
    }

    .wp-block.property.list .wp-block-footer ul.aux-info li {
        padding: 10px;
        font-size: 11px;
        text-align: center;
    }

    .wp-block.property.list .wp-block-footer ul.aux-info li:last-child {
        border: 0;
    }

    .wp-block.property.list .wp-block-footer ul.aux-info li i {
        display: block;
        font-size: 18px;
        margin-right: 0;
        color: #333;
    }

    .wp-block.no-space.arrow-right:after {
        margin-top: 0 !important;
        top: 100%;
        z-index: 2000;
        left: 50%;
        margin-left: -20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 20px;
        margin-top: -20px;
    }

    .wp-block.no-space.arrow-right.white:after {
        border-left-color: transparent;
        border-bottom-color: #fff;
    }

    .wp-block.no-space.arrow-right.base:after {
        border-left-color: transparent;
        border-top-color: #8ec449;
    }

    .wp-block.no-space.arrow-right.light:after {
        border-left-color: transparent;
        border-top-color: #ecf0f1;
    }

    .wp-block.no-space.arrow-right.dark:after {
        border-left-color: transparent;
        border-top-color: #131313;
    }

    .wp-block.no-space.arrow-right.base-alt:after {
        border-left-color: transparent;
        border-top-color: #2c2c2c;
    }

    .wp-block.article.list .article-image {
        display: block;
        width: 100%;
        position: relative;
    }

    .wp-block.article.list .wp-block-body {
        display: block;
        padding: 10px 0 0 0;
    }

    .carousel-1 .carousel-inner {
        height: auto;
    }

    .carousel-1 .carousel-inner {
        overflow: hidden;
    }

    .carousel-1 .carousel-control i {
        position: absolute;
        top: 50%;
        margin-top: -18px;
        font-size: 36px;
        font-weight: 600;
    }

    .carousel-1 .item-dark {
        color: #fff;
    }

    .carousel-1 p {
        font-size: 16px;
    }

    .carousel-1 .object {
        display: none;
    }

    .carousel-1 .object.fluid {
        width: 100%;
        left: 0;
        margin: 0;
    }

    .carousel-1 .object iframe {
        width: 100% !important;
    }

    .carousel-1 .description {
        width: 100% !important;
        top: 50px;
        left: 0 !important;
        margin: 0 !important;
    }

    .carousel-1 .description .title {
        font-size: 32px;
        margin: 0 0 15px 0;
        padding: 8px 20px;
        background: #fff;
        color: #9ab2cc;
        display: block;
        text-align: center;
    }

    .carousel-1 .description .subtitle {
        font-size: 24px;
        margin: 20px 0;
        padding: 0 15px !important;
        display: block;
        text-align: center;
    }

    .carousel-1 .description p {
        font-size: 16px;
        color: #fff;
        margin: 0;
    }

    .carousel-1 .description.fluid-center .features i {
        width: 80px;
        height: 80px;
        background: #fff;
        text-align: center;
        line-height: 80px;
        font-size: 34px;
        color: #697e93;
        font-weight: 700;
        border-radius: 80px;
        margin-right: 20px;
    }

    .carousel-1 .list-carousel {
        padding-left: 30px !important;
    }

    .carousel-3 .figure {
        margin-bottom: 20px;
    }

    .slider {
        height: auto;
        max-height: 440px;
        margin: 0;
    }

    .cta-wr {
        text-align: center;
    }

    .cta-wr .btn {
        float: none !important;
    }

    .client {
        margin-bottom: 20px;
    }

    .work.work-no-space.g2 .mix {
        width: 100%
    }

    .work.work-no-space.g2-all .mix {
        width: 50%
    }

    .work.work-no-space.g3 .mix {
        width: 100%
    }

    .work.work-no-space.g3-all .mix {
        width: 33.3%
    }

    .work.work-no-space.g4 .mix {
        width: 100%
    }

    .work.work-no-space.g4-all .mix {
        width: 50%
    }

    .work.work-no-space.g5 .mix {
        width: 100%
    }

    .work.work-no-space.g5-all .mix {
        width: 50%
    }

    .inline-form-filters.over-top-element {
        margin-top: 0;
    }

    .promo {
        margin-bottom: 20px;
    }

    ul.list-listings-2 > li {
        margin-bottom: 30px;
        border: 1px solid #e0eded;
        border-radius: 2px;
    }

    ul.list-listings-2 .listing-header {
        clear: both;
        padding: 8px 15px;
        font-weight: 600;
        text-transform: uppercase;
    }

    ul.list-listings-2 .listing-image {
        width: 100%;
        display: block;
        float: none;
        border-right: 0;
    }

    ul.list-listings-2 .listing-image img {
        border-bottom-left-radius: 0;
    }

    ul.list-listings-2 .listing-body {
        width: 100%;
        padding: 15px;
        display: block;
        background: #fcfcfc;
    }

    ul.list-listings-2 .listing-body h3 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
    }

    ul.list-listings-2 .listing-body h4 {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
    }

    ul.list-listings-2 .listing-footer ul.aux-info li {
        font-size: 11px;
        text-align: center;
        padding: 10px;
    }

    ul.list-listings-2 .listing-footer ul.aux-info li i {
        display: block;
        margin-right: 0;
    }

    .listing-body {
        position: relative;
    }

    .post-item.style2 {
        padding-left: 0;
    }

    .post-item.style2 .post-title {
        line-height: 19px;
        margin-bottom: 5px;
    }

    .post-item.style2 .post-title a {
        font-size: 16px;
    }

    ul.list-listings.blog-list .listing-image {
        display: block;
        width: auto;
        float: none;
    }

    ul.list-listings.blog-list .listing-body {
        width: auto;
        display: block;
        float: none;
        padding: 15px 0;
    }

    .wp-masonry-2-cols .wp-masonry-gutter {
        width: 0;
    }

    .wp-masonry-2-cols .wp-masonry-block {
        float: left;
        width: 100%;
        margin-bottom: 25px;
    }

    .wp-masonry-3-cols .wp-masonry-gutter {
        width: 0;
    }

    .wp-masonry-3-cols .wp-masonry-block {
        float: left;
        width: 100%;
        margin-bottom: 25px;
    }

    .wp-masonry-4-cols .wp-masonry-gutter {
        width: 0;
    }

    .wp-masonry-4-cols .wp-masonry-block {
        float: left;
        width: 100%;
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .slider {
        width: 100%
    }
}

@media (max-width: 460px) {
    .search-wr .search-sign i {
        margin-left: 0;
    }

    .global-search-input {
        font-size: 16px;
        padding: 14px 0;
    }
}

@media (min-width: 768px) {
    .promo {
        display: inline;
        float: left;
        width: 33.333%;
        margin: 15px 0 0;
    }

    .promo.first {
        border-right: none;
    }

    .promo.second {
        float: right;
        border-left: none;
    }

    .scale {
        transform: scale(1.2);
        box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.6);
    }

    .promos.light .scale {
        box-shadow: 0 0 4px 1px rgba(20, 20, 20, 0.3);
    }

    .scale .price {
        color: #64aaa4;
    }
}

@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin: 7.5px -15px;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        overflow-y: scroll !important;
        max-height: 340px !important;
    }

    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .text-sm-center {
        text-align: center;
    }

    #desktopLogo {
        display: none;
    }

    header .navbar-brand {
        margin: 14px auto;
        float: none;
        padding: 0 15px;
        display: inline-block;
    }

    header .top-navbar-header .navbar-brand {
        margin: 14px auto;
        float: none;
        padding: 5px 0;
        display: inline-block;
    }

    .navbar-wp .navbar-collapse {
        background-color: #fff;
    }

    .navbar-wp .navbar-nav > li {
        border-bottom: 1px solid #e0eded;
    }

    .navbar-wp .navbar-nav > li > a {
        color: #333;
        padding: 10px 20px !important;
        margin-right: 0;
    }

    .navbar-wp .navbar-nav > li > a:after {
        content: "+";
        position: relative;
        float: right;
    }

    .navbar-wp .navbar-nav > li > a:hover, .navbar-wp .navbar-nav > li > a:focus {
        color: #fff;
        background-color: #8ec449;
        border-radius: 0 !important;
    }

    .navbar-wp .navbar-nav > .active > a, .navbar-wp .navbar-nav > .active > a:hover, .navbar-wp .navbar-nav > .active > a:focus {
        border-radius: 0 !important;
    }

    .navbar-wp .dropdown-menu:after {
        border: 0 !important;
        margin-left: 0;
    }

    .navbar-wp .dropdown-menu:before {
        border: 0 !important;
        margin-left: 0;
    }

    .top-header .top-header-menu ul.menu > li ul.sub-menu {
        display: none !important;
    }

    .aside-feature {
        text-align: center;
    }

    .aside-feature .icon-feature {
        text-align: center;
    }

    .sort-list-btn .btn {
        margin-bottom: 10px;
    }

    .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 8px 30px;
    }

    .navbar-nav .open .dropdown-submenu .divider {
        padding-left: 50px;
    }

    .dropdown-submenu .dropdown-menu {
        display: block !important;
    }

    .navbar-nav .open .dropdown-submenu > a {
        border-bottom: 1px solid #e0eded;
    }

    .navbar-nav .open .dropdown-submenu > a:after {
        content: "+";
        position: relative;
        left: -5px;
        float: left;
    }

    .navbar-nav .open .dropdown-submenu .dropdown-menu > li > a, .navbar-nav .open .dropdown-submenu .dropdown-menu .dropdown-header {
        padding-left: 50px;
    }

    .dropdown-meganav .dropdown-menu {
        margin: 0 !important;
    }

    .navbar-wp .dropdown-menu .mega-nav-section-wr {
        border: 0;
        padding: 0 !important;
    }

    .navbar-wp .dropdown-menu .mega-nav-section-title {
        font-size: 14px;
        font-family: "PT Sans", sans-serif;
        border-bottom: 1px solid #e0eded;
        margin: 0;
        padding: 10px 25px;
        text-transform: none;
        color: #333;
        font-weight: normal;
    }

    .navbar-wp .dropdown-menu .mega-nav-section-title:after {
        content: "+";
        position: relative;
        float: left;
        left: -5px;
    }

    .navbar-wp .dropdown-menu .mega-nav-section-wr {
        border-right: 0;
    }

    .navbar-wp .dropdown-menu .mega-nav-section {
        padding: 0 !important;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul {
        margin: 0;
        margin-bottom: 0;
        padding: 0;
        list-style: none;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul li {
        position: relative;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul li a {
        display: block;
        padding: 8px 45px !important;
        border-bottom: 1px solid #e0eded;
        color: #333;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a {
        padding-left: 12px;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a:before {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        color: #333;
    }

    .navbar-wp .dropdown-menu ul.mega-nav-ul.arrow-li li a:hover {
        color: #8ec449;
    }

    .vcenter {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }

}

.post-content h2 {
    text-transform: none;
}

.like-box {
    margin-bottom: 7px;
    display: block;
    width: 100%;
    text-align: center;
}

.like-box span {
    font-size: 15px;
    font-weight: 500;
    color: #888;
}

.children {
    width: 94%;
    display: block;
    float: right;
}

.comment {
    float: right;
    display: block;
    width: 100%;
}

.portfolio-item {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: #fcfcfc;
    border: 1px solid #e0eded;
}

.portfolio-item:hover {
    background-color: #ececec;
}

.portfolio-desc {
    margin-top: 5px;
    text-align: center;
}

.portfolio-title {
    font-size: 15px;
    font-weight: 700;
}

.portfolio-subtitle {
    font-size: 12px;
    font-weight: 500;
}

.photo-thumb:hover {
    -webkit-filter: saturate(120%);
    -webkit-filter: contrast(120%);
    box-shadow: 0px 0px 15px grey;
}

a.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.border-left {
    border-left: 1px solid #e0eded;
}

.border-right {
    border-right: 1px solid #e0eded;
}

.base-color {
    color: #8ec449;
}

h3.slider-title {
    font-size: 2vw;
    padding: 1vw;
}

.listing-item {
    font-size: 16px;
}

.listing-item a {
    color: #7eb44a;
}

.listing-item a:hover {
    text-decoration: underline;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #ccc;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.vertical-center {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.photo-button {
    color: #555;
}

.photo-button:hover {
    color: #000;
}

.full-width-container {
    margin-right: 3.5vw;
    margin-left: 3.5vw;
}

.glowed {
    box-shadow: 0px 0px 30px 13px #777;
}

.image-glow-once {
    -webkit-animation: glow 1.2s alternate;
}

@-webkit-keyframes glow {
    from {
        box-shadow: 0px 0px 30px 13px #777;
    }
    to {
        box-shadow: 0px 0px 0px 0px #777;
    }
}